import React, { useState, useEffect } from "react";
import axios from "axios";
import { setAuthToken } from "./components/helpers/setAuthToken";
import "./Login.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { DatabaseContext } from "../index";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Login() {
  const { dbLink, dbGuid, apiPath, authToken } = React.useContext(DatabaseContext);
  const [loginName, setLoginName] = useState("");
  const [login, setLogin] = useState("");
  const [users, setUsers] = useState([{ id: "", name: "" }]);
  const [isLoading, setIsLoading] = useState(true);
  const [password, setPassword] = useState({ value: "", showPassword: false });
  const [open, setOpen] = useState(false);
  const [tokenObject, setTokenObject] = useState();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // Загружаем список доступных логинов
  useEffect(() => {
    setIsLoading(true);
    console.log("login.dblink: ", dbLink);
    console.log("login.apipath: ", apiPath);
    console.log("login.authToken: ", authToken);

    //alert(navigator.userAgent);

    let config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "cb8b8471-1125-4c17-b4c9-38522d221750"
      },
    };

    //alert("GET /users/");
    axios.get(`https://crm.gk-tis.ru/api/hs/restapi_v1/users`, config).then((res) => {
      const persons = res.data.data;
      console.log(persons)
      setUsers(persons);
    });

    setIsLoading(false);
  }, []);

  console.log(tokenObject,"tokenObject")
  console.log("ыгуввв")

  const get = () => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          `${tokenObject || authToken}`,
      },
    };

    axios
      .get(
        `${dbLink}${apiPath}/api/hs/restapi_v1/keys_issue/mobile_auth?phone_number=%2B7%20(922)%204790200`,
        config
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(authToken)

  document.setToken = (string) => {
    console.log("new token: ", string);
    // get();

    const token = string;
    setTokenObject(token || authToken);
    console.log(token, "auth token")
    console.log(tokenObject, "auth tokenObject")

    //alert(`TOKEN: ${token}`);

    // Проверяем полученный токен
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    console.log("get token request", config);

    localStorage.setItem("token", token || authToken);
    window.location.href = `/rec?host=${dbLink}&baseUrl=${apiPath}`;
    console.log("token", token)

    axios
      .get(`${dbLink}${apiPath}/auth/user`, config)
      .then((response) => {
        if (response.data.success) {
          console.log("GET TOKEN: ", token);
          localStorage.setItem("token", token || authToken);
          setAuthToken(token || authToken);
          window.location.href = `/rec?host=${dbLink}&baseUrl=${apiPath}`;
        } else {
          let str = JSON.stringify(response);
          alert("Ошибка при проверка токена. Ответ сервера: " + str);
        }
      })
      .catch((err) => {
        alert(err);
      });

      console.log(config, "config")
  };

  // Авторизация 1С
  useEffect(() => {
    const setToken = (string) => {
      console.log("new token: ", string);
      // get();

      const token = string;
      setTokenObject(token || localStorage.getItem("token"));
      console.log(token, "auth token")
      console.log(tokenObject, "auth tokenObject")

      //alert(`TOKEN: ${token}`);

      // Проверяем полученный токен
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      console.log("get token request", config);

      localStorage.setItem("token", token || authToken);
      window.location.href = `/rec?host=${dbLink}&baseUrl=${apiPath}`;
      console.log("token", token)

      axios
        .get(`${dbLink}${apiPath}/auth/user`, config)
        .then((response) => {
          if (response.data.success) {
            console.log("GET TOKEN: ", token);
            localStorage.setItem("token", token || authToken);
            setAuthToken(token || authToken);
            window.location.href = `/rec?host=${dbLink}&baseUrl=${apiPath}`;
          } else {
            let str = JSON.stringify(response);
            alert("Ошибка при проверка токена. Ответ сервера: " + str);
          }
        })
        .catch((err) => {
          alert(err);
        });

        console.log(config, "config")
    };

    document.payload = '{"action": ""}';
    document.setToken = setToken;
    document.get = get;

    if (!isLoading) {
      console.log("page loaded");

      document.payload = '{"action": "auth"}';
      document.getElementById("action_trigger").click();
    }
  }, [isLoading, dbLink, apiPath]);

  const handleActionTrigerClick = () => {
    console.log("handleActionTrigerClick");
  };

  const handleLoginChange = (event) => {
    setLoginName(event.target.value)
    const user = users.find((user) => event.target.value === user.name);
    setLogin(user ? user.id : null);
    console.log(login, "loginka");
  };

  console.log(login, "login")

  const handleChangePassword = (event) => {
    setPassword({ ...password, value: event.target.value });
  };

  const handleClickShowPassword = () => {
    setPassword({
      ...password,
      showPassword: !password.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (email, pass) => {
    //reqres registered sample user
    const loginPayload = {
      user_id: login,
      password: password.value,
    };

    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    console.log("Payload: ", loginPayload);

    //alert("POST auth/login/");
    axios
      .post(`${dbLink}${apiPath}/auth/login`, loginPayload, config)
      .then((response) => {
        const token = response.data.data;
        localStorage.setItem("token", token);
        console.log("call setAuthToken");
        setAuthToken(token);
        console.log(token,"token")
        window.location.href = `/rec?host=${dbLink}&baseUrl=${apiPath}`;
      })
      .catch((err) => {
        console.log(err);
        setOpen(true);
      });
  };

  /*
  document.auth = (str) => {
    console.log(str);
    //const userObj = JSON.parse(str);
    //console.log(userObj)
    setLogin(str.login);
    setPassword({ ...password, value: str.password });

    handleSubmit();
  };
  */

  if (isLoading)
    return (
      <div className="login-background">
        <Box
          sx={{
            position: "absolute",
            margin: "auto",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            width: 550,
            height: 360,
            backgroundColor: "#FFFFFF",
            borderRadius: "4px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          Loading
        </Box>
      </div>
    );

  return (
    <div className="login-background">
      <Box
        sx={{
          position: "absolute",
          margin: "auto",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          width: 550,
          height: 360,
          backgroundColor: "#FFFFFF",
          borderRadius: "4px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", marginTop: "24px", marginLeft: "24px" }}
        >
          4DEV
        </Typography>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", margin: "12px auto" }}
        >
          График выдачи ключей
        </Typography>
        <Box sx={{ minWidth: 120, width: 300, margin: "8px auto" }}>
          <FormControl fullWidth variant="standard">
            <InputLabel htmlFor="standard-adornment-login">
              Пользователь
            </InputLabel>
            <Input
              id="standard-adornment-login"
              type="text"
              value={loginName}
              onChange={handleLoginChange}
              endAdornment={
                <InputAdornment position="end">
                </InputAdornment>
              }
            />
            {/* <InputLabel id="demo-simple-select-label">Пользователь</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={login}
              label="user"
              onChange={handleLoginChange}
            >
              {users.map((user, index) => (
                <MenuItem key={index} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select> */}
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 120, width: 300, margin: "8px auto" }}>
          <FormControl fullWidth variant="standard">
            <InputLabel htmlFor="standard-adornment-password">
              Пароль
            </InputLabel>
            <Input
              id="standard-adornment-password"
              type={password.showPassword ? "text" : "password"}
              value={password.value}
              onChange={handleChangePassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {password.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <Button
          variant="contained"
          sx={{ width: 200, margin: "16px auto" }}
          onClick={handleSubmit}
        >
          Войти
        </Button>
        <Button
          id="action_trigger"
          sx={{ display: "none" }}
          onClick={handleActionTrigerClick}
        >
          none
        </Button>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            Ошибка авторизации!
          </Alert>
        </Snackbar>
      </Box>
    </div>
  );
}
