import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { NotificationContext } from "../../../components/context/NotificationContext";

export default function UserConfirmDate({ onClose, onOk, newDate, object, client, schedule, isShareholder, ...rest }) {
  const setNotification = useContext(NotificationContext);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <IconButton
        sx={{ position: "absolute", right: 2, top: 2 }}
        aria-label="close"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Установить время выдачи?</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <b>Клиент:</b> {client}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <b>Объект к выдаче:</b> {object}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <b>Новое время выдачи:</b> {newDate}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
            <Button variant="outlined" onClick={onClose}>
              Отмена
            </Button>
            <Button variant="contained" onClick={onOk}>Изменить</Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
