import React, { useContext } from "react";
import ValidatePhone from "./components/ValidatePhone";
import "./index.css";
import { UserContextProvider } from "../components/context/UserContext";

export default function UserZone() {
  return (
    <div className="background">
        <ValidatePhone />
    </div>
  );
}
