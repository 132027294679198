import React, { useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import DataAsList from "./DataViews/List";
import CalendarView from "./DataViews/Calendar";
import { DataContext } from "./context/DataContext";

const drawerW = 330;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerW}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Workspace({
  open,
  date,
  headerDateLabel,
  period,
  view,
  data,
  weekend,
  refetch,
  ...rest
}) {
  const { objsData, changeData, selectedSchedule, changeSchedule } =
  useContext(DataContext); 
  /*
  if (typeof data === "undefined" || data.length === 0) {
    return (
      <Main open={open}>
        <DrawerHeader />
        <div>Нет данных для отображения</div>
      </Main>
    );
  } else*/
  return (
    <Main open={open}>
      <DrawerHeader />
      {view === "list" ? (
        <DataAsList
          data={objsData}
          date={date}
          headerDateLabel={headerDateLabel}
          period={period}
        />
      ) : (
        <CalendarView
          period={period}
          weekends={weekend}
          open={open}
          data={objsData}
          date={date}
          refetch={refetch}
        />
      )}
    </Main>
  );
  /*
    return (
      <Main open={open}>
        <DrawerHeader />
        {view === "list" ? (
          <DataAsList data={data} date={date} />
        ) : period === "month" ?  <CalendarView weekends={weekend} open={open} data={data} date={date} /> : (
          <DataAsCalendar date={date} period={period} data={data} weekend={weekend}/>
        )}
      </Main>
    );*/
}
