import React, { useState, useEffect, useContext, useMemo } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ru } from "date-fns/locale/";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { DatabaseContext } from "../../../../index";
import { DataContext } from "../../context/DataContext";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { InputLabel, ListSubheader, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format, addMinutes } from "date-fns";
import { NotificationContext } from "../../context/NotificationContext";
import { parseISO } from "date-fns";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const containsText = (text, searchText) =>
  text?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;

function ComboBox({ optionsList, scheduleObj, handleClientSelect, ...rest }) {
  const { dbLink, dbGuid, apiPath } = useContext(DatabaseContext);

  const [selectedOption, setSelectedOption] = useState();
  const [clientListByPhone, setClientListByPhone] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () => optionsList.filter((option) => containsText(option, searchText)),
    [searchText, optionsList]
  );

  useEffect(() => {
    setSelectedOption(optionsList[0]);
  }, [optionsList]);

  const handleSelectOption = (e) => {
    console.log(e.target.value);
    setSelectedOption(e.target.value);
    handleClientSelect(e.target.value);
    setClientListByPhone([]);
  };

  const handleChangeSearchText = (newValue) => {
    if (newValue === "") {
      setSearchText("");
    }

    if (isNaN(newValue)) {
      setSearchText(newValue);
    } else {
      setSearchText(newValue);
      if (newValue.length > 2) {
        let d = new Date();
        let url = `${dbLink}${apiPath}/keys_issue/available_objects?schedule_id=${
          scheduleObj.schedule_id
        }&filter_type=phone_number&filter_id=${newValue}&timestamp=${d.toISOString()}`;
        setIsLoading(true);

        axios
          .get(url)
          .then((res) => {
            let tempArr = [];
            console.log("RES: ", res.data.data);
            res.data.data.map((obj) => {
              tempArr.push(obj.client);
            });
            console.log(tempArr);
            setClientListByPhone(tempArr);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setClientListByPhone([]);
      }
    }
  };

  return (
    <Box sx={{ mt: "-4px", ml: 1, width: "100%" }}>
      <FormControl fullWidth variant="standard">
        <Select
          // Disables auto focus on MenuItems and allows TextField to be in focus
          MenuProps={{
            autoFocus: false,
            PaperProps: { sx: { maxHeight: 300 } },
          }}
          labelId="search-select-label"
          id="search-select"
          value={selectedOption}
          label="Options"
          onChange={(e) => handleSelectOption(e)}
          onClose={() => setSearchText("")}
          // This prevents rendering empty string in Select's value
          // if search text would exclude currently selected option.
          renderValue={() => selectedOption}
        >
          {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
          <ListSubheader>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Ф.И.О или номер телефона"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleChangeSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {isLoading ? (
            <Typography m={1} variant="body1">
              Загрузка...
            </Typography>
          ) : clientListByPhone.length > 0 ? (
            clientListByPhone.map((option, i) => (
              <MenuItem key={i} value={option}>
                {option}
              </MenuItem>
            ))
          ) : displayedOptions.length > 0 ? (
            displayedOptions.map((option, i) => (
              <MenuItem key={i} value={option}>
                {option}
              </MenuItem>
            ))
          ) : isNaN(searchText) ? (
            <Typography m={1} variant="body1">
              Клиенты не найдены
            </Typography>
          ) : searchText.length < 3 ? (
            <Typography m={1} variant="body1">
              Для поиска по номеру введите больше 3 цифр
            </Typography>
          ) : (
            <Typography m={1} variant="body1">
              Клиенты не найдены
            </Typography>
          )}
        </Select>
      </FormControl>
    </Box>
  );
}

export default function AddRecord({
  onClose,
  scheduleObj,
  issue = null,
  ...rest
}) {
  const { dbLink, dbGuid, apiPath } = useContext(DatabaseContext);
  const setNotification = React.useContext(NotificationContext);
  const { reloadIssues, availablePeriod, selectedSchedule } =
    useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [buildingsList, setBuildingsList] = useState([]);
  const [buildingId, setBuildingId] = useState("");
  const [scheduleName, setScheduleName] = useState("");
  const [district, setDistrict] = useState("");
  const [client, setClient] = useState("");
  const [inputClient, setInputClient] = useState("");
  const [clientList, setClientList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [availableObjects, setAvailableObjects] = useState([]);
  const [filtredObjs, setFiltredObjs] = useState([]);
  const [selectedObj, setSelectedObj] = useState();
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [section, setSection] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [room, setRoom] = useState("");
  const [date, setDate] = useState("");
  const [formatedDate, setFormatedDate] = useState("");
  const [time, setTime] = useState("");
  const [dateLoading, setDateLoading] = useState(true);
  const [maxDate, setMaxDate] = useState(Date.now());
  const [minDate, setMinDate] = useState(Date.now());
  const [comment, setComment] = useState("");
  const [optionsList, setOptionsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [approveIssue, setApproveIssue] = useState(false);

  useEffect(() => {
    setLoading(true);
    setScheduleName(scheduleObj.schedule);

    console.log("ISSUE: ", issue);

    if (issue) {
      var issueDay = issue.issuing_time.split("T")[0];
      let date = new Date(issueDay);

      handleDateChange(date);

      // из списка доступных периодов для записи выделить те, что соответсвуют выбранному дню
      let tempAvailablePeriodForDay = availablePeriod.filter(
        (date) => date.split("T")[0] === issueDay
      );

      setAvailableTimes(tempAvailablePeriodForDay);
      setTime(issue.issuing_time);
    }

    // get schedule info
    let d = new Date();
    axios
      .get(
        `${dbLink}${apiPath}/keys_issue/get_schedule?schedule_id=${
          selectedSchedule.schedule_id
        }&timestamp=${d.toISOString()}`
      )
      .then((result) => {
        console.log(result.data[0]);
        if (result.status === 200) setDistrict(result.data[0].district);
      })
      .catch((error) => {
        console.log(error);
      });

    // get buildings list
    axios
      .get(
        `${dbLink}${apiPath}/keys_issue/available_objects?schedule_id=${
          selectedSchedule.schedule_id
        }&request_field=building&timestamp=${d.toISOString()}`
      )
      .then((result) => {
        if (result.data.success) {
          setBuildingsList(result.data.data);
          if (result.data.data.length === 1) {
            console.log("BUILD: ", result.data.data[0]);
            handleBuildinsListChange(result.data.data[0].building_id);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [scheduleObj]);

  const clearData = () => {
    if (!issue) setTime("");
    setSectionId("");
    setSection("");
    setClient("");
    setAvailableDates([]);
  };

  const handleBuildinsListChange = (newValue) => {
    clearData();
    console.log("New value: ", newValue);
    setBuildingId(newValue);

    // get sections list
    let d = new Date();
    axios
      .get(
        `${dbLink}${apiPath}/keys_issue/available_objects?schedule_id=${
          selectedSchedule.schedule_id
        }&filter_type=building&filter_id=${newValue}&request_field=section&timestamp=${d.toISOString()}`
      )
      .then((result) => {
        if (result.data.success) {
          setSectionList(result.data.data);
          console.log("Section list: ", result.data.data);
          if (result.data.data.length === 1) {
            handleSectionListChange(result.data.data[0].section_id);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTimeChange = (newValue) => {
    console.log("New value: ", newValue);
    setTime(newValue);
  };

  const handleDateChange = (newValue) => {
    setTime("");
    var y = newValue.getFullYear();
    var m = newValue.getMonth() + 1;
    var d = newValue.getDate();

    var selectedDate =
      y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);

    console.log("New Date Value: ", selectedDate);

    setFormatedDate(selectedDate);
    setDate(newValue);

    //
    var tempAvailableTimesOnSelectedDay = [];
    for (var i = 0; i < availableDates.length - 1; i++) {
      if (availableDates[i].includes(selectedDate))
        tempAvailableTimesOnSelectedDay.push(availableDates[i]);
    }

    tempAvailableTimesOnSelectedDay.sort();

    console.log(tempAvailableTimesOnSelectedDay);

    setAvailableTimes(tempAvailableTimesOnSelectedDay);
  };

  const handleRoomChange = (newValue) => {
    setRoom(newValue);
  };

  const handleScheduleChange = (newValue) => {
    setScheduleName(newValue);
  };

  const handleSectionListChange = (newValue) => {
    clearData();
    setSectionId(newValue);
    let d = new Date();
    axios
      .get(
        `${dbLink}${apiPath}/keys_issue/available_objects?schedule_id=${
          scheduleObj.schedule_id
        }&filter_type=section&filter_id=${newValue}&timestamp=${d.toISOString()}`
      )
      .then((result) => {
        if (result.data.success) {
          setAvailableObjects(result.data.data); //
          let tempOptionsArr = [];
          result.data.data.map((obj) => {
            const index = tempOptionsArr.findIndex(
              (object) => object === obj.client
            );

            if (index === -1) {
              tempOptionsArr.push(obj.client);
            }
          });
          setOptionsList(tempOptionsArr);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleClientChange = (newValue) => {
    setClient(newValue);
    setAvailableDates([]);
    setSection("");

    // newValue.split(":")[0]
    var tempClient = availableObjects.filter((obj) => {
      return obj.client_id === newValue.id;
    });
    setSection(tempClient);
  };

  const handleInputClientChange = (newValue, reason) => {
    console.log(newValue);
    // "9139259836"
    if (reason === "reset") {
      setOptionsList(clientList);
    }

    if (isNaN(newValue)) {
      setInputClient(newValue);
    } else {
      setInputClient(newValue);
      if (newValue.length > 2) {
        let d = new Date();
        let url = `${dbLink}${apiPath}/keys_issue/available_objects?schedule_id=${
          scheduleObj.schedule_id
        }&filter_type=phone_number&filter_id=${newValue}&timestamp=${d.toISOString()}`;
        setIsLoading(true);

        axios
          .get(url)
          .then((res) => {
            let tempArr = [];
            console.log("RES: ", res.data.data);
            res.data.data.map((obj) => {
              tempArr.push({
                label: obj.client,
                id: obj.client_id,
              });
            });
            console.log(tempArr);
            setOptionsList(tempArr);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setOptionsList(clientList);
      }
    }
  };

  /**
   * Находим ID клиента и загружаем доступные объекты
   * @param {string} clientName - ФИО клиента
   */
  const handleClientSelect = (clientName) => {
    var filtredObjects = availableObjects.filter((obj) => {
      return obj.client === clientName;
    });

    console.log(scheduleObj);

    setClient(filtredObjects[0]?.client_id);
    handleSectionChange(filtredObjects[0]?.object_id);
    setFiltredObjs(filtredObjects);
  };

  const handleSectionChange = (newValue) => {
    setDateLoading(true);

    let d = new Date();

    axios
      .get(
        `${dbLink}${apiPath}/keys_issue/available_period?schedule_id=${
          scheduleObj.schedule_id
        }&timestamp=${d.toISOString()}`
      )
      .then((result) => {
        console.log(result.data);
        if (result.data.success) {
          setSelectedObj(newValue);
          if (result.data.data.main_period.length > 0) {
            setAvailableDates(result.data.data.main_period);
            var tempMaxDate = result.data.data.main_period.slice(-1)[0];
            var tempMinDate = result.data.data.main_period[0];
            console.log("Max date: ", new Date(tempMaxDate.split("T")[0]));
            console.log("Min date: ", new Date(tempMinDate.split("T")[0]));
            setMaxDate(new Date(tempMaxDate.split("T")[0]));
            setMinDate(new Date(tempMinDate.split("T")[0]));
          } else if (result.data.data.alternative_period.length > 0) {
            setAvailableDates(result.data.data.alternative_period);
            var tempMaxDate = result.data.data.alternative_period.slice(-1)[0];
            var tempMinDate = result.data.data.alternative_period[0];
            console.log("Max date: ", new Date(tempMaxDate.split("T")[0]));
            console.log("Min date: ", new Date(tempMinDate.split("T")[0]));
            setMaxDate(new Date(tempMaxDate.split("T")[0]));
            setMinDate(new Date(tempMinDate.split("T")[0]));
          }
        }
        setDateLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveData = () => {
    //console.log(schedule);
    //console.log(section[0]);
    var objToSend = {
      schedule_id: scheduleObj.schedule_id,
      alternative_record: false,
      object_id: selectedObj,
      client_id: client,
      issue_time: time, //formatedDate + "T" + time,
      comment: comment,
    };

    console.log(objToSend);

    axios
      .post(`${dbLink}${apiPath}/keys_issue/manual_record`, objToSend)
      .then((result) => {
        console.log(result);

        if (approveIssue) {
          let url = `${dbLink}${apiPath}/keys_issue/customer_confirmation`;

          let body = {
            object_id: selectedObj,
            update_time: false,
            record_time: time,
          };

          axios
            .post(url, body)
            .then((res) => {
              if (res.data.success) {
                setNotification(
                  "success",
                  "Информация о записи успешно обновлена"
                );
                reloadIssues();
                onClose();
              } else {
                setNotification(
                  "error",
                  "Произошла ошибка во время смены даты и времени выдачи ключей"
                );
              }
            })
            .catch((err) => {
              console.log(err);
              setNotification(
                "error",
                "Произошла ошибка во время смены даты и времени выдачи ключей"
              );
            });
        }
        reloadIssues();
        onClose();
      })
      .catch((error) => console.log(error));
  };

  /**
   * Отключваем выходные для отображения в календаре
   * @param {*} date
   * @returns
   */
  function disableWeekends(date) {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  const blockRecord = () => {
    blockTime();
  };

  /**
   * Функция блокирует выбранную ячейку времени для записи
   */
  const blockTime = async () => {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    console.log(issue.issuing_time);

    let startDate = format(
      parseISO(issue.issuing_time),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
    let endDate = format(
      addMinutes(parseISO(issue.issuing_time), selectedSchedule.time_on_issue),
      "yyyy-MM-dd'T'HH:mm:ss"
    );

    let payload = {
      schedule_id: selectedSchedule.schedule_id,
      periods: [
        {
          start_date: startDate,
          end_date: endDate,
          available: false,
        },
      ],
    };

    console.log("Payload: ", payload);

    axios
      .post(
        `${dbLink}${apiPath}/keys_issue/set_availability_of_periods`,
        payload,
        config
      )
      .then((res) => {
        console.log(res);
        reloadIssues();
        setOpenDialog(false);
        onClose();
      })
      .catch((err) => {
        console.log(err);
        setNotification("error", "Ошибка при блокировании времени");
      });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IconButton
        sx={{ position: "absolute", right: 2, top: 2 }}
        aria-label="close"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
        <Grid item xs={12}>
          <Typography variant="h5">
            График выдачи ключей, запись вручную
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="body1">График:</Typography>
            <Typography variant="body1" sx={{ marginLeft: 1 }}>
              {scheduleName}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="body1">Район:</Typography>
            <Typography variant="body1" sx={{ marginLeft: 1 }}>
              {district}
            </Typography>
          </Box>
        </Grid>

        {buildingsList.length === 0 ? (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              Не доступных объектов для выдачи
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid item xs={6}>
              <Box sx={{ display: "flex" }}>
                <Typography variant="body1" sx={{ width: "20%" }}>
                  Строение:
                </Typography>
                <FormControl
                  variant="standard"
                  sx={{ ml: 3, mt: -0.5, width: "100%" }}
                >
                  <Select
                    labelId="select-client-label"
                    id="select-client"
                    value={buildingId}
                    onChange={(e) => handleBuildinsListChange(e.target.value)}
                    label="client"
                  >
                    {loading ? (
                      <MenuItem value="">
                        <em>Загрузка...</em>
                      </MenuItem>
                    ) : (
                      buildingsList?.map((b, index) => {
                        return (
                          <MenuItem key={b.building_id} value={b.building_id}>
                            {b.building}
                          </MenuItem>
                        );
                      })
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: "flex" }}>
                <Typography variant="body1" sx={{ width: "20%" }}>
                  Секция:
                </Typography>
                <FormControl
                  variant="standard"
                  sx={{ ml: 1, mt: -0.5, width: "100%" }}
                >
                  <Select
                    disabled={buildingId === "" ? true : false}
                    labelId="select-section-label"
                    id="select-section"
                    value={sectionId}
                    onChange={(e) => handleSectionListChange(e.target.value)}
                    label="client"
                  >
                    {loading ? (
                      <MenuItem value={null}>
                        <em>Загрузка...</em>
                      </MenuItem>
                    ) : (
                      sectionList?.map((s, index) => {
                        return (
                          <MenuItem key={s.section_id} value={s.section_id}>
                            {s.section}
                          </MenuItem>
                        );
                      })
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex" }}>
                <Typography variant="body1" sx={{ width: "10%" }}>
                  Клиент:
                </Typography>

                <ComboBox
                  optionsList={optionsList}
                  scheduleObj={scheduleObj}
                  handleClientSelect={handleClientSelect}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex" }}>
                <Typography variant="body1" sx={{ width: "48%" }}>
                  Объект недвижимости:
                </Typography>
                {filtredObjs.length > 0 ? (
                  <FormControl
                    variant="standard"
                    sx={{ ml: 0, mt: -0.5, width: "100%" }}
                  >
                    <Select
                      disabled={client === "" ? true : false}
                      labelId="select-section-label"
                      id="select-section"
                      value={filtredObjs[0]?.object_id}
                      onChange={(e) => handleSectionChange(e.target.value)}
                      label="section"
                    >
                      {loading ? (
                        <MenuItem value="">
                          <em>Загрузка...</em>
                        </MenuItem>
                      ) : filtredObjs.length > 0 ? (
                        filtredObjs?.map((option, i) => (
                          <MenuItem
                            key={option.object_id}
                            value={option.object_id}
                          >
                            {option.object}
                          </MenuItem>
                        ))
                      ) : (
                        <Typography variant="body1">
                          Объекты не найдены
                        </Typography>
                      )}
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl
                    variant="standard"
                    sx={{ ml: 0, mt: -0.5, width: "100%" }}
                  >
                    <Select
                      disabled={client === "" ? true : false}
                      labelId="select-obj-label"
                      id="select-obj"
                      value={""}
                      label="obj"
                    >
                      <MenuItem value="">
                        <em>Загрузка...</em>
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Box>
            </Grid>

            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
                <DesktopDatePicker
                  label="Доступная дата"
                  inputFormat="dd/MM/yyyy"
                  //disablePast={true}
                  disabled={availableDates.length === 0 ? true : false}
                  shouldDisableDate={
                    scheduleObj.issue_on_weekend === "false" && disableWeekends
                  }
                  maxDate={maxDate}
                  minDate={minDate}
                  loading={dateLoading}
                  value={date || null}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      sx={{ mt: "-20px" }}
                      variant="standard"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6}>
              <Box sx={{ display: "flex" }}>
                <Typography variant="body1">Время:</Typography>
                <FormControl
                  variant="standard"
                  sx={{ ml: 1, mt: -0.5, minWidth: "35%" }}
                >
                  <Select
                    labelId="select-time-label"
                    disabled={availableDates.length === 0 ? true : false}
                    id="select-time"
                    value={time}
                    onChange={(e) => handleTimeChange(e.target.value)}
                    label="time"
                  >
                    {availableTimes.map((t, index) => {
                      return (
                        <MenuItem value={t}>
                          {t?.split("T")[1].slice(0, -3)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  maxWidth: "100%",
                }}
              >
                <TextField
                  fullWidth
                  label="Комментарий"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  multiline
                  rows={4}
                  id="fullWidth"
                />
              </Box>
            </Grid>
            {selectedSchedule.status === "Черновик" ? (
              <Grid item xs={8}>
                <Button
                  variant="text"
                  sx={{ marginTop: "4px" }}
                  onClick={() => setOpenDialog(true)}
                >
                  Сделать недоступной для записи
                </Button>
                <Dialog
                  open={openDialog}
                  onClose={() => setOpenDialog(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Сделать ячейку недоступной для записи?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      При объявлении ячейки недоступной для записи заполненные
                      данные сбросятся. Продолжить?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Отмена</Button>
                    <Button onClick={blockRecord} autoFocus>
                      Да
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            ) : (
              <Grid item xs={8}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={approveIssue}
                      onClick={() => setApproveIssue(!approveIssue)}
                    />
                  }
                  label="Подтвердить запись"
                />
              </Grid>
            )}

            <Grid item xs={4}>
              <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
                {/*
            <Button variant="outlined" onClick={() => clearData()}>
              Отчистить
            </Button>
          */}
                <Button variant="contained" onClick={() => saveData()}>
                  Сохранить
                </Button>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}
