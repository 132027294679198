import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import NotificationsIcon from "@mui/icons-material/Notifications";
import InputBase from "@mui/material/InputBase";
import ActsList from "./components/ActsList";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#F8F8F9",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
      "&:focus": {
        width: "40ch",
      },
    },
  },
}));

export default function Acts() {
  const [showFilters, setShowFilters] = useState(false);

  return (
    <Box sx={{ height: "100vh", padding: "35px" }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Stack spacing={2} direction="row">
            <Button variant="contained" endIcon={<ExpandMoreIcon />}>Действия</Button>
            <Button variant="outlined" endIcon={<AddCircleOutlineIcon />}>Новый акт</Button>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Поиск по заявкам..."
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
            <Button variant="contained">Найти</Button>
            <IconButton aria-label="settings" onClick={() => setShowFilters(!showFilters)}>
              <TuneIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{display: showFilters ? "auto" : "none"}}>
          <Stack spacing={2} direction="row">
            <Button variant="text" endIcon={<ExpandMoreIcon />}>
              Объект
            </Button>
            <Button variant="text" endIcon={<ExpandMoreIcon />}>
              Клиент
            </Button>
            <Button variant="text" endIcon={<ExpandMoreIcon />}>
              Организация
            </Button>
            <Button variant="text" endIcon={<ExpandMoreIcon />}>
              Ответственый
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
            <Button variant="text" startIcon={<PrintIcon />}>
              Печать
            </Button>
            <Button variant="text" startIcon={<NotificationsIcon />}>
              Добавить напоминание
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
            <ActsList />
        </Grid>
      </Grid>
    </Box>
  );
}
