import axios from "axios";
import React, { useContext, useEffect, useState, useCallback } from "react";
import FullCalendar from "@fullcalendar/react";
import { useSearchParams } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MonthPicker } from "@mui/x-date-pickers/MonthPicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import endOfWeek from "date-fns/endOfWeek";
import isSameDay from "date-fns/isSameDay";
import isWeekend from "date-fns/isWeekend";
import isWithinInterval from "date-fns/isWithinInterval";
import { ru } from "date-fns/locale/";
import startOfWeek from "date-fns/startOfWeek";
import ruLocale from "@fullcalendar/core/locales/ru";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";

import { createEventId } from "../../../DataViews/Calendar/event-utils";
import { DatabaseContext } from "../../../../..";
import { DataContext } from "../../../context/DataContext";
import { NotificationContext } from "../../../context/NotificationContext";
import UserConfirmDate from "../../../../users/components/Dialog/UserConfirmDate";
import { timeArray, checkTime } from "../../../utils/utils";

var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0");
var yyyy = today.getFullYear();

today = mm + "/" + dd + "/" + yyyy;

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

export default function DateChangeDialog({
  objectId,
  object,
  client,
  clientId,
  onClose,
  onSuccess,
  ...rest
}) {
  const { dbLink, apiPath } = useContext(DatabaseContext);
  const { reloadIssues } = useContext(DataContext);
  const setNotification = React.useContext(NotificationContext);
  const [schedule, setSchedule] = useState();
  const [availableTimes, setAvailableTimes] = useState([]);
  const [period, setPeriod] = useState("week");
  const [showWeekends, setShowWeekends] = useState(true);
  const [error, setError] = useState(null);
  const calendarRef = React.useRef(null);
  const [currentDate, setCurrentDate] = useState("2022-08-01");
  const [eventDuration, setEventDuration] = useState("00:15:00");
  const [dataEvents, setDataEvents] = useState([]);
  const [currentIssue, setCurrentIssue] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [date, setDate] = useState({
    start: new Date(),
    end: new Date(),
    current: new Date(),
  });

  const loadData = useCallback(() => {
    let d = new Date();
    axios
      .get(
        `${dbLink}${apiPath}/keys_issue/queue?object_id=${objectId}&timestamp=${d.toISOString()}`
      )
      .then((res) => {
        console.log("result: ", res);
        let scheduleId = res.data[0].schedule_id;
        console.log("schedule id: ", scheduleId);

        let url = `${dbLink}${apiPath}/keys_issue/get_schedule?schedule_id=${scheduleId}&timestamp=${d.toISOString()}`;

        axios.get(url).then((res) => {
          let loadedSchedule = res.data[0];
          console.log("loaded schedule: ", loadedSchedule);
          setSchedule(loadedSchedule);
          if (loadedSchedule.issue_on_weekend === "true") setShowWeekends(true);
          // Перемещаем указатель даты на дату начала очереди
          var tempDate = new Date(
            Number(loadedSchedule.issue_start.split("-")[0]),
            Number(loadedSchedule.issue_start.split("-")[1]) - 1,
            Number(loadedSchedule.issue_start.split("-")[2].split("T")[0]) + 1
          );

          // TODO: исправить, заставляет срабатывать зависимость в другом useEffect()
          setDate({ ...date, start: tempDate, current: tempDate });
          console.log(tempDate);

          // задаем длительность слота для записи
          setEventDuration("00:" + loadedSchedule.time_on_issue + ":00");

          // получаем доступные слоты для записи
          let d = new Date();
          axios
            .get(
              `${dbLink}${apiPath}/keys_issue/available_period?schedule_id=${
                loadedSchedule.schedule_id
              }&timestamp=${d.toISOString()}`
            )
            .then((res) => {
              //console.log("Result for av.times", res); // res.data

              // проверяем, основное время используется или альтернативное
              if (res.data.success) {
                var availableDates =
                  res.data.data.main_period.length > 0
                    ? res.data.data.main_period
                    : res.data.data.alternative_period;
                //console.log("DATES: ", availableDates);

                // приводим свободные слоты к заданному виду для отображения на календаре
                var tempDataArr = [];

                // Загружаем список недоступных ячеек
                var blockedTimes = [];
                let d = new Date();
                axios
                  .get(
                    `${dbLink}${apiPath}/keys_issue/not_available_period?schedule_id=${
                      loadedSchedule.schedule_id
                    }&timestamp=${d.toISOString()}`
                  )
                  .then((res) => {
                    res.data.data.forEach((el) => {
                      blockedTimes.push(el.start_date);
                    });

                    availableDates.forEach((el) => {
                      if (
                        !checkTime(
                          loadedSchedule.dinner_start_time.split("T")[1],
                          loadedSchedule.dinner_end_time.split("T")[1],
                          el.split("T")[1]
                        ) &&
                        !blockedTimes.includes(el)
                      ) {
                        tempDataArr.push({
                          id: createEventId(),
                          title: "",
                          start: el,
                          backgroundColor: "#90caf9",
                          borderColor: "rgba(111,111,111,0.2)",
                          issue: {
                            issuing_time: el,
                          },
                        });
                      }
                    });

                    setDataEvents(tempDataArr);
                  })
                  .catch((err) => console.log(err));
              }
            })
            .catch((err) => {
              console.log(err);
              setNotification(
                "error",
                "Ошибка при загрузке доступного времени для выдачи"
              );
            });
        });
      })
      .catch((err) => {
        console.log(err);
        setNotification(
          "error",
          "Произошла ошибка при загрузке данных по очереди выдачи"
        );
      });
  }, [objectId, dbLink]);

  /**
   * Загружаем данные по очереди выдачи ключей
   */
  useEffect(() => {
    console.log("Loaded user object: ", objectId);
    if (objectId) {
      loadData();
    }
  }, [objectId, dbLink]);

  useEffect(() => {
    const { current: calendarDom } = calendarRef;
    const API = calendarDom ? calendarDom.getApi() : null;
    API &&
      API.changeView(
        period === "month"
          ? "dayGridMonth"
          : period === "week"
          ? "timeGridWeek"
          : "timeGridDay"
      );
    var y = date.start.getFullYear().toString();
    var m = (date.start.getMonth() + 1).toString();
    var d = date.start.getDate().toString();

    var dd =
      y +
      "-" +
      (m.length < 2 ? "0" + m : m) +
      "-" +
      (d.length < 2 ? "0" + d : d);
    //console.log("goto date: ", dd);
    API && API.gotoDate(dd);
    setCurrentDate(dd);
  }, [period, date]);

  const renderEventContent = (eventInfo) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <AddCircleIcon
          color="primary"
          sx={{ width: "16px", mr: "2px", ml: "2px" }}
        />

        <Typography variant="body1">
          {eventInfo.timeText.length === 2
            ? eventInfo.timeText + ":00"
            : eventInfo.timeText}

          <i>{"\xa0" + eventInfo.event.title}</i>
        </Typography>
      </Box>
    );
  };

  const handleEventClick = (clickInfo) => {
    console.log("Event info: ", clickInfo.event);

    // устанавливаем выбранное событие
    setCurrentIssue(clickInfo.event.extendedProps.issue);
    setOpenModal(true);
  };

  const sendNewDate = () => {
    let url = `${dbLink}${apiPath}/keys_issue/customer_confirmation`;

    let body = {
      object_id: objectId,
      client_id: clientId,
      update_time: true,
      record_time: currentIssue.issuing_time,
    };

    axios
      .post(url, body)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setNotification("success", "Информация о записи успешно обновлена");
          reloadIssues();
          onSuccess();
        } else {
          setNotification(
            "error",
            "Произошла ошибка во время смены даты и времени выдачи ключей"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setNotification(
          "error",
          "Произошла ошибка во время смены даты и времени выдачи ключей"
        );
      });
    setOpenModal(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 2,
    overflowY: "auto",
    maxHeight: "90vh",
  };

  const modalWindowConfirm = (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <UserConfirmDate
          onOk={() => sendNewDate()}
          onClose={() => setOpenModal(false)}
          newDate={currentIssue?.issuing_time?.slice(0, -3).replace("T", " в ")}
          object={object}
          client={client}
        />
      </Box>
    </Modal>
  );

  return (
    <div>
      <IconButton
        sx={{ position: "absolute", right: 2, top: 2 }}
        aria-label="close"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ pt: "24px", minWidth: "700px" }}>
        <FullCalendar
          height={"87vh"}
          ref={calendarRef}
          locale={ruLocale}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          allDaySlot={false}
          handleWindowResize={true}
          initialView={
            period === "month"
              ? "dayGridMonth"
              : period === "week"
              ? "timeGridWeek"
              : "timeGridDay"
          }
          initialDate={currentDate}
          defaultTimedEventDuration={eventDuration}
          editable={false}
          //slot settings
          slotDuration={eventDuration}
          slotMinTime={"07:00:00"}
          slotMaxTime={"20:00:00"}
          scrollTime={"09:00:00"}
          slotLabelFormat={{
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: false,
            meridiem: "short",
          }}
          //select settings
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          selectOverlap={false}
          selectAllow={(e) => {
            var border = period === "month" ? 86400 : 900;
            if (e.end.getTime() / 1000 - e.start.getTime() / 1000 <= border) {
              return true;
            }
          }}
          weekends={showWeekends}
          events={dataEvents}
          //select={handleDateSelect}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          //eventChange={handleEventChange}
          //eventsSet={handleEvents} // called after events are initialized/added/changed/removed
        />
        {modalWindowConfirm}
      </Box>
    </div>
  );
}
