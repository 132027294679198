import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { DatabaseContext } from "../../..";
import { NotificationContext } from "./NotificationContext";

const DataContext = React.createContext();

const DataContextProvider = ({ children }) => {
  const { dbLink, dbGuid, apiPath, authToken } = useContext(DatabaseContext);
  const setNotification = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const [objsData, setObjsData] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const [scheduleDate, setScheduleDate] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("week");
  const [showWeekends, setShowWeekends] = useState(false);
  const [availablePeriod, setAvailablePeriod] = useState([]);
  const [search, setSearch] = useState("")
  const [scheduleList, setSchedulesList] = useState([]);

  console.log(children, "children")

  let config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": authToken
    },
  };
  /**
   * Загружаем доступное время для выдачи по графику
   * @param {*} scheduleId - id графика выдачи
   */
  const loadAvailablePeriod = (scheduleId) => {
    console.log(scheduleId, "scheduleId")
    if (scheduleId) {
      let d = new Date();
      let url = `${dbLink}${apiPath}/keys_issue/available_period?schedule_id=${scheduleId}&timestamp=${d.toISOString()}`;
      setError(false);
      setIsLoading(true);
      axios
        .get(url, config)
        .then((result) => {
          if (result.data.success) {
            console.log("AVAILABLE TIMES:", result.data.data);
            // проверяем какое из времен задействовано (основное или альтернативное)
            // и устанавливаем достпуные промежутки времени для записи
            if (result.data.data.main_period) {
              setAvailablePeriod(result.data.data.main_period);
            } else if (result.data.data.alternative_period) {
              setAvailablePeriod(result.data.data.alternative_period);
            }
            setIsLoading(false);
          } else {
            console.error(
              "ERROR DURING AVAILABLE TIMES LOADING. ",
              result.data?.err_msg
            );
            setError(true);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setError(true);
          setIsLoading(false);
        });
    } else {
      console.log("schedule_id is undefined");
    }
  };

  /**
   * Загружаем список доступных графиков
   */
  const fetchData = () => {
    let d = new Date();
    let url = `${dbLink}${apiPath}/keys_issue/get_schedule?commissioned=true&timestamp=${d.toISOString()}`;
    console.log("SCHEDULE LIST URL: ", url);
    setError(false);
    setIsLoading(true);
    axios
      .get(url, config)
      .then((result) => {
        console.log("SCHEDULE LIST RESULT: ", result);
        if (result.status === 200) {
          setSchedulesList(result.data);

          // Если уже выбран график - обновляем его
          if (selectedSchedule.schedule_id) {
            setSelectedSchedule(
              result.data.find(
                (el) => el.schedule_id === selectedSchedule.schedule_id
              )
            );
          }
        } else setError(true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setIsLoading(false);
      });
  };

  /**
   * Функция загрузки данных по выбранной очереди выдачи
   * Данные сохраняются в DataContext
   * @param {*} id - id очереди
   */
  const loadDataForSchedule = (id) => {
    //console.log("loadDataForSchedule");
    let d = new Date();
    var url = `${dbLink}${apiPath}/keys_issue/queue?schedule_id=${id}&timestamp=${d.toISOString()}`;

    axios
      .get(url)
      .then((result) => {
        if (result.status === 200) {
          let d = result.data?.find((obj) => obj.schedule_id === id).data;
          changeData(d);
          loadAvailablePeriod(id);
          return d;
        } else {
          console.log("ERROR DURING DATA FETCHING");
          changeData([]);
          setNotification(
            "error",
            `Ошибка ${result.status} при загрузки данных для графика выдачи`
          );
          return [];
        }
      })
      .catch((err) => {
        console.log("ERROR DURING DATA FETCHING: ", err);
        setNotification(
          "error",
          "Ошибка при загрузки данных для графика выдачи"
        );
      });
  };

  const handleIssueDataReload = () => {
    loadDataForSchedule(selectedSchedule.schedule_id);
  };

  useEffect(() => {
    // при инициализации приложения загружаем список доступных графиков
    fetchData();
  }, []);

  /**
   * метод для перезагрузки списка доступных графиков
   */
  const handleReload = () => {
    console.log("handleReload");
    fetchData();
  };

  const changeShowWeekends = (show) => {
    setShowWeekends(show);
  };

  const changePeriod = (newPeriod) => {
    console.log("SET NEW PERIOD: ", newPeriod);
    setSelectedPeriod(newPeriod);
  };

  const changeScheduleDate = (newDate) => {
    console.log("SET NEW DATE: ", newDate);
    setScheduleDate(newDate);
  };

  const changeData = (newData) => {
    console.log("SET NEW DATA: ", newData);
    setObjsData(newData);
  };

  const changeSchedule = (newSchedule) => {
    console.log("SET NEW SCHEDULE: ", newSchedule);

    // устанавливаем объект "график выдачи ключей"
    setSelectedSchedule(newSchedule);

    // загружаем данные о записях по выбранному графику

    // загружаем доступные периоды к выдаче по выбранному графику
    loadAvailablePeriod(newSchedule.schedule_id);
  };

  const clearAll = () => {
    changeData([]);
    changeSchedule({});
  };

  console.log(search, "search")
  console.log(objsData, "objsData")

  const [filteredObjsData, setFilteredObjsData] = useState(objsData); // Filtered data

  useEffect(() => {
    // Filter objsData based on search
    const filteredData = objsData.filter(item =>
      (item.object + item.phone_number + item.client)
        .toLowerCase()
        .includes(search.toLowerCase())
    );
    setFilteredObjsData(filteredData); // Update filtered data
  }, [search, objsData]);

  // Function to handle search input change
  const handleSearchChange = event => {
    setSearch(event.target.value);
  };

  return (
    <DataContext.Provider
      value={{
        dataError: error,
        dataIsLoading: isLoading,
        scheduleList: scheduleList,
        reload: handleReload,
        clearAll: clearAll,
        objsData: filteredObjsData,
        availablePeriod: availablePeriod,
        changeData: changeData,
        reloadIssues: handleIssueDataReload,
        selectedSchedule: selectedSchedule,
        changeSchedule: changeSchedule,
        scheduleDate: scheduleDate,
        changeScheduleDate: changeScheduleDate,
        selectedPeriod: selectedPeriod,
        setNewPeriod: changePeriod,
        showWeekends: showWeekends,
        search: search,
        setSearch: setSearch,
        changeShowWeekends: changeShowWeekends,
      }}
    >
      {children}
    </DataContext.Provider>
  );
  
};

export { DataContext, DataContextProvider };
