import React from "react";
import "./404.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Error404() {
  return (
    <div className="error-background">
      <Box
        sx={{
          position: "absolute",
          margin: "auto",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          width: 550,
          height: 200,
          backgroundColor: "#FFFFFF",
          borderRadius: "4px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", margin: "12px auto" }}
        >
          Запрашиваемая страница не найдена.
        </Typography>
      </Box>
    </div>
  );
}
