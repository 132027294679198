import axios from "axios";

export const setAuthToken = (token) => {
    console.log('setAuthToken: ', token)
    /*
  if (token) {
    console.log("setting axios bearer token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else delete axios.defaults.headers.common["Authorization"];
  */
};
