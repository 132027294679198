import React, { useState, useEffect, useContext, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { createEventId } from "./event-utils";
import ruLocale from "@fullcalendar/core/locales/ru";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Tooltip from "@mui/material/Tooltip";
import KeyIcon from "@mui/icons-material/Key";

import "./index.css";
import AddRecord from "../../Record/Add";
import InfoIssue from "../../Record/Info";
import { DatabaseContext } from "../../../../index";
import { DataContext } from "../../context/DataContext";
import { NotificationContext } from "../../context/NotificationContext";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format, addMinutes } from "date-fns";
import { parseISO } from "date-fns";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { timeArray, checkTime } from "../../utils/utils";

export default function Calendar({ refetch, ...props }) {
  const { dbLink, dbGuid, apiPath } = useContext(DatabaseContext);
  const {
    objsData,
    changeData,
    selectedSchedule,
    changeSchedule,
    scheduleDate,
    selectedPeriod,
    availablePeriod,
  } = useContext(DataContext);
  const setNotification = useContext(NotificationContext);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [currentIssue, setCurrentIssue] = useState();
  const [currentIssueId, setCurrentIssueId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [dataEvents, setDataEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState("2022-08-01");
  const [eventDuration, setEventDuration] = useState("00:15:00");
  const calendarRef = React.useRef(null);

  const [openModalAddIssue, setOpenModalAddIssue] = useState(false);
  const handleOpenModalAddIssue = () => setOpenModalAddIssue(true);
  const handleCloseModalAddIssue = () => setOpenModalAddIssue(false);

  const [openModalInfoIssue, setOpenModalInfoIssue] = useState(false);
  const handleOpenModalInfoIssue = () => setOpenModalInfoIssue(true);

  const [blockedTimesData, setBlockedTimesData] = useState([]);
  const [openUnblockDialog, setOpenUnblockDialog] = useState(false);
  const [changeIssueFromInfo, setChangeIssueFromInfo] = useState(false);

  const [contextMenu, setContextMenu] = useState(null);

  const [showContextMenuItems, setShowContextMenuItems] = useState({
    addRecord: false,
    blockIssue: false,
    unblockIssue: false,
  });

  const handleContextMenu = (e) => {
    e.preventDefault();
    //console.log(e.target.children[0].children[0].id);
    if (e.target?.children[0]?.children[0]?.id) {
      //console.log(dataEvents)
      const { current: calendarDom } = calendarRef;
      const API = calendarDom ? calendarDom.getApi() : null;
      var events;
      API && (events = API.getEvents());
      //console.log("events ", events)

      let foundEl = events.find(
        (el) => el.id === e.target.children[0].children[0].id
      );

      // getEvents
      console.log("Current issue: ", foundEl.extendedProps);
      setCurrentIssue(foundEl);
      setCurrentIssueId(foundEl?.id);

      if (foundEl.extendedProps?.issue?.object_id) {
        // Нашли ячейку с данными
        // Для нее не показываем контекстное меню
        setShowContextMenuItems({
          addRecord: false,
          blockIssue: false,
          unblockIssue: false,
        });
      } else {
        if (foundEl.extendedProps?.blocked) {
          setShowContextMenuItems({
            addRecord: false,
            blockIssue: false,
            unblockIssue: true,
          });
        } else {
          setShowContextMenuItems({
            addRecord: false,
            blockIssue: true,
            unblockIssue: false,
          });
        }

        setContextMenu(
          contextMenu === null
            ? {
                mouseX: e.clientX + 2,
                mouseY: e.clientY - 6,
              }
            : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
              // Other native context menus might behave different.
              // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
              null
        );
      }
    }
  };

  const handleContextMenuClose = () => {
    setContextMenu(null);
  };

  const handleCloseModaInfoIssue = (objectId) => {
    setOpenModalInfoIssue(false);
    refetch();

    /*
    console.log("ID to remove: ", objectId);
    var tempArr = dataEvents;

    const indexOfObject = tempArr.findIndex(object => {
      return object.issue.object_id === objectId;
    });

    console.log("Item to remove: ", indexOfObject);

    tempArr.splice(indexOfObject, 1);

    setDataEvents(tempArr);*/
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      //console.log("PERIOD: ", selectedPeriod);
      //console.log("DATE: ", scheduleDate); // TODO: set init date
      //console.log("Load calendar");

      //setIsLoading(true);
      if (Object.keys(selectedSchedule).length === 0) {
        //console.log("Empty object");
        const { current: calendarDom } = calendarRef;
        const API = calendarDom ? calendarDom.getApi() : null;
        API && API.removeAllEvents();
      } else {
        setEventDuration("00:" + selectedSchedule.time_on_issue + ":00");

        if (scheduleDate) {
          const { current: calendarDom } = calendarRef;
          const API = calendarDom ? calendarDom.getApi() : null;
          API &&
            API.changeView(
              selectedPeriod === "month"
                ? "dayGridMonth"
                : selectedPeriod === "week"
                ? "timeGridWeek"
                : "timeGridDay"
            );

          var tempDataArr = [];

          //console.log("OBJ DATA: ", objsData);
          //console.log("AV PERIODS: ", availablePeriod);

          // Загружаем ячейки с записями
          objsData?.map((obj) =>
            tempDataArr.push({
              id: createEventId(),
              title: (
                obj.object.split(",").slice(-1)[0] +
                ", " +
                obj.client.split(" ")[0]
              )
                ?.replace("Квартира", "кв.")
                .replace("квартира", "кв."),
              description: obj.comment,
              issue: obj,
              start: obj.issuing_time,
              backgroundColor: "white",
              borderColor: "rgba(111,111,111,0.5)",
              textColor: "black",
              overlap: false,
            })
          );

          // Создаем обеденные ячейки
          let dinnerIssues;
          if (selectedSchedule.have_dinner) {
            dinnerIssues = timeArray(
              selectedSchedule.dinner_start_time.split("T")[1],
              selectedSchedule.dinner_end_time.split("T")[1],
              Number(selectedSchedule.time_on_issue)
            );
            //console.log("DINNER TIME! ", dinnerIssues);
          }

          if (
            selectedSchedule.status === "Черновик" ||
            selectedSchedule.status === "Опубликован"
          ) {
            // Загружаем список недоступных ячеек
            var blockedTimes = [];
            let d = new Date();
            axios
              .get(
                `${dbLink}${apiPath}/keys_issue/not_available_period?schedule_id=${
                  selectedSchedule.schedule_id
                }&timestamp=${d.toISOString()}`
              )
              .then((res) => {
                res.data.data.forEach((el) => {
                  blockedTimes.push(el.start_date);
                });

                if (changeIssueFromInfo) {
                  blockedTimes.push(currentIssue.issuing_time);
                }

                setBlockedTimesData(res.data.data);

                // Создамем массив объектов для записи
                console.log("AVAILABLE PERIODS: ", availablePeriod);
                //console.log("BLOCKED: ", blockedTimes);
                availablePeriod?.map((period) => {
                  let b = blockedTimes.includes(period);
                  //console.log(period, blockedTimes[0], b);
                  if (
                    selectedSchedule.have_dinner === "true" &&
                    !checkTime(
                      selectedSchedule.dinner_start_time.split("T")[1],
                      selectedSchedule.dinner_end_time.split("T")[1],
                      period.split("T")[1]
                    )
                  ) {
                    tempDataArr.push({
                      id: createEventId(),
                      title: "",
                      start: period,
                      blocked: b,
                      backgroundColor: b ? "#D7DBDF" : "#90caf9",
                      borderColor: "rgba(111,111,111,0.2)",
                      issue: {
                        issuing_time: period,
                      },
                      extendedProps: {
                        blocked: b,
                      },
                    });
                  } else if (selectedSchedule.have_dinner === "false") {
                    tempDataArr.push({
                      id: createEventId(),
                      title: "",
                      start: period,
                      blocked: b,
                      backgroundColor: b ? "#D7DBDF" : "#90caf9",
                      borderColor: "rgba(111,111,111,0.2)",
                      issue: {
                        issuing_time: period,
                      },
                      extendedProps: {
                        blocked: b,
                      },
                    });
                  }
                });

                setDataEvents(tempDataArr);
              })
              .catch((err) => console.log(err));
          } else {
            setDataEvents(tempDataArr);
          }

          let y = scheduleDate.split(" - ")[0].split(".")[2];
          let m = scheduleDate.split(" - ")[0].split(".")[1];
          let d = scheduleDate.split(" - ")[0].split(".")[0];

          var dd = y + "-" + m + "-" + d;
          //console.log("goto date: ", dd);
          API && API.gotoDate(dd);
          setCurrentDate(dd);

          //setChangeIssueFromInfo(false);
          //setDataEvents(tempDataArr);
          //setIsLoading(false);
        }
      }
    }, 0);

    return () => clearTimeout(timeout);
  }, [
    objsData,
    scheduleDate,
    selectedPeriod,
    availablePeriod,
    apiPath,
    dbLink,
    selectedSchedule,
  ]);

  const handleDateSelect = (selectInfo) => {
    alert("click");

    //handleOpenModalAddIssue(true);

    /*
    let title = prompt("create title:");
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }*/
  };

  const handleEventClick = (clickInfo) => {
    //console.log("Event info: ", clickInfo.event);
    //console.log("Schedule obj: ", selectedSchedule.status);
    //console.log("Extendet props: ", clickInfo.event.extendedProps);

    // устанавливаем выбранное событие
    setCurrentIssue(clickInfo.event.extendedProps.issue);
    setCurrentIssueId(clickInfo.event.id);

    // Проверяем доступна ли ячейка для записи или заблокирована
    if (clickInfo.event.extendedProps.blocked) {
      setOpenUnblockDialog(true);
    } else {
      // проверяем есть ли запись на выдачу в выбранное время
      // если есть - открывает окно с редактированием
      // если нет - открывает окно с созданием записи
      if (clickInfo.event.extendedProps.issue.object_id) {
        /*
        console.log(
          "extendedProps info: ",
          clickInfo.event.extendedProps.issue
        );
        */
        handleOpenModalInfoIssue(true);
      } else {
        //console.log("Empty event");
        setOpenModalAddIssue(true);
      }
    }
  };

  const clearIssue = () => {
    //console.log("clearIssue call");
    setChangeIssueFromInfo(true);
    let blockedArr = blockedTimesData;
    blockedArr.push({
      start_date: currentIssue.issuing_time,
      end_date: format(
        addMinutes(
          parseISO(currentIssue.issuing_time),
          selectedSchedule.time_on_issue
        ),
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
    });
    setBlockedTimesData(blockedArr);

    /*
    const { current: calendarDom } = calendarRef;
    const API = calendarDom ? calendarDom.getApi() : null;
    var event;
    API && (event = API.getEventById(currentIssueId));
    console.log("found event: ", event)
    event.setExtendedProp("blocked", true);
    event.setProp("color", "#ffb74d");
    */
  };

  const setIssueColor = (color) => {
    const { current: calendarDom } = calendarRef;
    const API = calendarDom ? calendarDom.getApi() : null;
    var event;
    console.log(currentIssueId);
    API && (event = API.getEventById(currentIssueId));
    event.setProp("color", color);
  };

  const setIssueIsBlocked = (blocked) => {
    const { current: calendarDom } = calendarRef;
    const API = calendarDom ? calendarDom.getApi() : null;
    var event;
    API && (event = API.getEventById(currentIssueId));
    console.log(event);
    event.setExtendedProp("blocked", blocked);
    event.setProp("color", blocked ? "#D7DBDF" : "#90caf9");
  };

  const handleEvents = (events) => {
    setCurrentEvents(events);
  };

  /**
   * Метод измеенение записи при перетаскивании на календаре
   * @param {*} e
   */
  const handleEventChange = (e) => {
    // e.event.extendedProps.issue - объект с данными о записи
    //console.log("change");
    var objToSend = {
      schedule_id: selectedSchedule.schedule_id,
      alternative_record: false,
      object_id: e.event.extendedProps.issue.object_id,
      client_id: e.event.extendedProps.issue.client_id,
      issue_time: e.event.startStr.substring(0, e.event.startStr.length - 6),
      comment: e.event.extendedProps.issue.comment,
    };

    //console.log(objToSend);

    axios
      .put(`${dbLink}${apiPath}/keys_issue/manual_record`, objToSend)
      .then((result) => {
        // console.log(result);
        // уведомляем пользователя
        setNotification("success", "Время записи изменено");
      })
      .catch((error) => {
        console.log(error);
        // уведомляем пользователя
        setNotification("error", "Ошибка при измкенении времени записи");
      });
  };

  const handleBlockIssue = () => {
    blockIssue();
    handleContextMenuClose();
  };

  const handleUnblockIssue = () => {
    unblockIssue();
    handleContextMenuClose();
  };

  /**
   * Метод для раблокировки выбранноя ячейки
   */
  const unblockIssue = () => {
    //console.log("unblock");
    //console.log(currentIssue);
    //console.log(blockedTimesData);

    const { current: calendarDom } = calendarRef;
    const API = calendarDom ? calendarDom.getApi() : null;
    var events;
    API && (events = API.getEvents());
    //console.log("events ", events)

    let foundEl = events.find((el) => el.id === currentIssueId);

    console.log("FOUND EL: ", foundEl);

    console.log("Current: ", currentIssueId);

    if (!foundEl) {
      foundEl = blockedTimesData.find(
        (el) => el.start_date === currentIssue.issuing_time
      );
    }

    if (!foundEl) {
      setNotification("error", "Ошибка при поиске заблокированной ячейки");
      return setOpenUnblockDialog(false);
    } else {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      let payload = {
        schedule_id: selectedSchedule.schedule_id,
        periods: [
          {
            start_date: foundEl.start
              ? format(foundEl.start, "yyyy-MM-dd'T'HH:mm:ss")
              : foundEl.start_date,
            end_date: foundEl.start
              ? format(
                  addMinutes(foundEl.start, selectedSchedule.time_on_issue),
                  "yyyy-MM-dd'T'HH:mm:ss"
                )
              : foundEl.end_date,
            available: true,
          },
        ],
      };
      axios
        .post(
          `${dbLink}${apiPath}/keys_issue/set_availability_of_periods`,
          payload,
          config
        )
        .then((res) => {
          //console.log(res);
          if (res.status === 200) {
            setIssueColor("#90caf9");
            setIssueIsBlocked(false);
            setNotification("success", "Ячейка успешно разблокирована");
          } else {
            setNotification("error", "Ошибка при разблокировании времени");
          }
        })
        .catch((err) => {
          console.log(err);
          setNotification("error", "Ошибка при разблокировании времени");
        });

      setOpenUnblockDialog(false);
    }
  };

  /**
   * Метод для блокировки выбранной ячейки
   */
  const blockIssue = (issue = currentIssue) => {
    console.log("block issue call ");

    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    let date = new Date(currentIssue.start);
    let startDate = format(date, "yyyy-MM-dd'T'HH:mm:ss");
    let endDate = format(
      addMinutes(date, selectedSchedule.time_on_issue),
      "yyyy-MM-dd'T'HH:mm:ss"
    );

    let payload = {
      schedule_id: selectedSchedule.schedule_id,
      periods: [
        {
          start_date: startDate,
          end_date: endDate,
          available: false,
        },
      ],
    };

    console.log("Payload: ", payload);

    axios
      .post(
        `${dbLink}${apiPath}/keys_issue/set_availability_of_periods`,
        payload,
        config
      )
      .then((res) => {
        //console.log(res);
        setIssueIsBlocked(true);
        setNotification("success", "Выбранная ячейка успешно заблокирована");
      })
      .catch((err) => {
        console.log(err);
        setNotification("error", "Ошибка при блокировании времени");
      });
  };

  function renderEventContent(eventInfo) {
    return (
      <Box
        sx={{ display: "flex", flexDirection: "row" }}
        id={eventInfo.event.id}
      >
        <Typography variant="caption">
          {eventInfo.timeText.length === 2
            ? eventInfo.timeText + ":00"
            : eventInfo.timeText}
        </Typography>
        {selectedSchedule.status === "Опубликован" ? (
          <>
            {eventInfo.event.extendedProps.issue?.object_id ? (
              <>
                <Tooltip
                  title={
                    eventInfo.event.extendedProps.issue?.approved_by_client ===
                    "true"
                      ? "Запись подтверждена клиентом"
                      : "Запись не подтверждена клиентом"
                  }
                >
                  <AccountCircleIcon
                    sx={{ height: "14px", mt: "3px" }}
                    color={
                      eventInfo.event.extendedProps.issue
                        ?.approved_by_client === "true"
                        ? "success"
                        : "error"
                    }
                  />
                </Tooltip>
                <Tooltip
                  title={
                    eventInfo.event.extendedProps.issue?.keys_received ===
                    "true"
                      ? "Ключи получены клиентом"
                      : "Ключи не получены"
                  }
                >
                  <KeyIcon
                    sx={{ height: "14px", mt: "3px", ml: "-6px" }}
                    color={
                      eventInfo.event.extendedProps.issue?.keys_received ===
                      "true"
                        ? "success"
                        : "error"
                    }
                  />
                </Tooltip>
              </>
            ) : (
              "\xa0"
            )}
          </>
        ) : (
          "\xa0"
        )}
        <Typography variant="caption">{eventInfo.event.title}</Typography>
      </Box>
    );
  }

  const modalWindowAddIssue = (
    <Modal
      open={openModalAddIssue}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "600px",
          bgcolor: "background.paper",
          border: "1px solid #000",
          boxShadow: 24,
          p: 2,
          overflowY: "auto",
          maxHeight: "90vh",
        }}
      >
        <AddRecord
          onClose={handleCloseModalAddIssue}
          scheduleObj={selectedSchedule}
          issue={currentIssue}
        />
      </Box>
    </Modal>
  );

  const modalWindowInfoIssue = (
    <Modal
      open={openModalInfoIssue}
      aria-labelledby="modal-info-title"
      aria-describedby="modal-info-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "500px",
          bgcolor: "background.paper",
          border: "1px solid #000",
          boxShadow: 24,
          p: 2,
          overflowY: "auto",
          maxHeight: "90vh",
        }}
      >
        <InfoIssue
          onClose={handleCloseModaInfoIssue}
          data={currentIssue}
          scheduleId={selectedSchedule?.schedule_id}
          changeColor={setIssueColor}
          clearIssue={clearIssue}
        />
      </Box>
    </Modal>
  );

  if (isLoading)
    return (
      <Box>
        <CircularProgress sx={{ margin: "25% 45% 0 45%;" }} />
      </Box>
    );

  return (
    <div className="calendar-container">
      <div
        className="calendar-main"
        /*
        onContextMenu={(e) => {
          e.preventDefault(); // prevent the default behaviour when right clicked
          //console.log(e.target.children[0].children[0].id)
          if (e.target?.children[0]?.children[0]?.id) {
            //console.log(dataEvents)
            let foundEl = dataEvents.find(
              (el) => el.id === e.target.children[0].children[0].id
            );
            console.log(foundEl);
          }
        }}
        */
        onContextMenu={handleContextMenu}
      >
        <FullCalendar
          ref={calendarRef}
          locale={ruLocale}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          /*headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}*/
          headerToolbar={{
            left: "",
            center: "",
            right: "",
          }}
          allDaySlot={false}
          handleWindowResize={true}
          initialView={
            selectedPeriod === "month"
              ? "dayGridMonth"
              : selectedPeriod === "week"
              ? "timeGridWeek"
              : "timeGridDay"
          }
          initialDate={currentDate}
          defaultTimedEventDuration={eventDuration}
          //editable={selectedSchedule.status === "Черновик"}
          editable={false}
          //slot settings
          slotDuration={eventDuration}
          slotMinTime={"07:00:00"}
          slotMaxTime={"20:00:00"}
          scrollTime={"09:00:00"}
          slotLabelFormat={{
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: false,
            meridiem: "short",
          }}
          //select settings
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          selectOverlap={false}
          selectAllow={(e) => {
            var border = selectedPeriod === "month" ? 86400 : 900;
            if (e.end.getTime() / 1000 - e.start.getTime() / 1000 <= border) {
              return true;
            }
          }}
          weekends={props.weekends}
          events={dataEvents}
          /*
          dateClick={function (info) {
            alert("Clicked on: " + info.dateStr);
          }}*/
          //select={handleDateSelect}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          //eventChange={handleEventChange} // перетаскивание записей
          //eventsSet={handleEvents} // called after events are initialized/added/changed/removed
        />
        <Menu
          open={contextMenu !== null}
          onClose={handleContextMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem
            onClick={() => handleBlockIssue()}
            sx={{
              gap: "8px",
              display: !showContextMenuItems.blockIssue ? "none" : "auto",
            }}
          >
            Заблокировать ячейку
          </MenuItem>
          <MenuItem
            onClick={() => handleUnblockIssue()}
            sx={{
              gap: "8px",
              display: !showContextMenuItems.unblockIssue ? "none" : "auto",
            }}
          >
            Разблокировать ячейку
          </MenuItem>
        </Menu>
        <Dialog
          open={openUnblockDialog}
          onClose={() => setOpenUnblockDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Недоступная ячейка"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Данная ячейка заблокирована для записи. Разблокировать?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenUnblockDialog(false)}>Нет</Button>
            <Button onClick={() => unblockIssue()} autoFocus>
              Да
            </Button>
          </DialogActions>
        </Dialog>
        {modalWindowAddIssue}
        {modalWindowInfoIssue}
      </div>
    </div>
  );
}
