import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { NotificationContext } from "../context/NotificationContext";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function PubAccess({ onClose, onOk, sendNoti, ...rest }) {
  const setNotification = useContext(NotificationContext);
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleOkClick = (e) => {
    if (checked) {
      sendNoti();
    }

    onOk(e);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IconButton
        sx={{ position: "absolute", right: 2, top: 2 }}
        aria-label="close"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Опубликовать график?</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            После публикации графика вносить изменения будет невозможно
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={handleChange} />}
              label="Разослать оповещения клиентам, включенным в эту очередь"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
            <Button variant="outlined" onClick={onClose}>
              Отмена
            </Button>
            <Button variant="contained" onClick={() => handleOkClick()}>
              Опубликовать
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
