import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { TableRow, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ru } from "date-fns/locale/";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip from "@mui/material/Tooltip";
import { DatabaseContext } from "../../../../index";
import { DataContext } from "../../context/DataContext";
import { NotificationContext } from "../../context/NotificationContext";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import DateChangeDialog from "./DateChangeDialog";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format, addMinutes } from "date-fns";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function RowPublish({
  desc,
  value,
  edit = false,
  accept = false,
  onAccept,
  onEdit,
  additionalProp,
}) {
  return (
    <Stack direction="row" spacing={1}>
      <Typography variant="body2" color={"#63707A"}>
        {desc || "Ошибка"}
      </Typography>
      <Typography variant="body2">{value || "Отсуствует"}</Typography>
      {additionalProp}
      {edit && (
        <Tooltip title="Редактировать запись">
          <IconButton
            sx={{ height: "20px", width: "20px" }}
            size="small"
            aria-label="close"
            onClick={onEdit}
          >
            <EditIcon fontSize="8px" color="primary" />
          </IconButton>
        </Tooltip>
      )}
      {accept && (
        <Tooltip title="Подтвердить запись">
          <IconButton
            sx={{ height: "20px", width: "20px" }}
            size="small"
            aria-label="close"
            onClick={onAccept}
          >
            <CheckCircleOutlineIcon fontSize="8px" color="primary" />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
}

export default function InfoIssue({
  onClose,
  data,
  scheduleId,
  changeColor,
  clearIssue,
  ...rest
}) {
  const [objectData, setObjectData] = useState()
  const { dbLink, dbGuid, apiPath } = useContext(DatabaseContext);
  const { reloadIssues, selectedSchedule } = useContext(DataContext);
  const setNotification = useContext(NotificationContext);
  const [schedule, setSchedule] = useState("");
  const [client, setClient] = useState("");
  const [section, setSection] = useState("");
  const [room, setRoom] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [maxDate, setMaxDate] = useState(Date.now());
  const [minDate, setMinDate] = useState(Date.now());
  const [dateLoading, setDateLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [addedComment, setAddedComment] = useState("");
  const [prevState, setPrevState] = useState({
    issuingDate: "",
    comment: "",
  });
  const [actStatus, setActStatus] = useState("not-signed");
  const [rejectionReason, setRejectionReason] = useState("");
  const [showDateChangeDialog, setShowDateChangeDialog] = useState(false);
  const [showRemark, setShowRemark] = useState(false);
  const [remark, setRemark] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openAcceptDialog, setopenAcceptDialog] = useState(false);
  const [showApproveBtn, setShowApproveBtn] = useState(
    data?.approved_by_client !== "true"
  );
  const [showApproverIcon, setShowApprovedIcon] = useState(
    data?.approved_by_client === "true"
  );
  const [act, setAct] = useState({
    transfer_act_id: "00000000-0000-0000-0000-000000000000",
    transfer_act: "",
    status: "",
  });
  const [showEdit, setShowEdit] = useState(true);

  useEffect(() => {
    setObjectData(data)
  },[objectData])

  console.log(objectData, "objectData")

  const handleClickOpenAcceptDialog = () => {
    setopenAcceptDialog(true);
  };

  document.getObject = () => {
    return objectData;
  }

  const handleCloseAcceptDialog = () => {
    setopenAcceptDialog(false);
  };

  const handleActChange = (newValue) => {
    console.log(newValue);
    setActStatus(newValue);
  };

  function getAvailableDates() {
    console.log("getAvailableDates");
    setPrevState({ issuingDate: data.issuing_time, comment: data.comment });
    setDateLoading(true);

    let d = new Date();

    axios
      .get(
        `${dbLink}${apiPath}/keys_issue/available_period?schedule_id=${scheduleId}&timestamp=${d.toISOString()}`
      )
      .then((result) => {
        console.log("AVAILABLE PERIODS DATA: ", result.data);
        if (result.data.success) {
          let tempArr = [];
          if (result.data.data.main_period.length > 0) {
            setAvailableDates(result.data.data.main_period);
            var tempMaxDate = result.data.data.main_period.slice(-1)[0];
            var tempMinDate = result.data.data.main_period[0];
            console.log("Max date: ", new Date(tempMaxDate.split("T")[0]));
            console.log("Min date: ", new Date(tempMinDate.split("T")[0]));
            setMaxDate(new Date(tempMaxDate.split("T")[0]));
            setMinDate(new Date(tempMinDate.split("T")[0]));
          } else if (result.data.data.alternative_period.length > 0) {
            setAvailableDates(result.data.data.alternative_period);
            var tempMaxDate = result.data.data.alternative_period.slice(-1)[0];
            var tempMinDate = result.data.data.alternative_period[0];
            console.log("Max date: ", new Date(tempMaxDate.split("T")[0]));
            console.log("Min date: ", new Date(tempMinDate.split("T")[0]));
            setMaxDate(new Date(tempMaxDate.split("T")[0]));
            setMinDate(new Date(tempMinDate.split("T")[0]));
          }
        }
        setDateLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    console.log("selectedSchedule: ", selectedSchedule.status);
    getAvailableDates();

    console.log("DATA: ", data);

    let startDate = new Date(data.issuing_time);
    handleDateChange(startDate);

    let startTime = data.issuing_time;
    setTime(startTime);
    setComment(data.comment);

    // Задаем начальное состояние компонента
    // Если очередь к выдаче опубликована
    if (selectedSchedule.status === "Опубликован") {
      // Загружаем комментарий по выдаче
      setComment(data.receipt_note);
      // Загружаем акт
      let d = new Date();
      let url = `${dbLink}${apiPath}/keys_issue/transfer_act?object_id=${
        data.object_id
      }&timestamp=${d.toISOString()}`;

      axios
        .get(url)
        .then((res) => {
          console.log("OBJECT DATA: ", data);
          console.log("STATUS: ", res.data.success);
          let act = res.data.data;
          console.log("ACT: ", act);

          if (res.data.success) {
            setAct(act);

            if (act.status === "Подписан") {
              setShowEdit(false);
              setActStatus("signed");
            }

            if (act.remarks) {
              setRemark(act.remarks);
              setShowRemark(true);
            }

            /*
            if (
              act.transfer_act_id === "00000000-0000-0000-0000-000000000000"
            ) {
              setActStatus("not-signed");
            } else {
              setActStatus("signed");
            }
            setRemark("");
            setRejectionReason("");
            */
          } else {
            setNotification("error", "Ошибка при загрузке акта");
          }
        })
        .catch((err) => {
          console.log(err);
          setNotification("error", "Ошибка при загрузке акта");
        });

      /*
      if (data?.transfer_act) setActStatus("signed");
      setRemark(data?.receipt_note);
      setRejectionReason(data?.receipt_note);
      */
    }
  }, [data, isEdit]);

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  /**
   * Функция которая удаляет объект из ячейки времени и
   * блокирует его для записи
   */
  const blockRecord = async () => {
    //1. удаляем заполненные данные
    deleteRecord();
    //1.1. меняем отображение ячейки через props
    clearIssue();

    //задержка, потому что не база не успевает реагировать
    await timeout(3000);

    //2. передаем беку что ячейка недоступна
    blockTime();

    //3. меняем отображение ячейки через props
    clearIssue();
  };

  /**
   * Функция блокирует выбранную ячейку времени для записи
   */
  const blockTime = async () => {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    let startDate = format(date, "yyyy-MM-dd'T'HH:mm:ss");
    let endDate = format(
      addMinutes(date, selectedSchedule.time_on_issue),
      "yyyy-MM-dd'T'HH:mm:ss"
    );

    let payload = {
      schedule_id: scheduleId,
      periods: [
        {
          start_date: startDate,
          end_date: endDate,
          available: false,
        },
      ],
    };

    console.log("Payload: ", payload);

    axios
      .post(
        `${dbLink}${apiPath}/keys_issue/set_availability_of_periods`,
        payload,
        config
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setNotification("error", "Ошибка при блокировании времени");
      });
  };

  const deleteRecord = () => {
    axios
      .delete(
        `${dbLink}${apiPath}/keys_issue/manual_record?schedule_id=${scheduleId}&object_id=${data.object_id}&client_id=${data.client_id}`
      )
      .then((result) => {
        console.log(result);
        reloadIssues();
        setNotification("success", "Запись успешно удалена");
        onClose(data.object_id);
      })
      .catch((error) => console.log(error));
  };

  const handleEdit = () => {
    if (!isEdit) {
      //console.log("INC DATA: ", data)
      //getAvailableDates(data.issuing_time);
    } else {
      // TODO: проверка заполненных полей
      // TODO: корректное заполнение select'a для времени
      var objToSend = {
        schedule_id: scheduleId,
        alternative_record: false,
        object_id: data.object_id,
        client_id: data.client_id,
        issue_time: time, //formatedDate + "T" + time,
        comment: comment,
      };

      console.log("objToSend: ", objToSend);

      axios
        .put(`${dbLink}${apiPath}/keys_issue/manual_record`, objToSend)
        .then((result) => {
          console.log("CHANGE RESULT: ", result);
          reloadIssues();
          setNotification("success", "Запись успешно изменена");
          onClose(data.object_id);
        })
        .catch((error) => console.log(error));
    }
    setIsEdit(!isEdit);
  };

  const handleDateChange = (newValue, newDate = true) => {
    console.log("handleDateChange: ", newValue);
    setTime("");
    if (newDate) {
      var y = newValue.getFullYear();
      var m = newValue.getMonth() + 1;
      var d = newValue.getDate();

      var selectedDate =
        y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
    } else selectedDate = newValue;

    console.log("New Date Value: ", selectedDate);

    setDate(newValue);

    // создаем массив с доступным временем для выбора на выбранную дату
    var tempAvailableTimesOnSelectedDay = [];
    for (var i = 0; i < availableDates.length - 1; i++) {
      if (availableDates[i].includes(selectedDate))
        tempAvailableTimesOnSelectedDay.push(availableDates[i]);
    }

    // проверяем входит ли уже заданное время и дата записи
    // в выбранную дату и добавляем эаданное время к доступному времени к выбору,
    // если входит
    let currentDate = data.issuing_time;
    if (currentDate.split("T")[0] === selectedDate) {
      tempAvailableTimesOnSelectedDay.push(currentDate);
    }

    tempAvailableTimesOnSelectedDay.sort();

    console.log(
      "handleDateChange.availableTimes:",
      tempAvailableTimesOnSelectedDay
    );

    setAvailableTimes(tempAvailableTimesOnSelectedDay);
  };

  const handleTimeChange = (newValue) => {
    console.log("New Time: ", newValue);
    setTime(newValue);
  };

  /**
   * Метод подписания акта выдачи
   */
  const handleKeyResultConfirm = () => {
    // когда status = Подписан, тогда Акт подписан
    // когда status = НеПодписан, тогда Акт не подписан
    var objToSend = {
      object_id: data?.object_id,
      result: actStatus === "signed",
      comment: comment,
    };

    console.log("objToSend: ", objToSend);

    axios
      .post(`${dbLink}${apiPath}/keys_issue/confirm_receipt`, objToSend)
      .then((result) => {
        console.log("RESULT: ", result);

        let actUrl = `${dbLink}${apiPath}/keys_issue/set_transfer_act`;

        let objToSend = {
          transfer_act_id: act.transfer_act_id,
          status: actStatus === "signed" ? "Подписан" : "НеПодписан",
          remarks:
            actStatus === "signed"
              ? showRemark
                ? remark
                : act.remarks
              : act.remarks,
        };

        axios
          .post(actUrl, objToSend)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
            setNotification("error", "Ошибка при передачи статуса акта");
          });
        reloadIssues();
        setNotification("success", "Данные об объекте сохранены");
        onClose(data.object_id);
      })
      .catch((error) => {
        console.log(error);
        setNotification(
          "error",
          error.response?.data?.err_msg ||
            "Ошибка при сохранении данных об объекте"
        );
      });
  };

  const showDateChangeDialogByOperator = () => {
    setShowDateChangeDialog(true);
  };

  const handleAccept = () => {
    handleClickOpenAcceptDialog();
  };

  const handleAcceptClick = () => {
    //console.log(data);

    let url = `${dbLink}${apiPath}/keys_issue/customer_confirmation`;

    let body = {
      object_id: data.object_id,
      update_time: false,
      record_time: data.issuing_time,
    };

    axios
      .post(url, body)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setNotification("success", "Информация о записи успешно обновлена");
          reloadIssues();
          setShowApproveBtn(false);
          setShowApprovedIcon(true);
          handleCloseAcceptDialog();
        } else {
          setNotification(
            "error",
            "Произошла ошибка во время смены даты и времени выдачи ключей"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setNotification(
          "error",
          "Произошла ошибка во время смены даты и времени выдачи ключей"
        );
      });

    handleCloseAcceptDialog();
  };

  const dateChangeDialog = (
    <Modal
      open={showDateChangeDialog}
      aria-labelledby="modal-info-title"
      aria-describedby="modal-info-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          minWidth: "700px",
          bgcolor: "background.paper",
          border: "1px solid #000",
          boxShadow: 24,
          p: 2,
          overflowY: "auto",
          overflowX: "auto",
          maxHeight: "95vh",
        }}
      >
        <DateChangeDialog
          objectId={data?.object_id}
          object={data?.object}
          client={data?.client}
          clientId={data?.client_id}
          onClose={() => setShowDateChangeDialog(false)}
          onSuccess={() => {
            setShowDateChangeDialog(false);
            onClose();
          }}
        />
      </Box>
    </Modal>
  );

  const acceptDialog = (
    <Dialog
      open={openAcceptDialog}
      onClose={handleCloseAcceptDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Подтвердить время для выдачи ключей?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Нажав "подтвердить" произойдет подтверждение записи клиента на
          выбранное время для выдачи ключей. Продолжить?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAcceptDialog}>Отмена</Button>
        <Button onClick={handleAcceptClick} autoFocus>
          Подтвердить
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Вид, если компонент вызывается в опубликованом графике
  const viewPublish = (
    <Grid container>
      <Grid item xs={12} sx={{ paddingBottom: "8px" }}>
        <Typography variant="body1">
          <b>Данные об объекте</b>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ borderBottom: "4px solid #F8F8F9", paddingBottom: "8px" }}
      >
        <Stack spacing={1}>
          <RowPublish
            desc="Дата и время:"
            value={
              data?.issuing_time.replace("T", " ").slice(0, -3) || "Ошибка"
            }
            edit={showEdit}
            accept={showApproveBtn}
            onAccept={handleAccept}
            onEdit={showDateChangeDialogByOperator}
            additionalProp={
              <Tooltip
                title={
                  showApproverIcon
                    ? "Запись подтверждена клиентом"
                    : "Запись не подтверждена клиентом"
                }
              >
                <AccountCircleIcon
                  sx={{ height: "20px", pt: "1px" }}
                  color={showApproverIcon ? "success" : "error"}
                />
              </Tooltip>
            }
          />
          {acceptDialog}
          {dateChangeDialog}
          {/* <RowPublish desc="Объект:" value={data?.object} /> */}
          <div style={{display: "flex"}}>
            <p style={{margin:0, color: "#63707A", fontSize: "0.875rem"}}>Объект: </p>
            {/* <a href={`object_${data?.object_id}`} onClick={(e) => e.preventDefault()} style={{textDecoration: "none", color:"rgba(0, 0, 0, 0.87)", margin:0, fontSize: "0.875rem", cursor: "pointer"}} id={`object_${data?.object_id}`}>&nbsp;{data?.object}</a> */}
            <p onClick={(e) => e.preventDefault()} style={{textDecoration: "none", color:"rgba(0, 0, 0, 0.87)", margin:0, fontSize: "0.875rem", cursor: "pointer"}} id={`object_${data?.object_id}`}>&nbsp;{data?.object}</p>
          </div>
          <div style={{display: "flex"}}>
            <p style={{margin:0, color: "#63707A", fontSize: "0.875rem"}}>Клиент: </p>
            {/* <a href={`client_${data?.client_id}`} onClick={(e) => e.preventDefault()} style={{textDecoration: "none", color:"rgba(0, 0, 0, 0.87)", margin:0, fontSize: "0.875rem", cursor: "pointer"}} id={`client_${data?.client_id}`}>&nbsp;{data?.client}</a> */}
            <p onClick={(e) => e.preventDefault()} style={{textDecoration: "none", color:"rgba(0, 0, 0, 0.87)", margin:0, fontSize: "0.875rem", cursor: "pointer"}} id={`client_${data?.client_id}`}>&nbsp;{data?.client}</p>
          </div>
          <div style={{display: "flex"}}>
            <p style={{margin:0, color: "#63707A", fontSize: "0.875rem"}}>Телефон: </p>
            {/* <a href={`tel_${data?.phone_number}`} onClick={(e) => e.preventDefault()} style={{textDecoration: "none", color:"rgba(0, 0, 0, 0.87)", margin:0, fontSize: "0.875rem", cursor: "pointer"}} id={`tel_${data?.phone_number}`}>&nbsp;{data?.phone_number}</a> */}
            <p onClick={(e) => e.preventDefault()} style={{textDecoration: "none", color:"rgba(0, 0, 0, 0.87)", margin:0, fontSize: "0.875rem", cursor: "pointer"}} id={`tel_${data?.phone_number}`}>&nbsp;{data?.phone_number}</p>
          </div>
            {/* <RowPublish desc="Клиент:" value={data?.client}/> */}
            {/* <RowPublish desc="Телефон:" value={data?.phone_number} /> */}
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: "12px", paddingBottom: "8px" }}>
        <Typography variant="body1">
          <b>Результат выдачи ключей</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1}>
          <Typography variant="body2">Акт приема-передачи:</Typography>
          {/* <a variant="body2" href={`act_${act.transfer_act_id}`} onClick={(e) => e.preventDefault()} id={`act_${act.transfer_act_id}`} style={{fontSize: 14,textDecoration: "none", color:"rgba(0, 0, 0, 0.87)", cursor: "pointer"}}>
            {act.transfer_act_id === "00000000-0000-0000-0000-000000000000"
              ? "Не найден"
              : act.transfer_act}
          </a>  */}
          <p onClick={(e) => e.preventDefault()} id={`act_${act.transfer_act_id}`} style={{fontSize: 14,textDecoration: "none", color:"rgba(0, 0, 0, 0.87)", cursor: "pointer"}}>
            {act.transfer_act_id === "00000000-0000-0000-0000-000000000000"
              ? "Не найден"
              : act.transfer_act}
          </p>
        </Stack>
      </Grid>
      {/*
      <Grid item xs={12}>
        <Typography variant="body2">Принимал ключи:</Typography>
          </Grid>*/}
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="publish-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={actStatus}
            onChange={(e) => handleActChange(e.target.value)}
          >
            <FormControlLabel
              value="signed"
              disabled={act.status === "Подписан"}
              control={<Radio size="small" />}
              label={<Typography variant="body2">Акт подписан</Typography>}
            />
            <FormControlLabel
              value="not-signed"
              disabled={act.status === "Подписан"}
              control={<Radio size="small" />}
              label={<Typography variant="body2">Акт не подписан</Typography>}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {
        // Показываем чекбокс с возможностью добавить замечания по приемке,
        // если акт подписан
        actStatus === "signed" ? (
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={showRemark}
                    onChange={() => setShowRemark(!showRemark)}
                  />
                }
                label={<Typography variant="body2">Есть замечания</Typography>}
              />
            </FormGroup>

            {
              // Показываем поле для ввода замечанич
              showRemark && (
                // <TextField
                //   fullWidth
                //   margin="dense"
                //   size="small"
                //   label="Замечания"
                //   value={remark}
                //   onChange={(e) => setRemark(e.target.value)}
                //   multiline
                //   rows={3}
                //   id="fullWidth"
                // />
                <Button variant="outlined" id="newwarranty">Создать замечание</Button>
              )
            }
          </Grid>
        )
        : 
        <Button variant="outlined" id="newwarranty">Создать замечание</Button>
        
      }
      {
        // Показываем поле для заполнения причины отказа, если акт не подписан
        actStatus === "not-signed" && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="dense"
              size="small"
              label="Причина отказа"
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
              multiline
              rows={3}
              id="fullWidth"
              sx={{display: "none"}}
            />
          </Grid>
        )
      }
      <Grid item xs={12} sx={{ display: "none" }}>
        <Stack direction="row" spacing={1} sx={{ mt: "4px" }}>
          <Typography variant="body2">Заявка на гарантию:</Typography>
          <Typography variant="body2">Не найдена</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: "12px", paddingBottom: "24px" }}>
        <TextField
          fullWidth
          size="small"
          label="Комментарий"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          multiline
          rows={3}
          id="fullWidth"
        />
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
          <Button size="small" variant="outlined" onClick={onClose}>
            Отмена
          </Button>
          <Tooltip
            title={
              act.transfer_act_id === "00000000-0000-0000-0000-000000000000"
                ? "Сохранить результат выдачи возможно только при наличии акта"
                : act.status === "Подписан"
                ? "Подписанные акты нельзя изменить"
                : ""
            }
            disabled={
              act.transfer_act_id !== "00000000-0000-0000-0000-000000000000"
            }
          >
            <span>
              <Button
                disabled={
                  act.transfer_act_id ===
                    "00000000-0000-0000-0000-000000000000" ||
                  act.status === "Подписан"
                }
                size="small"
                variant="contained"
                onClick={handleKeyResultConfirm}
              >
                Сохранить
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </Grid>
    </Grid>
  );

  const viewEdit = (
    <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
      <Grid item xs={12}>
        <Typography variant="h5">Запись о выдаче ключей</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          Клиент:
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body1">{data?.client}</Typography>
      </Grid>

      <Grid item xs={4}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          Объект:
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body1">{data?.object}</Typography>
      </Grid>

      <Grid item xs={4}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          Дата выдачи:
        </Typography>
      </Grid>
      <Grid item xs={8}>
        {isEdit ? (
          <Box sx={{ mt: "18px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
              <DesktopDatePicker
                label=""
                inputFormat="yyyy/MM/dd"
                value={date || null}
                maxDate={maxDate}
                minDate={minDate}
                loading={dateLoading}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField
                    sx={{ mt: "-20px", width: "35%", minWidth: "130px" }}
                    variant="standard"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        ) : (
          <Typography variant="body1">
            {data?.issuing_time.split("T")[0]}
          </Typography>
        )}
      </Grid>

      <Grid item xs={4}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          Время выдачи:
        </Typography>
      </Grid>
      <Grid item xs={8}>
        {isEdit ? (
          <Box sx={{ mt: "-16px" }}>
            <Select
              labelId="select-time-label"
              id="select-time"
              //defaultValue={time}
              value={time}
              onChange={(e) => handleTimeChange(e.target.value)}
              label=""
            >
              {availableTimes.map((t, index) => {
                return (
                  <MenuItem value={t} key={t}>
                    {t?.split("T")[1].slice(0, -3)}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        ) : (
          <Typography variant="body1">
            {data?.issuing_time.split("T")[1].slice(0, -3)}
          </Typography>
        )}
      </Grid>

      <Grid item xs={4}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          Комментарий: {addedComment}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        {isEdit ? (
          <TextField
            fullWidth
            label="Комментарий"
            value={comment}
            onChange={(e) => {setComment(e.target.value);setAddedComment(e.target.value)}}
            multiline
            rows={4}
            id="fullWidth"
          />
        ) : (
          <Typography variant="body1">{comment}</Typography>
        )}
      </Grid>
      {selectedSchedule.status === "Черновик" && (
        <>
          <Grid item xs={8}>
            <Button
              variant="text"
              sx={{ marginTop: "4px" }}
              onClick={() => setOpenDialog(true)}
            >
              Сделать недоступной для записи
            </Button>
            <Dialog
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Сделать ячейку недоступной для записи?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  При объявлении ячейки недоступной для записи данные о времени
                  выдачи для сохраненного объекта недвижимости сбросятся.
                  Продолжить?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDialog(false)}>Отмена</Button>
                <Button onClick={blockRecord} autoFocus>
                  Да
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                float: "right",
              }}
            >
              {isEdit ? (
                <Tooltip title="Отменить редактирование">
                  <IconButton
                    aria-label="delete"
                    onClick={() => setIsEdit(!isEdit)}
                    sx={{ "&:hover": { color: "#127CCA" } }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Удалить запись">
                  <IconButton
                    aria-label="delete"
                    onClick={() => deleteRecord()}
                    sx={{ "&:hover": { color: "#127CCA" } }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Редактировать запись">
                <IconButton
                  aria-label="edit"
                  onClick={() => handleEdit()}
                  sx={{ "&:hover": { color: "#127CCA" } }}
                >
                  {isEdit ? <CheckCircleIcon /> : <EditIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <Box sx={{ flexGrow: 1, margin: "12px" }}>
      <IconButton
        sx={{ position: "absolute", right: 2, top: 2 }}
        aria-label="close"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      {selectedSchedule.status === "Черновик" && viewEdit}
      {selectedSchedule.status === "Опубликован" && viewPublish}
    </Box>
  );
}
