import React, { useState, useContext, useEffect } from "react";
import "./index.css";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { IMaskInput } from "react-imask";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";

export default function Success() {
  return (
    <div className="background">
      <Box
        sx={{
          position: "absolute",
          margin: "auto",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          width: {
            xs: "100%",
            sm: 550,
          },
          height: 300,
          backgroundColor: "#FFFFFF",
          borderRadius: "4px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            sx={{ marginLeft: "24px", marginTop: "24px" }}
          >
            <b>4DEV</b>
          </Typography>
        </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" align="center" sx={{ mt: "24px" }}>Время выдачи успешно назначено</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">Благодарим вас за использование нашего продукта</Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
