/** @jsxRuntime classic */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
//import 'promise-polyfill/src/polyfill';
//import 'core-js/stable';
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ruRU } from "@mui/material/locale";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./pages/components/utils/PrivateRoute";
import MainPage from "./pages/Main";
import LoginPage from "./pages/Login";
import UserZone from "./pages/users";
import UserCalendar from "./pages/users/calendar";
import ActsPage from "./pages/acts";
import ActPage from "./pages/acts/act";
import App from "./App";
import Error404 from "./pages/404";
import configData from "./config.json";
import {
  NotificationContext,
  NotificationContextProvider,
} from "./pages/components/context/NotificationContext";
import { UserContextProvider } from "./pages/components/context/UserContext";
import Success from "./pages/users/success";
import axios from "axios";

const theme = createTheme({
  ruRU,
  palette: {
    background: {
      default: "#F2F2F2",
    },
    success: {
      main: "#00AF07",
      contrastText: "#FFFFFF",
    },
  },
  /*
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  transitions: {
    create: () => 'none',
  },
  */
});

/*
var dbLink = "https://prog3.4dev.app";
var apiPath = "/api/hs/restapi_v1";

const queryParams = new URLSearchParams(window.location.search);
const db = queryParams.get("host");
console.log("db: ", db);
const api = queryParams.get("baseUrl");
console.log("api: ", api);
*/

export const DatabaseContext = React.createContext();

function DbContext({ children }) {
  const [db, setDb] = useState();
  const [api, setApi] = useState();
  const [authToken, setAuthToken] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    console.log("host: ", queryParams.get("host"));
    console.log("token: ", queryParams.get("token"));
    if (
      queryParams.get("host") !== null &&
      queryParams.get("baseUrl") !== null
    ) {
      console.log("Found new db!");
      setDb(queryParams.get("host"));
      setApi(queryParams.get("baseUrl"));
      setAuthToken(queryParams.get("token"))
    } else {
      setDb("https://gds.4dev.app");
      setApi("/api/hs/restapi_v1");
      setAuthToken("");
    }
    setIsLoading(false);
  }, []);

  if (!isLoading)
    return (
      <DatabaseContext.Provider
        value={{ dbLink: db, dbGuid: "", apiPath: api, authToken: authToken }}
      >
        {children}
      </DatabaseContext.Provider>
    );
}

axios.defaults.headers.common["Authorization"] =
  localStorage.getItem("token");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ScopedCssBaseline>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <DbContext>
            <NotificationContextProvider>
              <UserContextProvider>
                <Routes>
                  <Route
                    path="/rec/"
                    element={
                      <PrivateRoute>
                        <App />
                      </PrivateRoute>
                    }
                  />
                  <Route path="/rec/login" element={<LoginPage />} />
                  <Route path="/rec/users" element={<UserZone />} />
                  <Route
                    path="/rec/users/calendar"
                    element={<UserCalendar />}
                  />
                  <Route path="/rec/users/success" element={<Success />} />
                  <Route path="/rec/acts" element={<ActsPage />} />
                  <Route path="/rec/acts/act/" element={<ActPage />} />
                  <Route path="*" element={<Error404 />} />
                </Routes>
              </UserContextProvider>
            </NotificationContextProvider>
          </DbContext>
        </BrowserRouter>
      </ThemeProvider>
    </ScopedCssBaseline>
  </React.StrictMode>
);
