import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { DatabaseContext } from "../../../index";
import jwt_decode from "jwt-decode";

const PrivateRoute = ({ children }) => {
  const { dbLink, dbGuid, apiPath, authToken } = React.useContext(DatabaseContext);
  const navigate = useNavigate();

  function hasJWT() {
    let flag = true;

    console.log("check token");
    console.log("LINK: ", dbLink);
    console.log("API: ", apiPath);
    console.log("FLAG: ", flag);
    console.log("token: ", authToken);

    authToken ? (flag = true) : (flag = false);

    if (flag) {
      console.log("flag1")
      var token = authToken;
      console.log(authToken, "flag1_token")
      //console.log("found token ", token);
      var decodedToken = jwt_decode(token);
      //console.log("user id: ", decodedToken.user_id);

      axios
        .post(`${dbLink}${apiPath}/auth/user`, token)
        .then((response) => {
          if (response.data.success) {
            console.log("valid token");
            localStorage.setItem("token", token);
            console.log("set token to axios");
            axios.interceptors.request.use(function (config) {
              config.headers["Authorization"] = `${token}`;
              return config;
            });
            // setAuthToken(token);
            // return navigate(`/rec`, { replace: true });
          } else {
            console.log("INVALID TOKEN");
            localStorage.clear();
            flag = false;
            //rec?host=https://gds.4dev.app&baseUrl=/api/hs/restapi_v1
            //return navigate(`/rec/login`, { replace: true });
          }
        })
        .catch((err) => alert(err));

      /*
      axios
        .get(`${dbLink}${apiPath}/auth/user`, token, {headers: {"Content-Type": "text/plain"}})
        .then((response) => {
          console.log("RESPONSE LOGIN: ", response);
          if (response.data.success) {
            alert("valid token");
            localStorage.setItem("token", token);
            // setAuthToken(token);
            // return navigate(`/rec`, { replace: true });
          } else {
            console.log("INVALID TOKEN");
            localStorage.clear();
            flag = false;
            //rec?host=https://gds.4dev.app&baseUrl=/api/hs/restapi_v1
            //return navigate(`/rec/login`, { replace: true });
          }
        })
        .catch((err) => {
          alert(err);
          console.log(err);
          localStorage.clear();
          flag = false;
        });
        */
    }

    return flag;
  }

  if (!hasJWT()) {
    return <Navigate to={`/rec/login`} replace />;
  }

  return children;
};

export default PrivateRoute;
