import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ru } from "date-fns/locale/";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { DatabaseContext } from "../../../../../index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 2,
  display: "flex",
  flexDirection: "column",
  gap: "12px",
};

export default function AddSection({ close, add, district, ...rest }) {
  const { dbLink, dbGuid, apiPath } = React.useContext(DatabaseContext);
  const [object, setObject] = useState("");
  const [districtList, setDistrictList] = useState([]);
  const [houseList, setHouseList] = useState([]);
  const [house, setHouse] = useState("");
  const [sectionList, setSectionList] = useState([]);
  const [section, setSection] = useState("");

  useEffect(() => {
    let d = new Date();
    axios
      .get(
        `${dbLink}${apiPath}/building?filter_type=district&id=${district}&commissioned=true&timestamp=${d.toISOString()}`
      )
      .then((res) => {
        const houses = res.data.data;
        console.log(houses);
        setHouseList(houses);
      });
  }, [district]);

  const handleHouseChange = (event) => {
    let h = event.target.value;
    setHouse(h);

    let d = new Date();
    axios
      .get(
        `${dbLink}${apiPath}/section/?id=${h}&filter_type=building&timestamp=${d.toISOString()}`
      )
      .then((res) => {
        const sections = res.data.data;
        console.log(sections);
        setSectionList(sections);
      });
  };

  const handleSectionChange = (event) => {
    console.log(sectionList);
    let s = event.target.value;
    setSection(s);
  };

  const handleAdd = () => {
    //Находим данные по соотв. секции в списке секций
    var resultSection = sectionList.filter((s) => {
      return s.id === section;
    });
    add(resultSection[0]);
    close();
  };

  return (
    <Box sx={style}>
      <Typography variant="h6">Добавление объекта в список</Typography>
      <Typography variant="body1">Выберите дом:</Typography>
      <FormControl variant="standard" sx={{ ml: 1, minWidth: "90%" }}>
        <Select
          labelId="select-house-label"
          id="select-house"
          disabled={houseList.length === 0 ? true : false}
          value={house}
          onChange={handleHouseChange}
        >
          {houseList.map((h, index) => (
            <MenuItem key={index} value={h.id}>
              {h.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography variant="body1">Выберите секцию:</Typography>
      <FormControl variant="standard" sx={{ ml: 1, minWidth: "90%" }}>
        <Select
          labelId="select-section-label"
          id="select-section"
          disabled={sectionList.length === 0 ? true : false}
          value={section}
          onChange={handleSectionChange}
        >
          {sectionList.map((s, index) => (
            <MenuItem key={index} value={s.id}>
              {s.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack
        spacing={2}
        direction="row"
        justifyContent={"flex-end"}
        sx={{ mt: "8px" }}
      >
        <Button variant="outlined" onClick={close}>
          Отмена
        </Button>
        <Button
          variant="contained"
          onClick={handleAdd}
          disabled={section === "" ? true : false}
        >
          Добавить
        </Button>
      </Stack>
    </Box>
  );
}
