import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ruRU } from "@mui/material/locale";
import { Propane, SignalCellularNull } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import axios from "axios";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyIcon from "@mui/icons-material/Key";
import styles from "./List.css";
import SettingsIcon from "@mui/icons-material/Settings";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';

import InfoIssue from "../../components/Record/Info";
import { DataContext } from "../context/DataContext";
import { NotificationContext } from "../context/NotificationContext";
import { DatabaseContext } from "../../../index";

function createData(
  dateAndTime,
  object,
  objectId,
  room,
  client,
  phone,
  comment
) {
  return {
    dateAndTime,
    object,
    objectId,
    room,
    client,
    phone,
    comment,
  };
}

function createPublishedData(
  status,
  keys,
  dateAndTime,
  object,
  objectId,
  room,
  client,
  phone,
  comment
) {
  return {
    status,
    keys,
    dateAndTime,
    object,
    objectId,
    room,
    client,
    phone,
    comment,
  };
}

const rows = [createData("", "", "", "", "", "", "")];

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  ruRU
);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1; 
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {  
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    console.log(a[0] === b[0] && console.log("gijid"))
    return a[1] - b[1];
  });
  console.log(stabilizedThis, "stabilizedThis")
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "dateAndTime",
    numeric: false,
    disablePadding: true,
    label: "Время и дата",
  },
  {
    id: "object",
    numeric: false,
    disablePadding: false,
    label: "Объект",
  },
  {
    id: "room",
    numeric: true,
    disablePadding: false,
    label: "Квартира",
  },
  {
    id: "client",
    numeric: false,
    disablePadding: false,
    label: "Клиент",
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "Телефон",
  },
  {
    id: "comment",
    numeric: false,
    disablePadding: false,
    label: "Комментарий",
  },
];

const headCellsPublished = [
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Статус",
  },
  {
    id: "keys",
    numeric: false,
    disablePadding: false,
    label: "Ключи",
  },
  {
    id: "dateAndTime",
    numeric: false,
    disablePadding: true,
    label: "Время и дата",
  },
  {
    id: "object",
    numeric: false,
    disablePadding: false,
    label: "Объект",
  },
  {
    id: "room",
    numeric: true,
    disablePadding: false,
    label: "Квартира",
  },
  {
    id: "client",
    numeric: false,
    disablePadding: false,
    label: "Клиент",
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "Телефон",
  },
  {
    id: "comment",
    numeric: false,
    disablePadding: false,
    label: "Комментарий",
  },
];

function EnhancedTableHead(props) {
  const {
    rowsData,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    showCheckBoxes,
    scheduleType,
  } = props;
  const createSortHandler = (property) => (event) => {
    console.log(property, event, "property", "event")
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {showCheckBoxes && (
          <TableCell
            padding="checkbox"
            sx={{ border: "1px solid #D7DBDF" /*backgroundColor: "#F2F2F2"*/ }}
          >
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "Выбрать все объекты",
              }}
            />
          </TableCell>
        )}
        {scheduleType === "Черновик"
          ? headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align="right"
                //padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{
                  border: "1px solid #D7DBDF" /*backgroundColor: "#F2F2F2"*/,
                }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={()=>{createSortHandler(headCell.id)}}
                >
                  <b>{headCell.label}</b>
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))
          : headCellsPublished.map((headCell) => (
              <TableCell
                key={headCell.id}
                align="right"
                //padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{
                  border: "1px solid #D7DBDF" /*backgroundColor: "#F2F2F2"*/,
                }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  <b>{headCell.label}</b>
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  showCheckBoxes: PropTypes.bool.isRequired,
  scheduleType: PropTypes.string.isRequired,
};

const EnhancedTableToolbar = ({
  handleChangeDense,
  dense,
  handleChangeDatesLimited,
  datesLimited,
  ...props
}) => {
  const { numSelected, editClick, deleteClick } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    search,
    setSearch
  } = useContext(DataContext);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar
      sx={{
        border: "1px solid #D7DBDF",
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0
          ? {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }
          : { bgcolor: "#F2F2F2" }),
      }}
    >
      {numSelected > 0 ? (
        numSelected === 1 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            Выбрана одна запись
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            Выбрано записей: {numSelected}
          </Typography>
        )
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {props.text}
        </Typography>
      )}

      {numSelected > 0 ? (
        <>
          {numSelected === 1 && (
            <Tooltip title="Редактировать">
              <IconButton onClick={editClick}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Удалить">
            <IconButton onClick={deleteClick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          {/*
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      */}
        </>
      )}
      <TextField 
        id="standard-basic" 
        label="Поиск" 
        variant="standard" 
        value={search}
        onChange={(e) => setSearch(e.target.value)} 
        size="small" 
        fullWidth 
        sx={{marginBottom: "20px"}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {search && (
                <IconButton
                  edge="end"
                  onClick={() => setSearch('')}
                  size="small"
                >
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <Tooltip title="Настройки отображения">
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Уменьшить промежутки между элементами списка"
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Switch
                checked={datesLimited}
                onChange={handleChangeDatesLimited}
              />
            }
            label="Ограничивать список объектов выбранными датами"
          />
        </MenuItem>
      </Menu>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  editClick: PropTypes.func.isRequired,
  deleteClick: PropTypes.func.isRequired,
  handleChangeDense: PropTypes.func.isRequired,
};

const monthNames = [
  "Января",
  "Февраля",
  "Марта",
  "Апреля",
  "Мая",
  "Июня",
  "Июля",
  "Августа",
  "Сентября",
  "Октября",
  "Ноября",
  "Декабря",
];

export default function EnhancedTable({
  date,
  data,
  headerDateLabel,
  ...rest
}) {
  const [rowsData, setRowsData] = useState(rows);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedObj, setSelectedObj] = useState(null);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [datesLimited, setDatesLimited] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openModalInfoIssue, setOpenModalInfoIssue] = useState(false);
  const setNotification = React.useContext(NotificationContext);
  const { dbLink, dbGuid, apiPath } = React.useContext(DatabaseContext);
  const [selectedObjectId, setSelectedObjectId] = useState();

  const {
    objsData,
    changeData,
    reloadIssues,
    selectedSchedule,
    changeSchedule,
    scheduleDate,
    changeScheduleDate,
    selectedPeriod,
    search,
  } = useContext(DataContext);

  useEffect(() => {
    console.log("Selected schedule: ", selectedSchedule);
    console.log("Object data: ", objsData);
    console.log("Schedule date: ", scheduleDate);
    console.log("selectedPeriod: ", selectedPeriod);

    // создаем список для отображения
    var newRows = [];
    //console.log("DATA FOR LIST: ", data);
    //console.log("DATE FOR LIST: ", date);

    // задаем границы дат для отображения данных
    var startDate = "";
    var endDate = "";
    if (selectedPeriod === "day") {
      startDate = new Date(
        scheduleDate?.split(".")[2],
        scheduleDate?.split(".")[1] - 1,
        scheduleDate?.split(".")[0]
      );
      endDate = new Date(
        scheduleDate?.split(".")[2],
        scheduleDate?.split(".")[1] - 1,
        Number(scheduleDate?.split(".")[0]) + 1
      );
    } else {
      startDate = new Date(
        scheduleDate?.split(" - ")[0]?.split(".")[2],
        scheduleDate?.split(" - ")[0]?.split(".")[1] - 1,
        scheduleDate?.split(" - ")[0]?.split(".")[0]
      );
      endDate = new Date(
        scheduleDate?.split(" - ")[1]?.split(".")[2],
        scheduleDate?.split(" - ")[1]?.split(".")[1] - 1,
        Number(scheduleDate?.split(" - ")[1]?.split(".")[0]) + 1
      );
    }

    console.log("START DATE: ", startDate);
    console.log("END DATE: ", endDate);

    try {
      // проверяем выбрана ли опция показывать весь список
      if (typeof data !== "undefined" && data.length > 0 && !datesLimited) {
        data.forEach((el) => {
          var currentDate = new Date(el.issuing_time?.split("T")[0]);
          // отображаем дату в виде "5 Августа, 14:15"
          var date =
            currentDate.getDate() +
            " " +
            monthNames[currentDate.getMonth()] +
            ", " +
            el.issuing_time?.split("T")[1].slice(0, -3);

          // различное отображение, в зависимости от типа графика
          if (selectedSchedule.status === "Черновик") {
            newRows.push(
              createData(
                date, //Number(tempDate.getTime()),
                el.object?.split(",").slice(0, -1), //d.object.includes(", квартира") ? d.object.split(", квартира")[0] : d.object, // объект недвижимости
                el.object_id,
                Number(el.object?.split(" №")[1]), // задаем номер квартиры как number
                el.client || "не указан",
                el.phone_number || "не указан", // номер телефона
                el.comment || "нет"
              )
            );
          } else if (selectedSchedule.status === "Опубликован") {
            newRows.push(
              createPublishedData(
                el.approved_by_client,
                el.keys_received,
                date, //Number(tempDate.getTime()),
                el.object?.split(",").slice(0, -1), //d.object.includes(", квартира") ? d.object.split(", квартира")[0] : d.object, // объект недвижимости
                el.object_id,
                Number(el.object?.split(" №")[1]), // задаем номер квартиры как number
                el.client || "не указан",
                el.phone_number || "не указан", // номер телефона
                el.comment || "нет"
              )
            );
            console.log(newRows, "newrows")
          }
        });

        // сохраняем список в состояние
        setRowsData(newRows);
      } else if (typeof data !== "undefined" && data.length > 0) {
        //var tempDataArr = data.filter(record => )
        data.map((d) => {
          var currentDate = new Date(d.issuing_time?.split("T")[0]);
          //console.log("currentDate: ", currentDate);
          // отображаем дату в виде "5 Августа, 14:15"
          var date =
            currentDate.getDate() +
            " " +
            monthNames[currentDate.getMonth()] +
            ", " +
            d.issuing_time?.split("T")[1].slice(0, -3);

          /*
          var tempDate = new Date(
            d.issuing_time.split("T")[0].split("-")[0],
            d.issuing_time.split("T")[0].split("-")[1]-1,
            d.issuing_time.split("T")[0].split("-")[2],
            d.issuing_time.split("T")[1].split(":")[0],
            d.issuing_time.split("T")[1].split(":")[1],
          );*/

          if (currentDate > startDate && currentDate < endDate) {
            if (selectedSchedule.status === "Черновик") {
              newRows.push(
                createData(
                  date, //Number(tempDate.getTime()),
                  d.object?.split(",").slice(0, -1), //d.object.includes(", квартира") ? d.object.split(", квартира")[0] : d.object, // объект недвижимости
                  d.object_id,
                  Number(d.object?.split(" №")[1]), // задаем номер квартиры как number
                  d.client || "не указан",
                  d.phone_number || "не указан", // номер телефона
                  d.comment || "нет"
                )
              );
            } else if (selectedSchedule.status === "Опубликован") {
              newRows.push(
                createPublishedData(
                  d.approved_by_client,
                  d.keys_received,
                  date, //Number(tempDate.getTime()),
                  d.object?.split(",").slice(0, -1), //d.object.includes(", квартира") ? d.object.split(", квартира")[0] : d.object, // объект недвижимости
                  d.object_id,
                  Number(d.object?.split(" №")[1]), // задаем номер квартиры как number
                  d.client || "не указан",
                  d.phone_number || "не указан", // номер телефона
                  d.comment || "нет"
                )
              );
            }
          }
        });
        setRowsData(newRows);
      } else setRowsData(rows);
    } catch (err) {
      console.log(err);
      setRowsData(rows);
    }

    // const hasDuplicates = objsData.length !== new Set(objsData).size;
  }, [
    data,
    date,
    selectedPeriod,
    scheduleDate,
    objsData,
    selectedSchedule,
    datesLimited,
  ]);

  const handleRequestSort = (event, property) => {
    setSelected([]);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rowsData.map((n) => n.dateAndTime);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, objectId) => {
    console.log("objectId: ", objectId);
    if (rowsData.length > 0) {
      setSelectedObjectId(objectId);
      setSelectedObj(
        objsData.find((obj) => {
          return obj.object_id === objectId;
        })
      );
      setOpenModalInfoIssue(true);
    }


    console.log("selectedObj", selectedObj)

    /*
    if (rowsData.length > 0 && selectedSchedule.status === "Опубликован") {
      setSelectedObjectId(objectId);
      setSelectedObj(
        objsData.find((obj) => {
          return obj.object_id === objectId;
        })
      );
      setOpenModalInfoIssue(true);
    }

    if (
      rowsData.length > 0 &&
      rowsData !== rows &&
      selectedSchedule.status === "Черновик"
    ) {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
      setSelectedName(name);
    }
    */
  };

  const editClick = () => {
    var resultRow = rowsData.filter((obj) => {
      return obj.dateAndTime === selectedName;
    });

    var resultObj = data.filter((obj) => {
      return obj.object_id === resultRow[0].objectId;
    });

    console.log("SELECTED OBJ: ", resultObj[0]);
    console.log("SCHEDUEL: ", selectedSchedule);

    setSelectedObj(resultObj[0]);

    setOpenModalInfoIssue(true);
    setSelected([]);
  };

  const deleteClick = () => {
    var resultRow = rowsData.filter((obj) => {
      return obj.dateAndTime === selectedName;
    });

    var resultObj = data.filter((obj) => {
      return obj.object_id === resultRow[0].objectId;
    });

    deleteRecord(selectedSchedule.schedule_id, resultObj[0].object_id);
    setSelected([]);
  };

  const deleteRecord = (scheduleId, objectId) => {
    axios
      .delete(
        `${dbLink}${apiPath}/keys_issue/manual_record?schedule_id=${scheduleId}&object_id=${objectId}`
      )
      .then((result) => {
        console.log(result);
        reloadIssues();
        setNotification("success", "Запись успешно удалена");
      })
      .catch((error) => console.log(error));
  };

  const handleCloseModaInfoIssue = (objectId) => {
    setOpenModalInfoIssue(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 2,
    overflowY: "auto",
    maxHeight: "90vh",
  };

  const modalWindowInfoIssue = (
    <Modal
      open={openModalInfoIssue}
      aria-labelledby="modal-info-title"
      aria-describedby="modal-info-description"
    >
      <Box sx={style}>
        <InfoIssue
          onClose={handleCloseModaInfoIssue}
          data={selectedObj}
          scheduleId={selectedSchedule?.schedule_id}
        />
      </Box>
    </Modal>
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleChangeDatesLimited = (event) => {
    setDatesLimited(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsData.length) : 0;

  const cellStyle = { border: "1px solid #D7DBDF" };
  

  // useEffect(()=> {

  // }, [search])

  // useEffect(() => {
  //   const filteredItems = objsData.filter((item) =>
  //     item.phone_number.includes(search)
  //   );
  //   setSelectedObj(filteredItems) 
  //   console.log(rowsData, "rowsdata")
  // },
  // [search])

  const rowsPerPageOptions = [5, 10, 25, 'Все'];
  const [rowsPerPage2, setRowsPerPage2] = useState(5);

  const handleChangeRowsPerPage2 = (event) => {
    const value = event.target.value;
    if (value === 'Все') {
      setRowsPerPage(rowsData.length); // Display all rows
    } else {
      setRowsPerPage(parseInt(value, 10));
      setPage(0); // Reset page to 0 when changing rows per page
    }
  };

  // document.getObject = () => {
  //   console.log(objsData)
  // }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "available", ml: "-60px", mt: "-10px" }}>
        {modalWindowInfoIssue}
        <Paper sx={{ width: "calc(100% + 10px)" }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            editClick={editClick}
            deleteClick={deleteClick}
            text={headerDateLabel}
            handleChangeDense={handleChangeDense}
            dense={dense}
            handleChangeDatesLimited={handleChangeDatesLimited}
            datesLimited={datesLimited}
          />
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <caption>
                {rowsData.length <= 1 && rowsData[0]?.client === ""
                  ? "Отсутсвуют данные для отображения"
                  : ""}
              </caption>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rowsData.length}
                showCheckBoxes={false}
                /*
                  rowsData.length > 0 &&
                  rowsData !== rows &&
                  selectedSchedule.status === "Черновик"
                  */
                scheduleType={selectedSchedule.status}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {rowsData.length > 0 && rowsData[0]?.client !== "" ? (
                  stableSort(rowsData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.dateAndTime);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      //var t = new Date(row.dateAndTime)
                      return (
                        <TableRow
                          hover
                          id={index}
                          onClick={(event) =>
                            handleClick(event, row.dateAndTime, row.objectId)
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          {/* Чекбоксы
                        rowsData.length > 0 &&
                          rowsData !== rows &&
                          selectedSchedule.status === "Черновик" && (
                            <TableCell padding="checkbox" sx={cellStyle}>
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                          )
                              */}
                          {
                            // Если график опубликован добавляет колонку "статус"
                            selectedSchedule.status === "Опубликован" && (
                              <>
                                <TableCell
                                  align="center"
                                  sx={{ border: "1px solid #D7DBDF" }}
                                  width={80}
                                >
                                  {row.status && (
                                    <Tooltip
                                      title={
                                        row.status === "true"
                                          ? "Запись подтверждена клиентом"
                                          : "Запись не подтверждена клиентом"
                                      }
                                    >
                                      <AccountCircleIcon
                                        sx={{ mt: "3px" }}
                                        color={
                                          row.status === "true"
                                            ? "success"
                                            : "error"
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ border: "1px solid #D7DBDF" }}
                                  size="small"
                                  width={80}
                                >
                                  {row.keys && (
                                    <Tooltip
                                      title={
                                        row.keys === "true"
                                          ? "Ключи получены клиентом"
                                          : "Ключи не получены"
                                      }
                                    >
                                      <KeyIcon
                                        sx={{
                                          mt: "3px",
                                        }}
                                        color={
                                          row.keys === "true"
                                            ? "success"
                                            : "error"
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                </TableCell>
                              </>
                            )
                          }
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            //padding="none"
                            align="right"
                            sx={{
                              border: "1px solid #D7DBDF",
                              minWidth: "160px",
                            }}
                          >
                            {row.dateAndTime}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{ border: "1px solid #D7DBDF", width: "20%" }}
                          >
                            {row.object}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{ border: "1px solid #D7DBDF", width: "120px" }}
                          >
                            {row.room}
                          </TableCell>
                          <TableCell align="right" sx={cellStyle}>
                            {row.client}
                          </TableCell>
                          <TableCell align="right" sx={cellStyle}>
                            {row.phone}
                          </TableCell>
                          <TableCell align="right" sx={cellStyle}>
                            {row.comment}
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow
                    align="right"
                    sx={{ border: "1px solid #D7DBDF" }}
                  ></TableRow>
                )}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage2}
            labelRowsPerPage="Количество страниц:"
            SelectProps={{
              native: true,
              value: rowsPerPage === rowsData.length ? 'Все' : rowsPerPage,
            }}
            sx={{
              borderLeft: "1px solid #D7DBDF",
              borderRight: "1px solid #D7DBDF",
            }}
          />
        </Paper>
      </Box>
    </ThemeProvider>
  );
}
