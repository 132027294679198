import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import PrintIcon from "@mui/icons-material/Print";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function Act() {
  return (
    <Box sx={{ margin: "22px" }}>
      <Grid container rowSpacing={1}>
        <Grid item xs={8}>
          <Stack spacing={2} direction="row">
            <Button variant="contained" endIcon={<ExpandMoreIcon />}>Действия</Button>
            <Button variant="outlined" endIcon={<AddCircleOutlineIcon />}>Новый акт</Button>
            <Button variant="text" startIcon={<PrintIcon />}>
              Печать
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Typography mt={1} variant="body2" align="right">
            Статус: На оформлении
          </Typography>
        </Grid>

        <Grid item xs={11} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">
            <b>Клиент:</b> Пономарёва Лилия Витальевна
          </Typography>
        </Grid>
        <Grid item xs={1} align="right">
          <IconButton aria-label="copy">
            <InsertDriveFileOutlinedIcon />
          </IconButton>
        </Grid>

        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">
            <b>Недвижимость:</b> Троллейная 4, кв. 210
          </Typography>
        </Grid>
        <Grid item xs={5} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">
            <b>Площадь:</b> 72, м2
          </Typography>
        </Grid>
        <Grid item xs={1} align="right">
          <IconButton aria-label="copy">
            <InsertDriveFileOutlinedIcon />
          </IconButton>
        </Grid>

        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">
            <b>Передаточный акт номер:</b> 153462
          </Typography>
        </Grid>
        <Grid item xs={5} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">
            <b>Организация:</b> ООО “СМУ-14”
          </Typography>
        </Grid>
        <Grid item xs={1} align="right">
          <IconButton aria-label="copy">
            <InsertDriveFileOutlinedIcon />
          </IconButton>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" mt={1}>
            <b>Дата оформления:</b> 22.июн.2022
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" mt={1}>
            <b>Дата подписания:</b> —
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" mt={2}>
            <b>Тип собственности:</b> Единоличная
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" mt={2}>
            <b>Вид документа:</b> Передаточный акт
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <hr color="#D7DBDF" />
        </Grid>

        <Grid item xs={11} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">
            Договор: Договор (с покупателем) № от 21.06.2021 (Подписан) на сумму
            2 110 310 RUB
          </Typography>
        </Grid>
        <Grid item xs={1} align="right">
          <IconButton aria-label="copy">
            <InsertDriveFileOutlinedIcon />
          </IconButton>
        </Grid>

        <Grid item xs={11} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">
            Договор ДУ: Договор (с покупателем) № от 21.06.2021 (Подписан) на
            сумму 2 110 310 RUB
          </Typography>
        </Grid>
        <Grid item xs={1} align="right">
          <IconButton aria-label="copy">
            <InsertDriveFileOutlinedIcon />
          </IconButton>
        </Grid>

        <Grid item xs={12}>
          <hr color="#D7DBDF" />
        </Grid>

        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Сторона 1</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container rowSpacing={2}>
                <Grid item xs={1}>
                  <Stack spacing={0.5} direction="column">
                    <Typography>№</Typography>
                    <Typography>1</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={5}>
                  <Stack spacing={0.5} direction="column">
                    <Typography>Организация:</Typography>
                    <Typography>ООО “СМУ-14”</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={0.5} direction="column">
                    <Typography>Банковский счет:</Typography>
                    <Typography>
                      23415678453647539076 в Северо-Западный...
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <Stack spacing={0.5} direction="column">
                    <Typography>Подписант:</Typography>
                    <Typography>Кольчугин Михаил Михайлович</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={0.5} direction="column">
                    <Typography>Должность:</Typography>
                    <Typography>Директор</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Сторона 2</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container rowSpacing={2}>
                <Grid item xs={1}>
                  <Stack spacing={0.5} direction="column">
                    <Typography>№</Typography>
                    <Typography>1</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={5}>
                  <Stack spacing={0.5} direction="column">
                    <Typography>Подписант:</Typography>
                    <Typography>Пономарёва Лилия Витальевна</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={0.5} direction="column">
                    <Typography>Доверенное лицо:</Typography>
                    <Typography>—</Typography>
                  </Stack>
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <Stack spacing={0.5} direction="column">
                    <Typography>Должность / представление:</Typography>
                    <Typography>—</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={0.5} direction="column">
                    <Typography>Основание:</Typography>
                    <Typography>—</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={11} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">
            <b>Комментарий:</b>
          </Typography>
        </Grid>
        <Grid item xs={1} align="right">
          <IconButton aria-label="edit">
            <EditOutlinedIcon />
          </IconButton>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1">
            В частности, высокое качество позиционных исследований обеспечивает
            актуальность укрепления моральных ценностей.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <hr color="#D7DBDF" />
        </Grid>

        <Grid item xs={11} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">Подразделение: Отдел продаж</Typography>
        </Grid>
        <Grid item xs={1} align="right">
          <IconButton aria-label="edit">
            <EditOutlinedIcon />
          </IconButton>
        </Grid>

        <Grid item xs={12}>
          <hr color="#D7DBDF" />
        </Grid>

        <Grid item xs={11} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">Дополнительные свойства</Typography>
        </Grid>
        <Grid item xs={1} align="right">
          <IconButton aria-label="add-props">
            <AddBoxOutlinedIcon />
          </IconButton>
        </Grid>

        <Grid item xs={12}>
          <hr color="#D7DBDF" />
        </Grid>

        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">Автор акта: Третьяков Арсений Михайлович</Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">Ответственный: Третьяков Арсений Михайлович</Typography>
        </Grid>

      </Grid>
    </Box>
  );
}
