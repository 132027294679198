import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(
  date,
  number,
  signDate,
  object,
  property,
  client,
  organisation,
  reservNumber,
  manager
) {
  return {
    date,
    number,
    signDate,
    object,
    property,
    client,
    organisation,
    reservNumber,
    manager,
  };
}

const rows = [
  createData(
    "14.июн.2022",
    2223434,
    "14.июн.2022",
    "Троллейная 4, кв, 88",
    "Единоличная",
    "Пономарёва Л.В.",
    'ООО "СМУ-14"',
    "456378",
    "Третьяков А.М."
  ),
  createData(
    "14.июн.2022",
    2223434,
    "14.июн.2022",
    "Троллейная 4, кв, 88",
    "Единоличная",
    "Пономарёва Л.В.",
    'ООО "СМУ-14"',
    "456378",
    "Третьяков А.М."
  ),
  createData(
    "14.июн.2022",
    2223434,
    "14.июн.2022",
    "Троллейная 4, кв, 88",
    "Единоличная",
    "Пономарёва Л.В.",
    'ООО "СМУ-14"',
    "456378",
    "Третьяков А.М."
  ),
  createData(
    "14.июн.2022",
    2223434,
    "14.июн.2022",
    "Троллейная 4, кв, 88",
    "Единоличная",
    "Пономарёва Л.В.",
    'ООО "СМУ-14"',
    "456378",
    "Третьяков А.М."
  ),
  createData(
    "14.июн.2022",
    2223434,
    "14.июн.2022",
    "Троллейная 4, кв, 88",
    "Единоличная",
    "Пономарёва Л.В.",
    'ООО "СМУ-14"',
    "456378",
    "Третьяков А.М."
  ),
  createData(
    "14.июн.2022",
    2223434,
    "14.июн.2022",
    "Троллейная 4, кв, 88",
    "Единоличная",
    "Пономарёва Л.В.",
    'ООО "СМУ-14"',
    "456378",
    "Третьяков А.М."
  ),
  createData(
    "14.июн.2022",
    2223434,
    "14.июн.2022",
    "Троллейная 4, кв, 88",
    "Единоличная",
    "Пономарёва Л.В.",
    'ООО "СМУ-14"',
    "456378",
    "Третьяков А.М."
  ),
  createData(
    "14.июн.2022",
    2223434,
    "14.июн.2022",
    "Троллейная 4, кв, 88",
    "Единоличная",
    "Пономарёва Л.В.",
    'ООО "СМУ-14"',
    "456378",
    "Третьяков А.М."
  ),
];

const tableHeadStyle = { border: "1px solid #D7DBDF", fontWeight: "bold" };
const tableCellStyle = { border: "1px solid #D7DBDF" };
export default function ActsList() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="acts table">
        <TableHead sx={{ backgroundColor: "#F8F8F9" }}>
          <TableRow>
            <TableCell align="left" sx={tableHeadStyle}>
              Дата
            </TableCell>
            <TableCell align="left" sx={tableHeadStyle}>
              Номер
            </TableCell>
            <TableCell align="left" sx={tableHeadStyle}>
              Подписан
            </TableCell>
            <TableCell align="left" sx={tableHeadStyle}>
              Объект
            </TableCell>
            <TableCell align="left" sx={tableHeadStyle}>
              Собственность
            </TableCell>
            <TableCell align="left" sx={tableHeadStyle}>
              Клиент
            </TableCell>
            <TableCell align="left" sx={tableHeadStyle}>
              Организация
            </TableCell>
            <TableCell align="left" sx={tableHeadStyle}>
              Бронь
            </TableCell>
            <TableCell align="left" sx={tableHeadStyle}>
              Ответственый
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              hover
              key={row.number}
              onClick={() => window.open("/rec/acts/act?id=12309")}
              //sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              sx={{ "&:hover":{cursor: "pointer"}}}
            >
              <TableCell scope="row" sx={tableCellStyle} size="small">
                {row.date}
              </TableCell>
              <TableCell component="th" align="left" size="small">
                {row.number}
              </TableCell>
              <TableCell align="left" sx={tableCellStyle} size="small">
                {row.signDate}
              </TableCell>
              <TableCell align="left" sx={tableCellStyle} size="small">
                {row.object}
              </TableCell>
              <TableCell align="left" sx={tableCellStyle} size="small">
                {row.property}
              </TableCell>
              <TableCell align="left" sx={tableCellStyle} size="small">
                {row.client}
              </TableCell>
              <TableCell align="left" sx={tableCellStyle} size="small">
                {row.organisation}
              </TableCell>
              <TableCell align="left" sx={tableCellStyle} size="small">
                {row.reservNumber}
              </TableCell>
              <TableCell align="left" sx={tableCellStyle} size="small">
                {row.manager}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
