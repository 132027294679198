import axios from "axios";
import React, { useContext, useEffect, useState, useCallback } from "react";
import FullCalendar from "@fullcalendar/react";
import { useSearchParams } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MonthPicker } from "@mui/x-date-pickers/MonthPicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import endOfWeek from "date-fns/endOfWeek";
import isSameDay from "date-fns/isSameDay";
import isWeekend from "date-fns/isWeekend";
import { addDays } from "date-fns";
import isWithinInterval from "date-fns/isWithinInterval";
import { ru } from "date-fns/locale/";
import startOfWeek from "date-fns/startOfWeek";
import { parseISO } from "date-fns";
import ruLocale from "@fullcalendar/core/locales/ru";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import { createEventId } from "../../components/DataViews/Calendar/event-utils";
import UserConfirmDate from "../components/Dialog/UserConfirmDate";
import { DatabaseContext } from "../../..";
import { NotificationContext } from "../../components/context/NotificationContext";
import { UserContext } from "../../components/context/UserContext";
import styles from "./index.css";
import useWindowDimensions from "../../components/utils/useWindowDimensions";
import { Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function checkTime(startTime, endTime, timeToCheck) {
  const startTimeArr = startTime.split(":");
  const endTimeArr = endTime.split(":");
  const timeToCheckArr = timeToCheck.split(":");
  const startTimeInMinutes = startTimeArr[0] * 60 + +startTimeArr[1];
  const endTimeInMinutes = endTimeArr[0] * 60 + +endTimeArr[1];
  const timeToCheckInMinutes = timeToCheckArr[0] * 60 + +timeToCheckArr[1];
  return (
    timeToCheckInMinutes >= startTimeInMinutes &&
    timeToCheckInMinutes <= endTimeInMinutes
  );
}

function timeArray(start, end, step) {
  let startTime = start.split(":");
  let endTime = end.split(":");
  let currentTime = startTime;
  let result = [];
  while (
    currentTime[0] * 60 + +currentTime[1] <
    endTime[0] * 60 + +endTime[1]
  ) {
    result.push(currentTime.join(":"));
    currentTime[1] = +currentTime[1] + step;
    if (currentTime[1] > 59) {
      currentTime[0] = +currentTime[0] + 1;
      currentTime[1] = 0;
      if (currentTime[0] < 10) {
        currentTime[0] = "0" + currentTime[0];
      }
    }
    if (currentTime[1] < 10) {
      currentTime[1] = "0" + currentTime[1];
    }
  }
  return result;
}

var days = [
  "Воскресенье",
  "Понедельник",
  "Вторник",
  "Среда",
  "Четверг",
  "Пятница",
  "Суббота",
];

var daysMobile = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];

function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(d.setDate(diff));
}

var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0");
var yyyy = today.getFullYear();

today = mm + "/" + dd + "/" + yyyy;

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

export default function Calendar() {
  const {
    userObject,
    phone,
    loadUser,
    isShareholder,
    shareholder,
    selectedScheduleId,
  } = useContext(UserContext);
  const { height, width } = useWindowDimensions();
  const { dbLink, apiPath } = useContext(DatabaseContext);
  const setNotification = React.useContext(NotificationContext);
  const [schedule, setSchedule] = useState();
  const [availableTimes, setAvailableTimes] = useState([]);
  const [period, setPeriod] = useState("week");
  const [showWeekends, setShowWeekends] = useState(true);
  const [error, setError] = useState(null);
  const calendarRef = React.useRef(null);
  const [currentDate, setCurrentDate] = useState("2022-08-01");
  const [eventDuration, setEventDuration] = useState("00:15:00");
  const [dataEvents, setDataEvents] = useState([]);
  const [currentIssue, setCurrentIssue] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [reqMethod, setReqMethod] = useState("post");
  const [issueTime, setIssueTime] = useState();

  // dialog
  const [openAlert, setOpenAlert] = useState(false);
  const handleClickOpenAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  // Burger menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [date, setDate] = useState({
    start: new Date(),
    end: new Date(),
    current: new Date(),
  });

  const loadData = useCallback(() => {
    let d = new Date();
    axios
      .get(
        `${dbLink}${apiPath}/keys_issue/queue?object_id=${
          userObject.object_id
        }&timestamp=${d.toISOString()}`
      )
      .then((res) => {
        console.log("result: ", res);
        /*
        var scheduleId;
        if (isShareholder) {
          let o = shareholder?.objects?.find(
            (el) => el.object_id === userObject.object_id
          );
          console.log("o: ", o);
          scheduleId = o.shedule_id;
        } else {
          scheduleId = res.data[0].schedule_id; //selectedScheduleId
        }
        */
        console.log("schedule id: ", selectedScheduleId);

        let url = `${dbLink}${apiPath}/keys_issue/get_schedule?schedule_id=${selectedScheduleId}&timestamp=${d.toISOString()}`;

        axios.get(url).then((res) => {
          let loadedSchedule = res.data[0];

          setSchedule(loadedSchedule);

          console.log("LOADED SCHEDULE: ", loadedSchedule);

          // Перемещаем указатель даты на дату начала очереди
          var tempDate = new Date(
            Number(loadedSchedule.issue_start.split("-")[0]),
            Number(loadedSchedule.issue_start.split("-")[1]) - 1,
            Number(loadedSchedule.issue_start.split("-")[2].split("T")[0]) + 1
          );

          // TODO: исправить, заставляет срабатывать зависимость в другом useEffect()
          setDate({
            end: parseISO(loadedSchedule.issue_end),
            start: parseISO(loadedSchedule.issue_start),
            current: parseISO(loadedSchedule.issue_start),
          });
          console.log(tempDate);

          // задаем длительность слота для записи
          setEventDuration("00:" + loadedSchedule.time_on_issue + ":00");

          // получаем доступные слоты для записи
          let d = new Date();
          axios
            .get(
              `${dbLink}${apiPath}/keys_issue/available_period?schedule_id=${
                loadedSchedule.schedule_id
              }&timestamp=${d.toISOString()}&client_id=${userObject.client_id}`
            )
            .then((res) => {
              //console.log("Result for av.times", res); // res.data

              // проверяем, основное время используется или альтернативное
              if (res.data.success) {
                var availableDates =
                  res.data.data.main_period.length > 0
                    ? res.data.data.main_period
                    : res.data.data.alternative_period;
                //console.log("DATES: ", availableDates);

                // приводим свободные слоты к заданному виду для отображения на календаре
                var tempDataArr = [];

                // Проверяем обед
                let dinnerTime = [];

                if (loadedSchedule.have_dinner === "true") {
                  dinnerTime = timeArray(
                    loadedSchedule.dinner_start_time.split("T")[1],
                    loadedSchedule.dinner_end_time.split("T")[1],
                    Number(loadedSchedule.time_on_issue)
                  );
                }

                // Проверяем заблокированные слоты
                var blockedTimes = [];
                let d = new Date();
                axios
                  .get(
                    `${dbLink}${apiPath}/keys_issue/not_available_period?schedule_id=${
                      loadedSchedule.schedule_id
                    }&timestamp=${d.toISOString()}&client_id=${userObject.client_id}`
                  )
                  .then((res) => {
                    res.data.data.forEach((el) => {
                      blockedTimes.push(el.start_date);
                    });

                    availableDates.forEach((el) => {
                      if (
                        !dinnerTime.includes(el.split("T")[1]) &&
                        !blockedTimes.includes(el) &&
                        !checkTime(
                          loadedSchedule.dinner_start_time.split("T")[1],
                          loadedSchedule.dinner_end_time.split("T")[1],
                          el.split("T")[1]
                        )
                      ) {
                          tempDataArr.push({
                            id: createEventId(),
                            title: "",
                            start: el,
                            backgroundColor: "#90caf9",
                            borderColor: "rgba(111,111,111,0.2)",
                            current: false,
                            issue: {
                              issuing_time: el,
                              current: false,
                            },
                          });
                      }
                    });

                    //console.log("USER OBJECT: ", userObject);

                    //if (!isShareholder) {
                    // текущая дата выдачи
                    tempDataArr.push({
                      id: createEventId(),
                      title: "",
                      start: userObject.issuing_time || issueTime,
                      backgroundColor: "#cddc39",
                      borderColor: "rgba(111,111,111,0.2)",
                      current: true,
                      issue: {
                        issuing_time: userObject.issuing_time || issueTime,
                        current: true,
                      },
                    });
                    //}

                    setDataEvents(tempDataArr);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            })
            .catch((err) => {
              console.log(err);
              setNotification(
                "error",
                "Ошибка при загрузке доступного времени для выдачи"
              );
            });
        });
      })
      .catch((err) => {
        console.log(err);
        setNotification(
          "error",
          "Произошла ошибка при загрузке данных по очереди выдачи"
        );
      });
  }, [userObject, dbLink, reload, reqMethod]);

  /**
   * Загружаем данные по очереди выдачи ключей
   */
  useEffect(() => {
    console.log("Loaded user object: ", userObject);
    if (userObject?.object_id) {
      loadData();
    } else {
      window.open(`/rec/users/?host=${dbLink}&baseUrl=${apiPath}`, "_self");
    }
  }, [userObject, dbLink, reload]);

  // меняем время и период отображения
  useEffect(() => {
    const { current: calendarDom } = calendarRef;
    const API = calendarDom ? calendarDom.getApi() : null;
    API &&
      API.changeView(
        period === "month"
          ? "dayGridMonth"
          : period === "week"
          ? "timeGridWeek"
          : "timeGridDay"
      );
    var y = date.start.getFullYear().toString();
    var m = (date.start.getMonth() + 1).toString();
    var d = date.start.getDate().toString();

    var dd =
      y +
      "-" +
      (m.length < 2 ? "0" + m : m) +
      "-" +
      (d.length < 2 ? "0" + d : d);
    //console.log("goto date: ", dd);
    API && API.gotoDate(dd);
    setCurrentDate(dd);
  }, [period, date]);

  const getWeekFromDate = (d) => {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6 : 1);
    var start = new Date(d.setDate(diff));
    var end = new Date(d.setDate(diff + 6));
    return [start, end];
  };

  const getMonthFromDate = (d) => {
    var start = new Date(d.getFullYear(), d.getMonth(), 1);
    var end = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    return [start, end];
  };

  const renderWeekPickerDay = (d, selectedDates, pickersDayProps) => {
    if (!date.current) {
      return <PickersDay {...pickersDayProps} />;
    }

    if (period !== "week") {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = startOfWeek(date.current, { weekStartsOn: 1 });
    const end = endOfWeek(date.current, { weekStartsOn: 1 });

    const dayIsBetween = isWithinInterval(d, { start, end });
    const isFirstDay = isSameDay(d, start);
    const isLastDay = isSameDay(d, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  const todayClick = () => {
    var today = new Date().getTime;
    console.log(today);
  };

  const handleDateChange = (newDate) => {
    console.log(newDate);
    switch (period) {
      case "day":
        setDate({ start: newDate, end: newDate, current: newDate });
        break;
      case "week":
        setDate({
          start: getWeekFromDate(newDate)[0],
          end: getWeekFromDate(newDate)[1],
          current: newDate,
        });
        break;
      case "month":
        setDate({
          start: getMonthFromDate(newDate)[0],
          end: getMonthFromDate(newDate)[1],
          current: newDate,
        });
        break;
      default:
        setError(true);
        break;
    }
  };

  const handlePeriodChange = (newPeriod) => {
    setPeriod(newPeriod);
  };

  /**
   * Рендер дней в календарь
   * @param {*} eventInfo
   * @returns
   */
  const renderEventContent = (eventInfo) => {
    //console.log("EVENT INFO: ", eventInfo.event.extendedProps.current);
    const { current: calendarDom } = calendarRef;
    const API = calendarDom ? calendarDom.getApi() : null;
    return (
      <Tooltip
        title={
          eventInfo.event.extendedProps.current
            ? "Ваша запись"
            : "Доступное время для записи"
        }
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {(!eventInfo.event.extendedProps?.current && width > 480) ||
          (API.view?.type === "timeGridDay" &&
            !eventInfo.event.extendedProps?.current) ? (
            <AddCircleIcon
              color="primary"
              sx={{ width: "16px", mr: "2px", ml: "2px" }}
            />
          ) : (
            <></>
          )}

          <Typography variant="body1" align="center" width={"100%"}>
            {eventInfo.timeText.length === 2
              ? eventInfo.timeText + ":00"
              : eventInfo.timeText}

            <i>{"\xa0" + eventInfo.event.title}</i>
          </Typography>
        </Box>
      </Tooltip>
    );
  };

  const handleEventClick = (clickInfo) => {
    //console.log("Event info: ", clickInfo.event);

    if (!clickInfo.event.extendedProps?.current) {
      // устанавливаем выбранное событие
      setCurrentIssue(clickInfo.event.extendedProps.issue);
      setOpenModal(true);
    } else {
      handleClickOpenAlert();
    }
  };

  function dateFromString(s) {
    var bits = s.split(/[-T:]/g);
    var d = new Date(bits[0], bits[1] - 1, bits[2]);
    d.setHours(bits[3], bits[4], bits[5]);

    return d;
  }

  const sendNewDate = () => {
    let url = `${dbLink}${apiPath}/keys_issue/customer_confirmation`;

    let body = {
      object_id: userObject.object_id,
      update_time: true,
      record_time: currentIssue.issuing_time,
      client_id: isShareholder ? shareholder.client_id : userObject.client_id,
    };

    console.log("Schedule: ", schedule);
    console.log("User: ", userObject);
    console.log("Current issue: ", currentIssue.issuing_time);
    console.log("Body: ", body);

    axios
      .post(url, body)
      .then((res) => {
        console.log("POST CUSTOMER: ", res);
        if (res.data.success) {
          setNotification("success", "Информация о записи успешно обновлена");
          // Обновляем данные по клиенту
          //loadUser(userObject.client_id, userObject.object_id);
          window.open("/rec/users/success/", "_self");
        } else {
          setNotification(
            "error",
            "Произошла ошибка во время смены даты и времени выдачи ключей"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setNotification(
          "error",
          "Произошла ошибка во время смены даты и времени выдачи ключей"
        );
      });
    setOpenModal(false);
  };

  const setTime = () => {
    console.log("SET NEW TIME");
    let url = `${dbLink}${apiPath}/keys_issue/manual_record`;

    console.log("UserObj: ", userObject);

    let body = {
      schedule_id: schedule.schedule_id,
      alternative_record: false,
      object_id: userObject.object_id,
      client_id: userObject.client_id,
      issue_time: currentIssue.issuing_time,
      comment: "",
    };

    console.log("Schedule: ", schedule);
    console.log("User: ", userObject);
    console.log("Current issue: ", currentIssue.issuing_time);
    console.log("Body: ", body);

    let config = {
      method: reqMethod,
      url: url,
      data: body,
    };
    axios(config)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setNotification("success", "Информация о записи успешно обновлена");
          // Обновляем данные по клиенту
          loadUser(userObject.client_id, userObject.object_id);
          setReqMethod("put");
          setIssueTime(currentIssue.issuing_time);
          setReload(!reload);
        } else {
          setNotification(
            "error",
            "Произошла ошибка во время смены даты и времени выдачи ключей"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setNotification(
          "error",
          "Произошла ошибка во время смены даты и времени выдачи ключей"
        );
      });
    setOpenModal(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width <= 880 ? "100%" : 600,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 2,
    overflowY: "auto",
    maxHeight: "90vh",
  };

  const modalWindowConfirm = (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <UserConfirmDate
          onOk={() =>
            userObject.issuing_time === "" ? setTime() : sendNewDate()
          }
          onClose={() => setOpenModal(false)}
          newDate={currentIssue?.issuing_time?.slice(0, -3).replace("T", " в ")}
          object={
            isShareholder
              ? shareholder?.objects?.find(
                  (el) => el.object_id === userObject.object_id
                )?.object_label
              : userObject?.object
          }
          schedule={schedule}
          client={isShareholder ? shareholder?.client : userObject?.client}
          isShareholder={isShareholder}
        />
      </Box>
    </Modal>
  );

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          height: "80px",
          backgroundColor: "#D0E2FA",
          overflow: "hidden",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 1, sm: 8, md: 8 }}
          sx={{ display: "flex", alignItems: "center", height: "80px" }}
        >
          <Typography
            variant={width <= 880 ? (width <= 425 ? "body1" : "h6") : "h5"}
            sx={{
              paddingLeft: { xs: "8px", sm: "24px" },
              width: { xs: "85%" },
            }}
          >
            <b>{schedule?.schedule}</b>
          </Typography>
          {width <= 880 && (
            <>
              <IconButton
                aria-label="burger"
                size="large"
                onClick={handleClick}
              >
                <MenuIcon fontSize="inherit" />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showWeekends}
                        onChange={() => setShowWeekends(!showWeekends)}
                      />
                    }
                    label="Показывать выходные"
                  />
                </MenuItem>
              </Menu>
            </>
          )}
        </Stack>

        {/*
        <Typography
          variant={width <= 880 ? (width <= 425 ? "body1" : "h6") : "h5"}
          sx={{
            padding: "12px",
            width: { xs: "100%" },
          }}
        >
          <b>{schedule?.schedule}</b>
        </Typography>
        */}
      </Box>
      <Stack sapcing={0} direction="row">
        <Box
          sx={{
            width: "476px",
            height: "calc(100% - 80px)",
            display: width <= 880 ? "none" : "auto",
          }}
        >
          <Stack
            spacing={0.5}
            direction="column"
            sx={{
              padding: "16px",
              margin: "16px",
              boxShadow:
                "1px 1px 2px rgba(27, 39, 47, 0.05), 1px 1px 30px rgba(27, 39, 47, 0.05)",
              borderRadius: "4px",
            }}
          >
            <Typography variant="body2">
              <b>{isShareholder ? shareholder?.client : userObject?.client}</b>
            </Typography>
            <Typography variant="body2" color={"#63707A"}>
              {phone}
            </Typography>
            <Typography variant="body2">
              <b>
                {isShareholder
                  ? shareholder?.objects?.find(
                      (el) => el.object_id === userObject?.object_id
                    )?.object_label
                  : userObject?.object}
              </b>
            </Typography>
            {isShareholder ? (
              <></>
            ) : (
              <>
                <Typography variant="body2">
                  Текущая запись назначена на:
                </Typography>
                <Typography variant="body2">
                  <b>
                    {userObject?.issuing_time?.slice(0, -3).replace("T", " в ")}
                  </b>
                </Typography>
              </>
            )}
            <Typography variant="body2">
              Чтобы изменить дату и время записи, выберите доступные дату и
              время в графике
            </Typography>
            <Typography variant="body2">
              Если возникли вопросы или сложности с перезаписью, свяжитесь со
              своим менеджером.
            </Typography>
            {/*
            <Stack spacing={1} direction="row">
              <Typography variant="body2" color={"#63707A"}>
                Менеджер:
              </Typography>
              <Typography variant="body2">
                Третьяков Арсений Михайлович
              </Typography>
            </Stack>
            <Stack spacing={2} direction="row">
              <Typography variant="body2" color={"#63707A"}>
                Телефон:
              </Typography>
              <Typography variant="body2">+7 999 343 33 33</Typography>
            </Stack>
          */}
          </Stack>

          <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
              {period !== "month" ? (
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  label="date picker"
                  shouldDisableDate={showWeekends ? "" : isWeekend}
                  value={date.current}
                  onChange={(newValue) => {
                    handleDateChange(newValue);
                  }}
                  renderDay={renderWeekPickerDay}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat="'Week of' MMM d"
                  minDate={getMonday(parseISO(schedule?.issue_start))}
                  maxDate={addDays(parseISO(schedule?.issue_end), 1)}
                  showDaysOutsideCurrentMonth={true}
                />
              ) : (
                <MonthPicker
                  date={date.current}
                  sx={{ margin: "0 auto" }}
                  minDate={
                    new Date(
                      new Date().setFullYear(new Date().getFullYear() - 1)
                    )
                  }
                  maxDate={
                    new Date(
                      new Date().setFullYear(new Date().getFullYear() + 1)
                    )
                  }
                  onChange={(newValue) => {
                    handleDateChange(newValue);
                  }}
                />
              )}
            </LocalizationProvider>
          </Box>
          <div className="today-container">
            <div className="today-text" onClick={() => todayClick()}>
              <Typography variant="body1">Сегодня {today}</Typography>
            </div>
          </div>

          <div className="period-control">
            <Stack spacing={2} direction="row" className="period-btns">
              <Button
                onClick={() => handlePeriodChange("day")}
                variant={period === "day" ? "contained" : "outlined"}
              >
                День
              </Button>
              <Button
                onClick={() => handlePeriodChange("week")}
                variant={period === "week" ? "contained" : "outlined"}
              >
                Неделя
              </Button>
              <Button
                onClick={() => handlePeriodChange("month")}
                variant={period === "month" ? "contained" : "outlined"}
              >
                Месяц
              </Button>
            </Stack>

            <div className="period-check">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showWeekends}
                    onClick={() => setShowWeekends(!showWeekends)}
                  />
                }
                label="Показывать выходные"
              />
            </div>
          </div>
        </Box>
        <Box
          sx={{
            backgroundColor: "#F8F8F9",
            width: "100%",
            height: "calc(100vh - 80px)",
            paddingLeft: { xs: "4px", sm: "20px" },
            paddingRight: { xs: "4px", sm: "20px" },
          }}
        >
          <div className="calendar-main">
            <FullCalendar
              ref={calendarRef}
              locale={ruLocale}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              /*headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}*/
              headerToolbar={
                width <= 880
                  ? {
                      left: "prev,today,next",
                      center: "",
                      right: "timeGridWeek,timeGridDay",
                    }
                  : {
                      left: "",
                      center: "",
                      right: "",
                    }
              }
              dayHeaderFormat={function (e) {
                return (
                  <Stack sx={{ overflow: "hidden" }}>
                    {calendarRef.current?.getApi()?.view?.type !==
                    "dayGridMonth" ? (
                      <Typography variant="body1">
                        {e.date.day}.{e.date.month + 1}
                      </Typography>
                    ) : (
                      <></>
                    )}
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                      {calendarRef.current?.getApi()?.view?.type ===
                      "timeGridDay"
                        ? days[new Date(e.date.marker).getDay()]
                        : width < 1030
                        ? daysMobile[new Date(e.date.marker).getDay()]
                        : days[new Date(e.date.marker).getDay()]}
                    </Typography>
                  </Stack>
                );
              }}
              validRange={
                calendarRef.current?.getApi()?.view?.type !== "dayGridMonth"
                  ? {
                      start: getMonday(parseISO(schedule?.issue_start)),
                      end: addDays(endOfWeek(parseISO(schedule?.issue_end)), 1),
                    }
                  : {}
              }
              allDaySlot={false}
              handleWindowResize={true}
              initialView={
                period === "month"
                  ? "dayGridMonth"
                  : period === "week"
                  ? "timeGridWeek"
                  : "timeGridDay"
              }
              initialDate={currentDate}
              defaultTimedEventDuration={eventDuration}
              editable={false}
              //slot settings
              slotDuration={eventDuration}
              slotMinTime={"07:00:00"}
              slotMaxTime={"20:00:00"}
              scrollTime={"09:00:00"}
              slotLabelInterval={width <= 880 && { days: 0 }}
              slotLabelFormat={{
                hour: "numeric",
                minute: "2-digit",
                omitZeroMinute: false,
                meridiem: "short",
              }}
              //select settings
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              selectOverlap={false}
              selectAllow={(e) => {
                var border = period === "month" ? 86400 : 900;
                if (
                  e.end.getTime() / 1000 - e.start.getTime() / 1000 <=
                  border
                ) {
                  return true;
                }
              }}
              weekends={showWeekends}
              events={dataEvents}
              //select={handleDateSelect}
              eventContent={renderEventContent} // custom render function
              eventClick={handleEventClick}
              //eventChange={handleEventChange}
              //eventsSet={handleEvents} // called after events are initialized/added/changed/removed
            />
          </div>
          {modalWindowConfirm}
          <Dialog
            open={openAlert}
            onClose={handleCloseAlert}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Выбранное время уже назначено вам"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Для изменения времени выдачи ключей вам необходимо выбрать
                другое время
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAlert} autoFocus>
                Хорошо
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Stack>
    </Box>
  );
}
