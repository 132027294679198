import "./Main.css";
import { DatabaseContext } from "../index";
import React, { useState, useEffect, useCallback, useContext } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ru } from "date-fns/locale/";
import isWeekend from "date-fns/isWeekend";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import Workspace from "./components/Workspace";
import { styled } from "@mui/material/styles";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import endOfWeek from "date-fns/endOfWeek";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";
import startOfWeek from "date-fns/startOfWeek";
import TextField from "@mui/material/TextField";
import { MonthPicker } from "@mui/x-date-pickers/MonthPicker";
import Modal from "@mui/material/Modal";
import CreateSchedule from "./components/Schedule/Create";
import EditSchedule from "./components/Schedule/Edit";
import AddRecord from "./components/Record/Add";
import PubAccess from "./components/Dialog/PubAccess";
import DeleteIcon from "@mui/icons-material/Delete";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import axios from "axios";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LinearProgress from "@mui/material/LinearProgress";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { NotificationContext } from "./components/context/NotificationContext";
import { useLoading } from "./components/context/LoadingContext";
import { DataContext } from "./components/context/DataContext";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const drawerW = 330;

const months = [
  "янв.",
  "фев.",
  "мар.",
  "апр.",
  "мая",
  "июн.",
  "июл.",
  "авг.",
  "сент.",
  "окт.",
  "нояб.",
  "дек.",
];

function Array(N) {
  var a = [];
  for (var i = 1; i < N; i++) {
    a.push(i);
  }
  return a;
}

const days = Array(32);

var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0");
var yyyy = today.getFullYear();

today = mm + "/" + dd + "/" + yyyy;

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

function App() {
  const { dbLink, dbGuid, apiPath } = useContext(DatabaseContext);
  const setNotification = useContext(NotificationContext);
  const { loading, setLoading } = useLoading();
  const {
    dataError,
    dataIsLoading,
    scheduleList,
    reload,
    clearAll,
    objsData,
    changeData,
    selectedSchedule,
    changeSchedule,
    scheduleDate,
    changeScheduleDate,
    selectedPeriod,
    setNewPeriod,
    showWeekends,
    changeShowWeekends,
  } = useContext(DataContext);
  //const [scheduleList, setSchedulesList] = useState([]);
  //const [reload, setReload] = useState(true);
  const [selectedScheduleId, setSelectedScheduleId] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(true);
  const [date, setDate] = useState({
    start: new Date(),
    end: new Date(),
    current: new Date(),
  });
  const [workspaceView, setWorkspaceView] = useState("calendar");
  const [highlightedMonths, setHighlightedMonths] = useState([]);
  const [highlightedDays, setHighlightesDays] = useState([]);

  const [openModalCreateSchedule, setOpenModalCreateSchedule] = useState(false);
  const [openModalEditSchedule, setOpenModalEditSchedule] = useState(false);
  const [openModalAddRecord, setOpenModalAddRecord] = useState(false);
  const [openModalPub, setOpenModalPub] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalArchive, setOpenModalArchive] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openBurger = Boolean(anchorEl);

  const handleBurgerClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleBurgerClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const [anchorElPub, setAnchorElPub] = useState(null);
  const openBurgerPub = Boolean(anchorElPub);

  const handleBurgerClickPub = (event) => {
    event.stopPropagation();
    setAnchorElPub(event.currentTarget);
  };
  const handleBurgerClosePub = (event) => {
    event.stopPropagation();
    setAnchorElPub(null);
  };

  const [openNotiDialog, setOpenNotiDialog] = useState(false);
  const [openNotiDialogOnPub, setOpenNotiDialogOnPub] = useState(false);

  const handleOpenModalDelete = () => setOpenModalDelete(true);
  const handleCloseModalDelete = () => setOpenModalDelete(false);

  const handleOpenModalArchive = () => setOpenModalArchive(true);
  const handleCloseModalArchive = () => setOpenModalArchive(false);

  const handleOpenModalCreateSchedule = () => setOpenModalCreateSchedule(true);
  const handleCloseModalCreateSchedule = () =>
    setOpenModalCreateSchedule(false);

  const handleOpenModalEditSchedule = () => setOpenModalEditSchedule(true);
  const handleCloseModalEditSchedule = () => setOpenModalEditSchedule(false);

  const handleOpenModalAddRecord = () => setOpenModalAddRecord(true);
  const handleCloseModalAddRecord = () => {
    setOpenModalAddRecord(false);
    reload();
  };

  const handleOpenModalPub = (e) => {
    e.stopPropagation();
    setOpenModalPub(true);
  };
  const handleCloseModalPub = (e) => {
    e.stopPropagation();
    setOpenModalPub(false);
  };

  const getWeekFromDate = (d) => {
    //console.log("D_: ", d);
    //console.log("firstday: ", startOfWeek(d, { weekStartsOn: 1 }));
    //console.log("lastday: ", endOfWeek(d, { weekStartsOn: 1 }));
    //d = new Date(d);
    /*
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6 : 1);
    var start = new Date(d.setDate(diff));
    var end = new Date(d.setDate(diff + 6));

    var t = new Date(d.setTime((diff + 6) * 86400000));
    console.log("D: ", d)
    */

    //return [start, end];
    return [
      startOfWeek(d, { weekStartsOn: 1 }),
      endOfWeek(d, { weekStartsOn: 1 }),
    ];
  };

  const getMonthFromDate = (d) => {
    var start = new Date(d.getFullYear(), d.getMonth(), 1);
    var end = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    return [start, end];
  };

  /*
  const fetchData = useCallback(async () => {
    //setLoading(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    //var dateFrom = date.start.toISOString().split("T")[0] + "T00:00:00";
    //var dateTo = date.end.toISOString().split("T")[0] + "T23:59:59";

    var url = `${dbLink}${apiPath}/keys_issue/schedule`;
    console.log("get data from ", url);

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Ошибка при загрузке данных. Код ошибки: ${response.status}`
          );
        }
        return response.json();
      })
      .then((result) => {
        console.log("RESULT: ", result);
        setSchedulesList(result);
        result.length > 0
          ? setAccordionExpanded(true)
          : setAccordionExpanded(false);
        setError(false);
        //setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (reload) {
      //fetchDataNew();
      fetchData();
    }
  }, [fetchData, selectedPeriod, reload]);
  */

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  useEffect(() => {
    handleDateChange(new Date()); // set init date
  }, []);

  const handleViewChange = () => {
    workspaceView === "list"
      ? setWorkspaceView("calendar")
      : setWorkspaceView("list");
  };

  const handleDateChange = (newDate) => {
    // меняем цвет выделеной даты
    /*
    var t = window.event.target;
    t.style["background-color"] = "red";
    */

    console.log("newDate: ", newDate);
    switch (selectedPeriod) {
      case "day":
        setDate({ start: newDate, end: newDate, current: newDate });
        changeScheduleDate(newDate.toLocaleDateString());
        break;
      case "week":
        setDate({
          start: getWeekFromDate(newDate)[0],
          end: getWeekFromDate(newDate)[1],
          current: newDate,
        });
        var d_week =
          getWeekFromDate(newDate)[0].toLocaleDateString() +
          " - " +
          getWeekFromDate(newDate)[1].toLocaleDateString();
        //console.log("d_week: ", d_week);
        changeScheduleDate(d_week);
        break;
      case "month":
        setDate({
          start: getMonthFromDate(newDate)[0],
          end: getMonthFromDate(newDate)[1],
          current: newDate,
        });
        var d_month =
          getMonthFromDate(newDate)[0].toLocaleDateString() +
          " - " +
          getMonthFromDate(newDate)[1].toLocaleDateString();
        changeScheduleDate(d_month);
        break;
      default:
        setError(true);
        break;
    }
  };

  /**
   * Функция загрузки данных по выбранной очереди выдачи
   * Данные сохраняются в DataContext
   * После загрузки, подсвечиваем загруженные даты
   * @param {*} id - id очереди
   */
  const loadDataForSchedule = (id) => {
    let d = new Date();
    var url = `${dbLink}${apiPath}/keys_issue/queue?schedule_id=${id}&timestamp=${d.toISOString()}`;
    setNotification("info", "Загрузка...");
    axios
      .get(url)
      .then((result) => {
        if (result.status === 200) {
          let d = result.data?.find((obj) => obj.schedule_id === id).data;
          console.log(`DATA FOR ${id} SCHEDULE:`, d);
          changeData(d); // устанавливаем данные по графику в контекст
          setNotification("success", "Данные для графика загружены");
          return d;
        } else {
          console.log("ERROR DURING DATA FETCHING");
          changeData([]);
          setNotification(
            "error",
            `Ошибка ${result.status} при загрузки данных для графика выдачи`
          );
          return [];
        }
      })
      .then((data) => {
        highlightDate(data); // подсвечиваем выбранные даты
      })
      .catch((err) => {
        console.log("ERROR DURING DATA FETCHING: ", err);
        setNotification(
          "error",
          "Ошибка при загрузки данных для графика выдачи"
        );
      });
  };

  const handleQueueCheck = (e) => {
    e.stopPropagation();
    clearAll();

    var id = e.currentTarget.getAttribute("id");
    console.log("SELECTED SCHEDULE: ", id);

    setSelectedScheduleId(id);

    let sch = scheduleList.find((obj) => obj.schedule_id === id);

    handleDateChange(new Date(Date.parse(sch.issue_start + ".000Z")));

    changeSchedule(sch); // устанавливаем объект графика в контекст
    loadDataForSchedule(id);
  };

  useEffect(() => {
    selectedPeriod === "month"
      ? changeMonthElBorder(highlightedMonths, "PrivatePickersMonth-root")
      : changeMonthElBorder(highlightedDays, "MuiPickersDay-root");
  }, [selectedPeriod]);

  const renderWeekPickerDay = (d, selectedDates, pickersDayProps) => {
    if (!date.current) {
      return <PickersDay {...pickersDayProps} />;
    }

    if (selectedPeriod !== "week") {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = startOfWeek(date.current, { weekStartsOn: 1 });
    const end = endOfWeek(date.current, { weekStartsOn: 1 });

    const dayIsBetween = isWithinInterval(d, { start, end });
    const isFirstDay = isSameDay(d, start);
    const isLastDay = isSameDay(d, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  const todayClick = () => {
    var today = new Date();
    console.log(today);
    console.log(date);
    //setDate({...date, current: today});
    handleDateChange(today);
  };

  const deleteSchedule = () => {
    let d = new Date();
    axios
      .delete(
        `${dbLink}${apiPath}/keys_issue/schedule?id=${selectedScheduleId}&timestamp=${d.toISOString()}`
      )
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          setNotification("success", "График выдачи ключей успешно удален");
          reload();
        }
      })
      .catch((err) => {
        setNotification("error", "Ошибка при удалении графика выдачи ключей");
        console.log(err);
      });
  };

  /**
   * Перенос выбранного опубликованного графика в архив
   * После - обновление данных
   */
  const archiveSchedule = () => {
    let url = `${dbLink}${apiPath}/keys_issue/archive`;
    let data = { schedule_id: selectedScheduleId };

    axios
      .post(url, data)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setNotification("success", "График успешно перенесен в архив");
          reload();
        } else {
          setNotification(
            "error",
            "Произошла ошибка при переносе графика в архив"
          );
          reload();
        }
      })
      .catch((err) => {
        setNotification(
          "error",
          "Произошла ошибка при переносе графика в архив"
        );
        console.log(err);
      });
  };

  const handleDeleteScheduleAgreee = () => {
    deleteSchedule();
    handleCloseModalDelete();
    clearAll();
    setAnchorEl(null);
  };

  const handleArchiveScheduleAgreee = () => {
    archiveSchedule();
    handleCloseModalArchive();
    setAnchorEl(null);
  };

  const iconDeleteClick = (e, obj) => {
    e.stopPropagation();
    console.log("Delete schedule id ", obj.schedule_id);
    setSelectedScheduleId(obj.schedule_id);
    handleBurgerClose(e);
    handleOpenModalDelete();
  };

  const iconEditClick = (e) => {
    e.stopPropagation();
    handleBurgerClose(e);
    handleOpenModalEditSchedule();
    console.log("DATA: ", scheduleList);
  };

  const iconPublicClick = (e) => {
    e.stopPropagation();
    handleBurgerClose(e);
    handleOpenModalPub(e);
  };

  const iconArchiveClick = (e, obj) => {
    e.stopPropagation();
    console.log("Archive schedule id ", obj.schedule_id);
    setSelectedScheduleId(obj.schedule_id);
    handleOpenModalArchive();
  };

  /**
   * Публикация выбранного графика выдачи ключей
   * Происходит в два этапа
   * 1. перевод графика в статус "подтвержден"
   * 2. публикация графика
   * 3. (в случае успеха) вызов метода обновления данных
   */
  const publishSchedule = () => {
    var url_approve = `${dbLink}${apiPath}/keys_issue/approve`;
    var url_publish = `${dbLink}${apiPath}/keys_issue/publish`;
    var params = {
      schedule_id: selectedScheduleId,
    };

    axios
      .post(url_approve, params)
      .then((res) => {
        console.log(res);
        setNotification("success", "График успешно подтвержден");
        axios
          .post(url_publish, params)
          .then((res) => {
            console.log(res);
            setNotification("success", "График успешно опубликован");
            reload();
          })
          .catch((err) => {
            console.error(err);
            setNotification(
              "error",
              err.response.data.err_msg
                ? err.response.data.err_msg
                : "Ошибка при публикации графика"
            );
          });
      })
      .catch((err) => {
        console.error(err);
        setNotification(
          "error",
          err.response.data.err_msg
            ? err.response.data.err_msg
            : "Ошибка при подтверждении графика"
        );
      });
  };

  const iconNotiClick = (e, obj) => {
    e.stopPropagation();
    setOpenNotiDialog(true);
  };

  const handleSendNotifications = (e) => {
    e.stopPropagation();
    sendNotificationsToClients();
    handleNotiDialogClose(e);
  };

  const handleNotiDialogClose = (e) => {
    setOpenNotiDialog(false);
    handleBurgerClosePub(e);
  };

  /**
   * Рассылаем уведомления о выдаче ключей клиентам
   */
  const sendNotificationsToClients = () => {
    let url = `${dbLink}${apiPath}/keys_issue/notify_clients`;

    let payload = {
      schedule_id: selectedSchedule.schedule_id,
    };

    axios
      .post(url, payload)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setNotification("success", "Уведомления разосланы");
        }
      })
      .catch((err) => {
        console.log(err);
        setNotification("error", "Ошибка при рассылке уведомлений");
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 2,
    overflowY: "auto",
    maxHeight: "90vh",
  };

  const modalWindowCreateSchedule = (
    <Modal
      open={openModalCreateSchedule}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <CreateSchedule
          onClose={handleCloseModalCreateSchedule}
          onCreate={reload}
        />
      </Box>
    </Modal>
  );

  const modalWindowEditSchedule = (
    <Modal
      open={openModalEditSchedule}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <EditSchedule onClose={handleCloseModalEditSchedule} />
      </Box>
    </Modal>
  );

  const modalWindowAddRecord = (
    <Modal
      open={openModalAddRecord}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <AddRecord
          onClose={handleCloseModalAddRecord}
          scheduleObj={selectedSchedule}
        />
      </Box>
    </Modal>
  );

  const modalWindowPub = (
    <Modal
      open={openModalPub}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <PubAccess
          onClose={(e) => handleCloseModalPub(e)}
          onOk={(e) => {
            publishSchedule();
            setOpenModalPub(false);
          }}
          sendNoti={async () => {
            await timeout(3000);
            sendNotificationsToClients();
          }}
        />
      </Box>
    </Modal>
  );

  const sendNoti = () => {
    console.log("NOTIFICATION");
  };

  const modalWindowDeleteConfirm = (
    <Modal
      open={openModalDelete}
      aria-labelledby="modal-delete-title"
      aria-describedby="modal-delete-description"
    >
      <Box sx={style}>
        <IconButton
          sx={{ position: "absolute", right: 2, top: 2 }}
          aria-label="close"
          onClick={handleCloseModalDelete}
        >
          <CloseIcon />
        </IconButton>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Удалить график?</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Вся заполненная информация будет безвозвратно удалена.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
              <Button variant="outlined" onClick={handleCloseModalDelete}>
                Отмена
              </Button>
              <Button variant="contained" onClick={handleDeleteScheduleAgreee}>
                Удалить
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );

  const modalWindowArchiveConfirm = (
    <Modal
      open={openModalArchive}
      aria-labelledby="modal-archive-title"
      aria-describedby="modal-archive-description"
    >
      <Box sx={style}>
        <IconButton
          sx={{ position: "absolute", right: 2, top: 2 }}
          aria-label="close"
          onClick={handleCloseModalArchive}
        >
          <CloseIcon />
        </IconButton>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Перенести график в архив?</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Вся заполненная информация станет недоступной.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
              <Button variant="outlined" onClick={handleCloseModalArchive}>
                Отмена
              </Button>
              <Button variant="contained" onClick={handleArchiveScheduleAgreee}>
                Перенести
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );

  const loader = (
    <Box
      sx={{
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        display: "flex",
        zIndex: (theme) => theme.zIndex.drawer + 2,
        bgcolor: "rgba(0, 0, 0, 0.6)",
        textAlign: "center",
      }}
    >
      <CircularProgress sx={{ margin: "45vh auto" }} size={60} thickness={4} />
    </Box>
  );

  return (
    <>
      {/*loading && loader*/}
      <Box sx={{ display: "flex", bgcolor: "background.default" }}>
        <AppBar
          position="fixed"
          elevation={1}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: "white" }}
        >
          <Toolbar>
            <Stack spacing={2} direction="row">
              <Button
                disabled={dataIsLoading || dataError}
                variant="contained"
                endIcon={<ControlPointOutlinedIcon />}
                onClick={() => handleOpenModalCreateSchedule()}
              >
                Новый график
              </Button>
              {modalWindowCreateSchedule}
              <Button
                variant="text"
                onClick={() => setOpen(!open)}
                startIcon={
                  open ? <ArrowBackIosNewIcon /> : <ArrowForwardIosIcon />
                }
              >
                {open ? "Свернуть настройки" : "Показать настройки"}
              </Button>
              <Button
                /*
                disabled={
                  selectedSchedule?.status === "Черновик" ? false : true
                }*/
                variant="text"
                startIcon={<GroupAddOutlinedIcon />}
                onClick={() => handleOpenModalAddRecord()}
              >
                Записать вручную
              </Button>
              {modalWindowAddRecord}
              <Button
                variant="text"
                startIcon={<CalendarTodayOutlinedIcon />}
                onClick={() => handleViewChange()}
              >
                Вид: {workspaceView === "list" ? "календарь" : "список"}
              </Button>
              <Button
                sx={{ display: "none" }}
                variant="text"
                startIcon={<CalendarMonthOutlinedIcon />}
              >
                Календарь событий
              </Button>
            </Stack>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <Drawer
          variant="persistent"
          open={open}
          sx={{
            width: drawerW,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerW,
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />
          <Box
            sx={{
              width: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              scrollbarWidth: "thin",
              "::-webkit-scrollbar": {
                backgroundColor: "#fff",
                width: "16px",
              },
              "::-webkit-scrollbar-track": {
                backgroundColor: "#fff",
              },
              "::-webkit-scrollbar-track:hover": {
                backgroundColor: "#f4f4f4",
              },
              "::-webkit-scrollbar-thumb": {
                backgroundColor: "#babac0",
                borderRadius: "16px",
                border: "5px solid #fff",
              },
              "::-webkit-scrollbar-button": { display: "none" },
            }}
          >
            <div className="calendar">
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
                {selectedPeriod !== "month" ? (
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    label="date picker"
                    shouldDisableDate={showWeekends ? "" : isWeekend}
                    value={date.current}
                    onChange={(newValue) => {
                      handleDateChange(newValue);
                    }}
                    renderDay={renderWeekPickerDay}
                    renderInput={(params) => <TextField {...params} />}
                    inputFormat="'Week of' MMM d"
                    minDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 3)
                      )
                    }
                    maxDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() + 1)
                      )
                    }
                    showDaysOutsideCurrentMonth={true}
                  />
                ) : (
                  <MonthPicker
                    date={date.current}
                    disableHighlightToday={false}
                    minDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 3)
                      )
                    }
                    maxDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() + 1)
                      )
                    }
                    onChange={(newValue) => {
                      handleDateChange(newValue);
                    }}
                  />
                )}
              </LocalizationProvider>
            </div>
            <div className="today-container">
              <div className="today-text" onClick={() => todayClick()}>
                <Typography variant="body1">Сегодня {today}</Typography>
              </div>
            </div>

            <div className="period-control">
              <Stack spacing={2} direction="row" className="period-btns">
                <Button
                  onClick={() => setNewPeriod("day")}
                  variant={selectedPeriod === "day" ? "contained" : "outlined"}
                >
                  День
                </Button>
                <Button
                  onClick={() => setNewPeriod("week")}
                  variant={selectedPeriod === "week" ? "contained" : "outlined"}
                >
                  Неделя
                </Button>
                <Button
                  onClick={() => setNewPeriod("month")}
                  variant={
                    selectedPeriod === "month" ? "contained" : "outlined"
                  }
                >
                  Месяц
                </Button>
              </Stack>

              <div className="period-check">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showWeekends}
                      onClick={() => changeShowWeekends(!showWeekends)}
                    />
                  }
                  label="Показывать выходные"
                />
              </div>
            </div>

            <Accordion
              disableGutters={true}
              expanded={accordionExpanded}
              onClick={() => setAccordionExpanded(!accordionExpanded)}
              //sx={{ overflowY: "auto" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>График выдачи ключей</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography align="left" color="gray" variant="body1">
                  Черновики:
                </Typography>
                <RadioGroup>
                  {scheduleList.length > 0 ? (
                    scheduleList.map((obj, index) => {
                      return obj.status === "Черновик" ? (
                        <FormControlLabel
                          control={
                            <Radio
                              key={"icon" + index}
                              checked={
                                selectedScheduleId === obj.schedule_id
                                  ? true
                                  : false
                              }
                            />
                          }
                          index={index}
                          id={obj.schedule_id}
                          key={obj.schedule_id}
                          label={
                            <div className="check-label">
                              <Typography variant="body2">
                                {`${obj.district} (${
                                  obj.issue_start.split("T")[0].split("-")[2] +
                                  "." +
                                  obj.issue_start.split("T")[0].split("-")[1]
                                } - ${
                                  obj.issue_end.split("T")[0].split("-")[2] +
                                  "." +
                                  obj.issue_end.split("T")[0].split("-")[1]
                                })`}
                              </Typography>
                              <div className="check-icons">
                                <IconButton
                                  disabled={
                                    selectedScheduleId === obj.schedule_id
                                      ? false
                                      : true
                                  }
                                  size="small"
                                  aria-label={"burger" + index}
                                  key={"burger" + index}
                                  onClick={handleBurgerClick}
                                  sx={
                                    accordionExpanded
                                      ? { display: "auto" }
                                      : { display: "none" }
                                  }
                                >
                                  <MenuIcon
                                    sx={{ "&:hover": { color: "#127CCA" } }}
                                  />
                                  <Menu
                                    id={"menu" + obj.schedule_id}
                                    anchorEl={anchorEl}
                                    open={openBurger}
                                    onClose={handleBurgerClose}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    MenuListProps={{
                                      "aria-labelledby":
                                        "basic-button" + obj.schedule_id,
                                    }}
                                  >
                                    <MenuItem
                                      onClick={(e) => iconDeleteClick(e, obj)}
                                    >
                                      <DeleteIcon
                                        sx={{ "&:hover": { color: "#127CCA" } }}
                                      />
                                    </MenuItem>
                                    <MenuItem
                                      onClick={(e) =>
                                        iconEditClick(e)
                                      } /*sx={{display: "none"}}*/
                                    >
                                      <ModeEditOutlineOutlinedIcon
                                        sx={{ "&:hover": { color: "#127CCA" } }}
                                      />
                                    </MenuItem>
                                    <MenuItem
                                      onClick={(e) => iconPublicClick(e)}
                                    >
                                      <InventoryOutlinedIcon
                                        sx={{ "&:hover": { color: "#127CCA" } }}
                                      />
                                    </MenuItem>
                                  </Menu>
                                </IconButton>
                              </div>
                            </div>
                          }
                          onClick={(e) => handleQueueCheck(e)}
                        />
                      ) : (
                        <></>
                      );
                    })
                  ) : (
                    <Box sx={{ width: "100%", mt: "4px", mb: "4px" }}>
                      <LinearProgress />
                    </Box>
                  )}
                </RadioGroup>
                <Typography align="left" color="gray" variant="body1">
                  Опубликованы:
                </Typography>
                <RadioGroup>
                  {scheduleList.length > 0 ? (
                    scheduleList.map((obj, index) => {
                      return obj.status === "Опубликован" ? (
                        <FormControlLabel
                          control={
                            <Radio
                              key={"icon" + index}
                              checked={
                                selectedScheduleId === obj.schedule_id
                                  ? true
                                  : false
                              }
                            />
                          }
                          index={index}
                          id={obj.schedule_id}
                          key={obj.schedule_id}
                          label={
                            <div className="check-label">
                              <Typography variant="body2">
                                {`${obj.district} (${
                                  obj.issue_start.split("T")[0].split("-")[2] +
                                  "." +
                                  obj.issue_start.split("T")[0].split("-")[1]
                                } - ${
                                  obj.issue_end.split("T")[0].split("-")[2] +
                                  "." +
                                  obj.issue_end.split("T")[0].split("-")[1]
                                })`}
                              </Typography>
                              <div className="check-icons">
                                {obj.notification_is_available === "true" ? (
                                  <IconButton
                                    disabled={
                                      selectedScheduleId === obj.schedule_id
                                        ? false
                                        : true
                                    }
                                    size="small"
                                    aria-label={"burger" + index}
                                    key={"burger" + index}
                                    onClick={handleBurgerClickPub}
                                    sx={
                                      accordionExpanded
                                        ? { display: "auto" }
                                        : { display: "none" }
                                    }
                                  >
                                    <MenuIcon
                                      sx={{ "&:hover": { color: "#127CCA" } }}
                                    />
                                    <Menu
                                      id={"menu" + obj.schedule_id}
                                      anchorEl={anchorElPub}
                                      open={openBurgerPub}
                                      onClose={handleBurgerClosePub}
                                      anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                      MenuListProps={{
                                        "aria-labelledby":
                                          "basic-button" + obj.schedule_id,
                                      }}
                                    >
                                      <MenuItem
                                        onClick={(e) =>
                                          iconArchiveClick(e, obj)
                                        }
                                      >
                                        <Inventory2OutlinedIcon
                                          sx={{
                                            "&:hover": { color: "#127CCA" },
                                          }}
                                        />
                                      </MenuItem>
                                      <MenuItem
                                        onClick={(e) => iconNotiClick(e, obj)}
                                      >
                                        <NotificationsIcon
                                          sx={{
                                            "&:hover": { color: "#127CCA" },
                                          }}
                                        />
                                        <Dialog
                                          open={openNotiDialog}
                                          onClose={handleNotiDialogClose}
                                          aria-labelledby="alert-dialog-title"
                                          aria-describedby="alert-dialog-description"
                                        >
                                          <DialogTitle id="alert-dialog-title">
                                            {"Разослать оповещения"}
                                          </DialogTitle>
                                          <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                              При согласии будут разосланы
                                              оповещения клиентам, включенным в
                                              данную очередь на выдачу
                                            </DialogContentText>
                                          </DialogContent>
                                          <DialogActions>
                                            <Button
                                              onClick={handleNotiDialogClose}
                                            >
                                              Отмена
                                            </Button>
                                            <Button
                                              onClick={handleSendNotifications}
                                              autoFocus
                                            >
                                              Отправить
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </MenuItem>
                                    </Menu>
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    disabled={
                                      selectedScheduleId === obj.schedule_id
                                        ? false
                                        : true
                                    }
                                    size="small"
                                    aria-label={"burger" + index}
                                    key={"burger" + index}
                                    onClick={(e) => iconArchiveClick(e, obj)}
                                    sx={
                                      accordionExpanded
                                        ? { display: "auto" }
                                        : { display: "none" }
                                    }
                                  >
                                    <Inventory2OutlinedIcon
                                      sx={{ "&:hover": { color: "#127CCA" } }}
                                    />
                                  </IconButton>
                                )}
                              </div>
                            </div>
                          }
                          onClick={(e) => handleQueueCheck(e)}
                        />
                      ) : (
                        <></>
                      );
                    })
                  ) : (
                    <Box sx={{ width: "100%", mt: "4px", mb: "4px" }}>
                      <LinearProgress />
                    </Box>
                  )}
                </RadioGroup>
              </AccordionDetails>
            </Accordion>
            {modalWindowPub}
            {modalWindowDeleteConfirm}
            {modalWindowEditSchedule}
            {modalWindowArchiveConfirm}
          </Box>
        </Drawer>
        <Workspace
          open={open}
          weekend={showWeekends}
          date={
            selectedPeriod === "day"
              ? date.current.toLocaleDateString()
              : date.start.toLocaleDateString() +
                " - " +
                date.end.toLocaleDateString()
          }
          headerDateLabel={
            selectedPeriod === "day"
              ? date.current.toLocaleDateString().slice(0, -5)
              : date.start.toLocaleDateString().slice(0, -5) +
                " - " +
                date.end.toLocaleDateString().slice(0, -5)
          }
          period={selectedPeriod}
          view={workspaceView}
          refetch={reload}
        />
      </Box>
    </>
  );

  function highlightDate(data) {
    if (data.length > 0) {
      console.log("HIGHLIGHT DATE");
      let firstEl = data[0];
      let lastEl = data[data.length - 1];

      //console.log(firstEl, lastEl); // .issuing_time - "2022-09-20T12:00:00"
      //console.log(period);

      // создаем массив месяцев для заполнения
      let startMonthNumber = Number(
        firstEl.issuing_time.split("T")[0].split("-")[1]
      );
      let endMonthNumber = Number(
        lastEl.issuing_time.split("T")[0].split("-")[1]
      );

      var highlightedMonthArr = months.slice(
        startMonthNumber - 1,
        endMonthNumber
      );

      //let startDay = Number(firstEl.issuing_time.split("T")[0].split("-")[2]);
      //let endDay = Number(lastEl.issuing_time.split("T")[0].split("-")[2]);

      var daysArr = []; // массив с днями, где есть записи

      // aria-label="28 окт. 2022 г."
      data.map((el) => {
        daysArr.indexOf(el.issuing_time.split("T")[0]) === -1 &&
          daysArr.push(
            el.issuing_time.split("T")[0].split("-")[2] +
              " " +
              months[Number(el.issuing_time.split("T")[0].split("-")[1]) - 1] +
              " " +
              el.issuing_time.split("T")[0].split("-")[0] +
              " г."
          );
      });

      setHighlightesDays(daysArr);
      setHighlightedMonths(highlightedMonthArr);

      switch (selectedPeriod) {
        case "month":
          // находим элементы, ототбражающие месяцы в календаре и меняем цвет
          changeMonthElBorder(highlightedMonthArr, "PrivatePickersMonth-root");
          break;
        default:
          //MuiPickersDay-root
          changeMonthElBorder(daysArr, "MuiPickersDay-root");
          break;
      }
    } else {
      // сбрасываем стили для границ
      clearMonthElBorder("PrivatePickersMonth-root");
      clearMonthElBorder("MuiPickersDay-root");
    }
  }

  function clearMonthElBorder(elToFind) {
    var elems = document.getElementsByClassName(elToFind);
    for (var i = 0; i < elems.length; i++) {
      elems[i].style["border"] = "none";
    }
  }

  function changeMonthElBorder(dateArr, elToFind) {
    var elems = document.getElementsByClassName(elToFind);
    for (var i = 0; i < elems.length; i++) {
      elems[i].style["border"] = "none";
    }
    dateArr.forEach((m) => {
      for (var i = 0; i < elems.length; i++) {
        if (elems[i].textContent === m || elems[i].ariaLabel === m) {
          //console.log("FIND EL", elems[i]);
          elems[i].style["border"] = "1px solid #127CCA";
        }
      }
    });
  }
}

export default App;
