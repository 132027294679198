import React, { useEffect } from "react";
import Main from "./pages/Main";
import { LoadingProvider } from "./pages/components/context/LoadingContext";
import { DataContextProvider } from "./pages/components/context/DataContext";

export default function App() {
  return (
    <LoadingProvider>
      <DataContextProvider>
        <Main />
      </DataContextProvider>
    </LoadingProvider>
  );
}
