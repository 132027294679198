import React from "react";
import { useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import Events from "./components/events";
import ActPage from "./components/act";

export default function Act() {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  return (
    <Box sx={{ height: "100vh" }}>
      <Stack spacing={0} direction="row">
        <Box
          sx={{
            height: "100vh",
            width: "55%",
            overflowY: "auto",
            scrollbarWidth: "thin",
          }}
        >
          <ActPage />
        </Box>
        <Box
          sx={{
            height: "100vh",
            width: "45%",
            backgroundColor: "#F8F8F9",
            padding: "22px",
            overflowY: "auto",
            scrollbarWidth: "thin",
          }}
        >
          <Events />
        </Box>
      </Stack>
    </Box>
  );
}
