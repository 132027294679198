import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const NotificationContext = React.createContext();
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NotificationContextProvider = ({ children }) => {
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationText, setNotifiactionText] = useState("");
  const [notificationType, setNotifiactionType] = useState("error");

  /**
   * Вызов уведомления для пользователя
   * @param {*} type - тип уведомления - "error"|"warning"|"info"|"success"
   * @param {*} text - текст уведомления
   */
  const setNotification = (type, text) => {
    setNotifiactionType(type);
    setNotifiactionText(text);
    setOpenNotification(true);
  }

  const handleNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotification(false);
  };

  return (
    <NotificationContext.Provider
      value={setNotification}
    >
      {children}
      <Snackbar
        open={openNotification}
        autoHideDuration={3000}
        onClose={handleNotificationClose}
      >
        <Alert
          onClose={handleNotificationClose}
          severity={notificationType}
          sx={{ width: "100%", color: "white" }}
        >
          {notificationText}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationContextProvider };
