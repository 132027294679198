import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./index.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ru } from "date-fns/locale/";
import { parseISO, addMinutes, format } from "date-fns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import AddSection from "./AddSection";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { DatabaseContext } from "../../../../index";
import { NotificationContext } from "../../context/NotificationContext";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { changeSymbols, separateBy } from "../../utils/utils";

export const dinnerTime = [
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
];

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
  },
});

function createNewSchedule(
  author_id,
  alternative_issue_start,
  alternative_issue_end,
  issue_start_time,
  issue_end_time,
  time_on_issue,
  issue_on_saturday,
  issue_on_sunday,
  issue_on_holidays,
  have_dinner,
  dinner_start_time,
  dinner_end_time,
  flat_per_specialist,
  number_of_specialists,
  comment,
  issue_start,
  issue_end,
  organisation_id,
  responsible_id,
  unit_id,
  district_id,
  sections,
  fill_schedule
) {
  return {
    author_id,
    alternative_issue_start,
    alternative_issue_end,
    issue_start_time,
    issue_end_time,
    time_on_issue,
    issue_on_saturday,
    issue_on_sunday,
    issue_on_holidays,
    have_dinner,
    dinner_start_time,
    dinner_end_time,
    flat_per_specialist,
    number_of_specialists,
    comment,
    issue_start,
    issue_end,
    organisation_id,
    responsible_id,
    unit_id,
    district_id,
    sections,
    fill_schedule,
  };
}



export default function CreateSchedule({ onClose, onCreate, ...rest }) {
  const { dbLink, dbGuid, apiPath } = useContext(DatabaseContext);
  const { height, width } = useWindowDimensions();
  const setNotification = useContext(NotificationContext);

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const [object, setObject] = useState("");
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateAlt, setStartDateAlt] = useState("");
  const [endDateAlt, setEndDateAlt] = useState("");
  const [sectionList, setSectionList] = useState([]);
  const [modalAddSection, setModalAddSection] = useState(false);
  const [specialistNumber, setSpecialistNumber] = useState(1);
  const [flatPerSpec, setFlatPerSpec] = useState(1);
  const [timePerFlat, setTimePerFlat] = useState(30);
  const [issueOnWeekend, setIssueOnWeekend] = useState(false);
  const [issueOnSunday, setIssueOnSunday] = useState(false);
  const [issueOnSaturday, setIssueOnSaturday] = useState(false);
  const [issueOnHolidays, setIssueOnHolidays] = useState(false);
  const [withDiner, setWithDiner] = useState(false);
  const [issueStart, setIssueStart] = useState("08:00");
  const [issueEnd, setIssueEnd] = useState("20:00");
  const [comment, setComment] = useState("");
  const [showAltDate, setShowAltDate] = useState(false);
  const [fillingMethod, setFillingMethod] = useState("auto");
  const [dinnerStart, setDinnerStart] = useState("13:00");
  const [dinnerEnd, setDinnerEnd] = useState("14:00");

  const [availableTime, setAvailableTime] = useState([]);
  const [availableEndTime, setAvailableEndTime] = useState([]);

  const [openNotification, setOpenNotification] = useState(false);

  const handleNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotification(false);
  };

  useEffect(() => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        auth_test: `${localStorage.token}`,
      },
    };
    //alert("GET auth/user/");
    //alert("Headers: " + JSON.stringify(config))
    axios
      .post(`${dbLink}${apiPath}/auth/user/`, localStorage.token)
      .then((response) => {
        console.log("GETTIN RESPONSE");
        console.log("response success: ", response.data.success);
        console.log("response data: ", response.data.data);
        console.log("response body: ", response);
        //alert(JSON.stringify(response.data.data));
        if (response.data.success) setUser(response.data.data);
      })
      .catch((err) => {
        console.error(err);
        alert(err);
      });

    axios
      .get(`${dbLink}${apiPath}/keys_issue/get_diner_time`)
      .then((res) => {
        console.log("DINNER: ", res.data);
        setDinnerStart(res.data?.dinner_start_time.split("T")[1].slice(0, -3));
        setDinnerEnd(res.data?.dinner_end_time.split("T")[1].slice(0, -3));
      })
      .catch((err) => {
        console.log(err);
        setNotification(
          "error",
          "Ошибка при получении стандартного времени обеда"
        );
      });
  }, []);

  useEffect(() => {
    let d = new Date();
    axios
      .get(
        `${dbLink}${apiPath}/district?commissioned=true&timestamp=${d.toISOString()}`
      )
      .then((res) => {
        const districts = res.data.data;
        console.log(districts);
        setDistrictList(districts);
      });
  }, []);

  useEffect(() => {
    createTimeList("08:00", "20:00", timePerFlat);
  }, [timePerFlat]);

  const createTimeList = (startTime, endTime, step) => {
    // 07:00
    let endH = parseInt(endTime.split(":")[0], 10);
    let startH = parseInt(startTime.split(":")[0], 10);
    let stepParsed = parseInt(step, 10);

    let diffH = endH - startH;
    let totalMins = diffH * 60;
    let stepMins = totalMins / stepParsed;
    let arr = [startTime];
    let time = 0;
    for (let i = 0; i < Math.round(stepMins); i++) {
      time += stepParsed;
      let hh = startH + Math.floor(time / 60);
      let mm = time - Math.floor(time / 60) * 60;
      arr.push(`${hh < 10 ? "0" + hh : hh}:${mm < 10 ? "0" + mm : mm}`);
    }
    console.log(arr);
    setAvailableTime(arr);
    setAvailableEndTime(arr);
  };

  const handleFillingMethod = (e) => {
    console.log(e.target.value);
    setFillingMethod(e.target.value);
  };

  const handleDistrictChange = (event) => {
    setSectionList([]);
    setDistrict(event.target.value);
  };

  const handleIssueStartChange = (event) => {
    let index = availableTime.indexOf(event.target.value);
    let tempArr = availableTime.slice(index, availableTime.length);
    setIssueStart(event.target.value);
    setAvailableEndTime(tempArr);
  };

  const handleIssueEndChange = (event) => {
    setIssueEnd(event.target.value);
  };

  const handleDinnerStartChange = (event) => {
    console.log(event.target.value);
    setDinnerStart(event.target.value);
  };

  const handleDinnerEndChange = (event) => {
    console.log(event.target.value);
    setDinnerEnd(event.target.value);
  };

  const handleTimePerFlatChange = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    setIssueStart(availableTime[0]);
    setIssueEnd(availableEndTime[availableEndTime.length - 1]);
    setTimePerFlat(onlyNums);
  };

  const handleSpecialistNumberChange = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    setSpecialistNumber(onlyNums);
  };

  const handleFlatPerSpecChange = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    setFlatPerSpec(onlyNums);
  };

  const handleStartDateChangeAlt = (newValue) => {
    setStartDateAlt(newValue);
  };

  const handleEndDateChangeAlt = (newValue) => {
    setEndDateAlt(newValue);
  };

  const handleStartDateChange = (newValue) => {
    console.log("New date: ", newValue.toISOString());
    setStartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  const handleChangeObject = (event) => {
    setObject(event.target.value);
  };

  const handleModalAddSection = () => {
    setModalAddSection(true);
  };

  const handleAddSection = (section) => {
    var tempArr = sectionList;
    const i = tempArr.findIndex((e) => e.id === section.id);
    if (i === -1) {
      tempArr.push(section);
      setObject(tempArr);
    } else {
      setNotification("error", "Секция уже добавлена!");
    }
  };

  const handleDeleteSection = (id) => {
    var tempArr = [...sectionList];
    console.log("OBJ LIST:", tempArr);
    var index = tempArr.findIndex(function (s) {
      return s.id === id;
    });
    if (index !== -1) tempArr.splice(index, 1);
    setSectionList(tempArr);
    console.log(id);
  };

  const handleClear = () => {
    setDistrict(null);
    setIssueOnWeekend(false);
    setIssueOnHolidays(false);
    setSpecialistNumber(1);
    setFlatPerSpec(1);
    setTimePerFlat(30);
    setIssueStart("08:00");
    setIssueEnd("20:00");
    setShowAltDate(false);
    setObject("");
    setStartDate("");
    setStartDateAlt("");
    setEndDate("");
    setEndDateAlt("");
    setSectionList([]);
    setNotification("success", "Данные удалены");
  };

  const dateFormat = (date, end = false) => {
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();

    return (
      y +
      "-" +
      (m < 10 ? "0" + m : m) +
      "-" +
      (d < 10 ? "0" + d : d) +
      (end ? "T23:59:59" : "T00:00:00")
    );
  };

  const handleCreateSchedule = () => {
    setIsLoading(true);
    var sectionListToCreate = [];

    if (endDate < startDate) {
      setIsLoading(false);
      return setNotification("error", "Неверно указана дата");
    }

    sectionList.forEach((s) => {
      sectionListToCreate.push({
        section_id: s.id,
        adress: s.district + " " + s.building + " " + s.name,
      });
    });

    var newSchedule;
    console.log("first ", dinnerEnd);
    let d = parseISO("2022-12-12T" + dinnerEnd + ":00");
    console.log("D: ", d);
    let dinner_end = format(addMinutes(d, -1), "yyyy-MM-dd'T'HH:mm:ss");
    console.log("Dinner end: ", dinner_end);
    try {
      newSchedule = createNewSchedule(
        user.user_id,
        showAltDate ? dateFormat(startDateAlt) : "0001-01-01T00:00:00",
        showAltDate ? dateFormat(endDateAlt, true) : "0001-01-01T00:00:00",
        issueStart.replace(":", "") + "00",
        issueEnd.replace(":", "") + "00",
        timePerFlat,
        issueOnSaturday,
        issueOnSunday,
        issueOnHolidays,
        withDiner,
        dinnerStart.replace(":", "") + "00",
        //dinnerEnd.replace(":", "") + "00",
        changeSymbols(dinner_end.split("T")[1], ":", ""),
        flatPerSpec,
        specialistNumber,
        comment,
        dateFormat(startDate),
        dateFormat(endDate, true),
        user.organisation_id,
        user.user_id,
        user.unit_id,
        district,
        sectionListToCreate,
        fillingMethod === "auto"
      );
    } catch (err) {
      setIsLoading(false);
      alert(dinner_end); alert(err);
      return setNotification("error", "Ошибка в заданных данных");
    }

    console.log("NEW SCHEDULE: ", newSchedule);

    //"2022-07-01T00:00:00"
    //console.log("START DATE: ", dateFormat(startDate));

    //console.log("SECTION LIST: ", sectionListToCreate);

    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    let url = `${dbLink}${apiPath}/keys_issue/create_schedule`;

    //alert("Отправляем POST-запрос на " + url);

    axios
      .post(url, newSchedule, config)
      .then((response) => {
        console.log(response);
        const { data, ...res } = response;
        //alert(JSON.stringify(data));
        //alert(JSON.stringify(res));
        if (response.data.success) {
          setIsLoading(false);
          setNotification("success", "График выдачи ключей успешно создан");
          onCreate();
          onClose();
        } else {
          alert("Ошибка при обработке запроса");
          onCreate();
          onClose();
        }
      })
      .catch((err) => {
        alert(err);
        console.log(err);
        setNotification("error", "Ошибка при создании графика выдачи ключей");
        setIsLoading(false);
      });
  };

  const modalWindowAddSection = (
    <Modal
      open={modalAddSection}
      aria-labelledby="modal-add-section-title"
      aria-describedby="modal-add-section-description"
    >
      <AddSection
        close={() => setModalAddSection(false)}
        add={handleAddSection}
        district={district}
      />
    </Modal>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IconButton
        sx={{ position: "absolute", right: 2, top: 2 }}
        aria-label="close"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
        <Grid item xs={11}>
          <Typography variant="h5">Новый график выдачи ключей</Typography>
        </Grid>
        <Grid item xs={12}>
          <div>
            <Typography variant="subtitle2" align="right">
              Статус: черновик
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <b>Выберите район:</b>
          </Typography>
          <FormControl variant="standard" sx={{ ml: 1, minWidth: "97%" }}>
            <Select
              labelId="select-district-label"
              id="select-district"
              value={district}
              onChange={handleDistrictChange}
            >
              {districtList.map((dist, index) => (
                <CustomWidthTooltip
                  title={dist?.description}
                  placement="right"
                  key={index}
                  value={dist.id}
                >
                  <MenuItem key={index} value={dist.id}>
                    {dist.name}
                  </MenuItem>
                </CustomWidthTooltip>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <div>
            <Box>
              <Typography
                variant="body1"
                sx={{ paddingTop: "8px", float: "left" }}
              >
                <b>Список объектов и секций в порядке выдачи ключей:</b>
              </Typography>
              <IconButton
                onClick={handleModalAddSection}
                aria-label="add"
                disabled={district ? false : true}
                sx={{ float: "right" }}
              >
                <AddIcon />
              </IconButton>
            </Box>
            {modalWindowAddSection}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid
            rowSpacing={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "200px",
              width: "100%",
              overflowY: "auto",
            }}
          >
            {sectionList.length === 0 ? (
              <Typography>Нет секции для отображения</Typography>
            ) : (
              sectionList.map((section, index) => (
                <Box
                  sx={{ width: "100%", display: "flex", flexDirection: "row" }}
                >
                  <Typography
                    variant="body1"
                    key={index}
                    sx={{ width: "94%", float: "left", marginTop: "8px" }}
                  >
                    <b>{section.district}</b> {section.building} {section.name}
                  </Typography>
                  <IconButton
                    value={section.id}
                    onClick={() => handleDeleteSection(section.id)}
                    aria-label="delete"
                    sx={{ width: "auto", float: "right" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))
            )}
          </Grid>
        </Grid>

        <Grid mt={2} item xs={12}>
          <Typography variant="body1">
            <b>Метод заполнения графика выдачи</b>
          </Typography>
        </Grid>

        <Grid mt={1} item xs={12}>
          <ToggleButtonGroup
            color="primary"
            value={fillingMethod}
            exclusive
            onChange={handleFillingMethod}
            aria-label="filling-method"
            size="small"
          >
            <ToggleButton value="auto">Автоматический</ToggleButton>
            <ToggleButton value="manually">Вручную</ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid mt={2} item xs={12}>
          <Typography variant="body1">
            <b>Основные даты выдачи</b>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
            <DesktopDatePicker
              label="Начало выдачи"
              inputFormat="dd/MM/yyyy"
              disablePast
              //maxDate={endDate || null}
              value={startDate || null}
              onChange={handleStartDateChange}
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
            <DesktopDatePicker
              label="Окончание выдачи"
              inputFormat="dd/MM/yyyy"
              disablePast
              disabled={startDate ? false : true}
              minDate={startDate}
              value={endDate || null}
              onChange={handleEndDateChange}
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography variant="body1">Задействовать выходные дни:</Typography>
        </Grid>
        <Grid item xs={3}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={issueOnSaturday}
                  onChange={() => setIssueOnSaturday(!issueOnSaturday)}
                />
              }
              label="Суббота"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={9}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={issueOnSunday}
                  onChange={() => setIssueOnSunday(!issueOnSunday)}
                />
              }
              label="Воскресенье"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={issueOnHolidays}
                  onChange={() => setIssueOnHolidays(!issueOnHolidays)}
                />
              }
              label="Задействовать праздничные дни"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={5}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="body1" mt={1} mr={1}>
              Число специалистов:
            </Typography>
            <TextField
              id="standard-basic"
              label=""
              variant="standard"
              type="number"
              value={specialistNumber}
              onChange={handleSpecialistNumberChange}
              sx={{ width: "15%" }}
            />
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="body1" mt={1} mr={1}>
              Квартир на каждого:
            </Typography>
            <TextField
              id="standard-basic"
              label=""
              variant="standard"
              value={flatPerSpec}
              type="number"
              onChange={handleFlatPerSpecChange}
              sx={{ width: "15%" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="body1" mt={1} mr={1}>
              Время на каждого:
            </Typography>

            <FormControl variant="standard" sx={{ mt: "4px", width: "100px" }}>
              <Select
                labelId="select-object-label"
                id="select-object"
                label="Object"
                value={timePerFlat}
                onChange={handleTimePerFlatChange}
              >
                <MenuItem value={"5"}>5 минут</MenuItem>
                <MenuItem value={"10"}>10 минут</MenuItem>
                <MenuItem value={"15"}>15 минут</MenuItem>
                <MenuItem value={"20"}>20 минут</MenuItem>
                <MenuItem value={"25"}>25 минут</MenuItem>
                <MenuItem value={"30"}>30 минут</MenuItem>
                <MenuItem value={"35"}>35 минут</MenuItem>
                <MenuItem value={"40"}>40 минут</MenuItem>
                <MenuItem value={"45"}>45 минут</MenuItem>
                <MenuItem value={"50"}>50 минут</MenuItem>
                <MenuItem value={"55"}>55 минут</MenuItem>
                <MenuItem value={"60"}>60 минут</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item mt={1} xs={12}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="body1">Время выдачи: c</Typography>

            <FormControl
              variant="standard"
              sx={{ ml: 1, mt: -0.4, minWidth: "14%", width: "80px" }}
            >
              <Select
                labelId="select-object-label"
                id="select-object"
                label="Object"
                value={issueStart}
                onChange={handleIssueStartChange}
              >
                {availableTime.map((t, i) => {
                  return <MenuItem value={t}>{t}</MenuItem>;
                })}
              </Select>
            </FormControl>

            <Typography variant="body1" ml={1}>
              до
            </Typography>

            <FormControl
              variant="standard"
              sx={{ ml: 1, mt: -0.4, minWidth: "14%", width: "80px" }}
            >
              <Select
                labelId="select-object-label"
                id="select-object"
                label="Object"
                value={issueEnd}
                onChange={handleIssueEndChange}
              >
                {availableEndTime.map((t, i) => {
                  return <MenuItem value={t}>{t}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={withDiner}
                  onChange={() => setWithDiner(!withDiner)}
                />
              }
              label="Обед"
            />
          </FormGroup>
        </Grid>

        <Grid item mt={1} xs={10}>
          <Box sx={{ display: "flex", marginLeft: "26px" }}>
            <Typography variant="body1">c</Typography>

            <FormControl
              variant="standard"
              sx={{ ml: 1, mt: -0.4, minWidth: "14%", width: "80px" }}
            >
              <Select
                disabled={!withDiner}
                labelId="select-dinnerstart-label"
                id="select-dinnerstart"
                label="DinnerStart"
                value={dinnerStart}
                onChange={handleDinnerStartChange}
              >
                {dinnerTime.map((t, i) => {
                  return <MenuItem value={t}>{t}</MenuItem>;
                })}
              </Select>
            </FormControl>

            <Typography variant="body1" ml={1}>
              до
            </Typography>

            <FormControl
              variant="standard"
              sx={{ ml: 1, mt: -0.4, minWidth: "14%", width: "80px" }}
            >
              <Select
                disabled={!withDiner}
                labelId="select-dinnerend-label"
                id="select-dinnerend"
                label="DinnerEnd"
                value={dinnerEnd}
                onChange={handleDinnerEndChange}
              >
                {separateBy(dinnerTime, dinnerStart)[1].map((t, i) => {
                  return <MenuItem value={t}>{t}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showAltDate}
                  onChange={() => setShowAltDate(!showAltDate)}
                />
              }
              label="Альтернативные даты выдачи"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={4} sx={{ display: showAltDate ? "flex" : "none" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
            <DesktopDatePicker
              label="Начало выдачи"
              inputFormat="dd/MM/yyyy"
              disablePast
              value={startDateAlt}
              onChange={(newValue) => handleStartDateChangeAlt(newValue)}
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{ display: showAltDate ? "flex" : "none" }}
        ></Grid>
        <Grid item xs={4} sx={{ display: showAltDate ? "flex" : "none" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
            <DesktopDatePicker
              label="Окончание выдачи"
              inputFormat="dd/MM/yyyy"
              disablePast
              disabled={startDateAlt ? false : true}
              minDate={startDateAlt}
              value={endDateAlt}
              onChange={(newValue) => handleEndDateChangeAlt(newValue)}
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              maxWidth: "100%",
              mt: "12px",
              mb: "12px",
            }}
          >
            <TextField
              fullWidth
              label="Комментарий"
              multiline
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              rows={4}
              id="fullWidth"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
            <Button
              disabled={isLoading}
              variant="outlined"
              onClick={handleClear}
            >
              Очистить
            </Button>
            <LoadingButton
              disabled={district === null || sectionList.length === 0}
              loading={isLoading}
              variant="contained"
              onClick={handleCreateSchedule}
            >
              Сохранить
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
      <Snackbar
        open={openNotification}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
      >
        <Alert
          onClose={handleNotificationClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Ошибка при создании графика выдачи!
        </Alert>
      </Snackbar>
    </Box>
  );
}
