import React, { useState, useContext, useEffect } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { IMaskInput } from "react-imask";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";

import { NotificationContext } from "../../components/context/NotificationContext";
import { DatabaseContext } from "../../..";
import { UserContext } from "../../components/context/UserContext";
import LinearProgress from "@mui/material/LinearProgress";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(+7) ###-###-##-##"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

function InputPhone({
  setTab,
  setPhoneNumber,
  setClient,
  setTempCode,
  ...rest
}) {
  const setNotification = useContext(NotificationContext);
  const { dbLink, dbGuid, apiPath } = useContext(DatabaseContext);
  const { setIsShareholder, setShareholder } = useContext(UserContext);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState("(+7)");
  const [duration, setDuration] = useState(60);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    let interval = null;
    if (disabled) {
      interval = setInterval(() => {
        setDuration((duration) => duration - 1);
      }, 1000);
      if (duration === 0) resetCountdown();
    } else if (!disabled && duration !== 60) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [disabled, duration]);

  const startCountdown = () => {
    setDisabled(true);
  };

  const resetCountdown = () => {
    setDuration(60);
    setDisabled(false);
  };

  const handlePhoneInput = (newValue) => {
    setError(false);
    setPhone(newValue);
    setPhoneNumber(newValue);
  };

  const handleClick = () => {
    setIsLoading(true);
    startCountdown(true);
    var number = phone.replaceAll("-", "");
    console.log(number);
    if (number.length < 15) {
      setError(true);
      setNotification("error", "Неверно указан номер телефона");
      setIsLoading(false);
    } else {
      let requestUrl = `${dbLink}${apiPath}/keys_issue/mobile_auth?phone_number=${number}`;
      axios
        .get(requestUrl)
        .then((res) => {
          setClient(res.data.data);
          return res.data.data.client_id; // получаем id клиента
        })
        .then((clientId) => {
          console.log(clientId);
          let url = `${dbLink}${apiPath}/keys_issue/get_mobile_code?client_id=${clientId}&phone_number=${number}`;
          axios
            .get(url)
            .then((res) => {
              setNotification(
                "success",
                `Код подтверждения был отправлен на номер ${phone}`
              );
              //console.log(res);
              setTempCode(res.data?.data);
              setIsLoading(false);
              setTab("validate");
            })
            .catch((err) => {
              console.error(err);
              setIsLoading(false);
              setNotification("error", "Ошибка отправки кода подтверждения");
            });
        })
        .catch((err) => {
          console.log("ERROR: ", err);
          setIsLoading(false);
          setNotification("error", err.response?.data?.err_msg);
        });
    }
  };

  console.log(UserContext.userObject, "object")
  console.log(UserContext.userObjectsArr, "objectARRAY")

  return (
    <Box
      sx={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: {
          xs: "100%",
          sm: 550,
        },
        justifyContent: "center !important",
        height: 382,
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* <Typography
            variant="h4"
            sx={{ marginLeft: "24px", marginTop: "24px" }}
          >
            <b>4DEV</b>
          </Typography> */}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" align="center" sx={{ mt: "0px" }}>
            <b>График выдачи ключей</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="center" sx={{ mt: "4px" }}>
            Введите свой номер телефона, чтобы войти
            {UserContext.userObjectsArr}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" align="center" sx={{ mt: "-12px" }}>
            На указанный номер поступит смс
          </Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <Box sx={{ width: "220px" }}>
            <Typography variant="caption" sx={{ float: "left" }}>
              Контактный телефон:
            </Typography>
            <FormControl variant="outlined" size="small">
              <OutlinedInput
                error={error}
                value={phone}
                onChange={(e) => handlePhoneInput(e.target.value)}
                name="phoneMAsk"
                id="formatted-phone-mask-input"
                inputComponent={TextMaskCustom}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} align="center">
          <LoadingButton
            loading={isLoading}
            disabled={disabled}
            variant="contained"
            sx={{ width: "220px", mt: "4px" }}
            onClick={handleClick}
          >
            Получить код
          </LoadingButton>
        </Grid>
        <Grid item xs={12} align="center">
          {duration !== 60 && (
            <Typography variant="caption">
              Повторная отправка возможна через {duration} секунд
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

function Validate({ setTab, phone, client, tempCode, ...rest }) {
  const [validateCode, setValidateCode] = useState(tempCode || ""); // tempCode
  const [error, setError] = useState(false);
  const setNotification = useContext(NotificationContext);
  const { dbLink, dbGuid, apiPath } = useContext(DatabaseContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (newValue) => {
    setError(false);
    const re = /^\d+$/;
    if (newValue === "" || re.test(newValue)) {
      setValidateCode(newValue);
    }
  };

  const handleSendCodeClick = () => {
    setIsLoading(true);
    let url = `${dbLink}${apiPath}/keys_issue/check_mobile_code?client_id=${client.client_id}&code=${validateCode}`;
    axios
      .get(url)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        if (res.data.success === true) {
          axios.defaults.headers.common["Authorization"] =
            res.data.data;
          setTab("info");
        }
      })
      .catch((err) => {
        console.error(err);
        setError(true);
        setIsLoading(false);
        setNotification(
          "error",
          err.response.data.err_msg || "Ошибка проверки кода"
        );
      });
  };

  return (
    <Box
      sx={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: {
          xs: "100%",
          sm: 550,
        },
        height: 427,
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* <Typography
            variant="h4"
            sx={{ marginLeft: "24px", marginTop: "24px" }}
          >
            <b>4DEV</b>
          </Typography> */}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" align="center" sx={{ mt: "0px" }}>
            <b>График выдачи ключей</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="center" sx={{ mt: "0px" }}>
            <b>Введите код</b>
          </Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <Typography variant="body2" sx={{ mt: "0px", width: "240px" }}>
            Мы отправили код подтверждения на номер {phone}
          </Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <TextField
            error={error}
            id="validation-code"
            variant="outlined"
            size="small"
            value={validateCode}
            onChange={(e) => handleChange(e.target.value)}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            sx={{ width: "220px", mt: "8px" }}
          />
        </Grid>
        <Grid item xs={12} align="center">
          <LoadingButton
            loading={isLoading}
            variant="contained"
            sx={{ width: "220px", mt: "8px" }}
            onClick={() => handleSendCodeClick()}
          >
            Отправить код
          </LoadingButton>
        </Grid>
        <Grid item xs={12} align="center">
          <Stack
            spacing={2}
            direction="row"
            sx={{ width: "max-content", mt: "20px" }}
          >
            <Button
              variant="text"
              size="small"
              style={{ textTransform: "none" }}
              onClick={() => setTab("input")}
            >
              Изменить номер
            </Button>
            <Button
              variant="text"
              size="small"
              style={{ textTransform: "none" }}
              onClick={() => setTab("error")}
            >
              Не приходит СМС?
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

function Error({ setTab, ...rest }) {
  return (
    <Box
      sx={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: {
          xs: "100%",
          sm: 550,
        },
        height: 380,
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* <Typography
            variant="h4"
            sx={{ marginLeft: "24px", marginTop: "24px" }}
          >
            <b>4DEV</b>
          </Typography> */}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" align="center" sx={{ mt: "0px" }}>
            <b>График выдачи ключей</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="center" sx={{ mt: "8px" }}>
            <b>Если не поступает СМС с кодом</b>
          </Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <Typography variant="body2" align="center" sx={{ mt: "0px" }}>
            Проверьте, что
          </Typography>
          <Typography
            variant="body2"
            align="center"
            sx={{ mt: "12px", width: "400px" }}
          >
            <li>ваш телефон включён и находится в зоне приёма сети,</li>
            <li>вы ввели правильный номер телефона.</li>
          </Typography>
          <Typography
            variant="body2"
            align="center"
            sx={{ mt: "12px", width: "450px" }}
          >
            Иногда СМС приходит не сразу. Если СМС нет 20 минут, попробуйте
            получить код ещё раз.
          </Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <Button
            variant="text"
            size="medium"
            style={{ textTransform: "none" }}
            onClick={() => setTab("input")}
          >
            Войти по номеру телефона
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function Info({
  phone,
  setTab,
  client,
  setObject,
  isConfirmationOnly,
  isReadOnly,
  ...rest
}) {
  const { dbLink, dbGuid, apiPath } = useContext(DatabaseContext);
  const {
    userObjectsArr,
    loadUser,
    setUserObject,
    isShareholder,
    shareholder,
    isContextLoading,
    setIsContextLoading,
    setSelectedScheduleId,
  } = useContext(UserContext);


  const setNotification = useContext(NotificationContext);
  const [time, setTime] = useState("issue time");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsContextLoading(true);
    loadUser(client?.client_id);
  }, []);

  const confirmIssue = (obj) => {
    setIsLoading(true);
    if (obj.object_id !== "") {
      setObject(obj);
      console.log("Obj: ", obj);
      let url = `${dbLink}${apiPath}/keys_issue/customer_confirmation`;
      let params = {
        object_id: obj.object_id,
        update_time: false,
        record_time: "",
        client_id: obj.client_id,
      };

      axios
        .post(url, params)
        .then((res) => {
          console.log(res);
          setIsLoading(false);
          setNotification("success", "Запись успешно подтверждена");
          setTab("confirm");
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
          setNotification(
            "error",
            err.response.data.err_msg || "Ошибка при подтверждении записи"
          );
        });
    } else {
      setIsLoading(false);
      setNotification("error", "Ошибка при подтверждении записи");
    }
  };

  const handleChangeDateClick = (object) => {
    setUserObject(object);
    console.log("setUserObject: ", object);
    setSelectedScheduleId(object.schedule_id);
    navigate("/rec/users/calendar", {
      replace: true,
    });
  };

  return (
    <Box
      sx={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: {
          xs: "100%",
          sm: 550,
        },
        height: userObjectsArr.length > 1 ? 390 : 344,
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        // sx={{
        //   position: "absolute",
        //   top: {
        //     xs: "24px",
        //     sm: "24px",
        //   },
        //   right: {
        //     xs: "4px",
        //     sm: "24px",
        //   },
        // }}
      >
        <Typography variant="body2" align="center" sx={{textAlign: "center", width: "100%", padding: "30px 0 0 0", fontWeight: "bold"}}>
          {isShareholder ? shareholder.client : userObjectsArr[0]?.client}
        </Typography>
        {/* <Typography variant="body2" align="right">
          {phone}
        </Typography> */}
      </Box>
      <Grid container spacing={2} >
        <Grid item xs={12}>
          {/* <Typography
            variant="h4"
            sx={{
              marginLeft: {
                xs: "4px",
                sm: "24px",
              },
              marginTop: {
                xs: "24px",
                sm: "24px",
              },
            }}
          >
            <b>4DEV</b>
          </Typography> */}
        </Grid>
        {false ? (
          <>
            <Grid item xs={12} sx={{height: "100%"}}>
              <Typography variant="body1" align="center" sx={{ mt: "16px" }}>
                Ищем ваши объекты недвижимости
              </Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <LinearProgress />
            </Grid>
            <Grid item xs={1} />
          </>
        ) : userObjectsArr[0]?.issuing_time.length < 1 ? (
          <Grid item xs={12} sx={{height: "100%"}}>
            <Typography variant="h6" align="center" sx={{ mt: "16px" }}>
                Выдача ключей в {userObjectsArr[0].object_label}
            </Typography>
            <Typography variant="body2" align="center" sx={{ mt: "16px" }}>
                Выберите дату и время для выдачи:
            </Typography>
            <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <Button
                  variant="outlined"
                  size="small"
                  align="center"
                  style={{ textTransform: "none", marginTop: "14px" }}
                  onClick={() => handleChangeDateClick(userObjectsArr[0])}
                >
                  Записаться
              </Button>
             </div>
          </Grid>)
          : userObjectsArr?.length < 1 ? (
          <Grid item xs={12} sx={{height: "100%"}}>
            <Typography variant="body2" align="center" sx={{ mt: "16px" }}>
              На ваше имя объектов недвижимости для выдачи не найдено
            </Typography>
          </Grid>
        ) : userObjectsArr?.length === 1 ? (
          isShareholder ? (
            <>
              <Grid item xs={12} sx={{height: "100%"}}>
                <Typography variant="h6" align="center" sx={{ mt: "16px" }}>
                  Выдача ключей в {userObjectsArr[0].object_label}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" align="center" sx={{ mt: "8px" }}>
                  Указать дату и время записи?
                </Typography>
              </Grid>
              <Grid item xs={12} align="center">
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ width: "max-content", mt: "20px" }}
                >
                  <Button
                    variant="contained"
                    size="medium"
                    style={{ textTransform: "none" }}
                    onClick={() => handleChangeDateClick(userObjectsArr[0])}
                  >
                    Указать
                  </Button>
                  <LoadingButton
                    variant="outlined"
                    size="medium"
                    style={{ textTransform: "none" }}
                    onClick={() => {
                      window.open(
                        `/rec/users/?host=${dbLink}&baseUrl=${apiPath}`,
                        "_self"
                      );
                    }}
                  >
                    Отмена
                  </LoadingButton>
                </Stack>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="body2" align="center" sx={{ mt: "16px" }}>
                  Выдача ключей в {userObjectsArr[0].object} назначена на:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" align="center" sx={{ mt: "8px" }}>
                  <b>{userObjectsArr[0]?.issuing_time.replace("T", " в ")}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" align="center" sx={{ mt: "8px" }}>
                  {userObjectsArr[0].read_only
                    ? ""
                    : "Подтвердить дату и время записи?"}
                </Typography>
              </Grid>
              <Grid item xs={12} align="center">
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ width: "max-content", mt: "20px" }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ textTransform: "none" }}
                    // sx={{
                    //   display:
                    //     userObjectsArr[0].read_only ||
                    //     userObjectsArr[0].confirmation_only
                    //       ? "none"
                    //       : "auto",
                    // }}
                    onClick={() => handleChangeDateClick(userObjectsArr[0])}
                  >
                    Изменить дату и время
                  </Button>
                  <LoadingButton
                    variant="contained"
                    size="small"
                    // sx={{
                    //   display: userObjectsArr[0].read_only ? "none" : "auto",
                    // }}
                    style={{ textTransform: "none" }}
                    onClick={() => confirmIssue(userObjectsArr[0])}
                  >
                    Подтвердить запись
                  </LoadingButton>
                </Stack>
              </Grid>
            </>
          )
        ) : (
          <>
            <Grid item xs={12}>
              <Typography align="center" variant="h5">
                Ваши объекты:
              </Typography>
            </Grid>
            <Box
              sx={{
                width: "100%",
                minHeight: "250px",
                maxHeight: "250px",
                mt: "20px",
                ml: "16px",
                overflowY: "auto",
                scrollbarWidth: "thin",
                "::-webkit-scrollbar": {
                  backgroundColor: "#fff",
                  width: "16px",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "#fff",
                },
                "::-webkit-scrollbar-track:hover": {
                  backgroundColor: "#f4f4f4",
                },
                "::-webkit-scrollbar-thumb": {
                  backgroundColor: "#babac0",
                  borderRadius: "16px",
                  border: "5px solid #fff",
                },
                "::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#a0a0a5",
                  border: "4px solid #f4f4f4",
                },
                "::-webkit-scrollbar-button": { display: "none" },
              }}
            >
              {userObjectsArr?.map((obj, i) => {
                return (
                  <Grid
                    container
                    key={"obj" + i}
                    spacing={0.5}
                    sx={{
                      border: "1px solid #eaeaea",
                      borderRadius: "10px",
                      marginTop: "4px",
                      marginLeft: "4px",
                      width: "99%",
                      "&:hover": {
                        color: "primary.dark",
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                  >
                    <Grid item xs={12}>
                      <Stack direction={"row"} spacing={1}>
                        <Typography variant="body2">
                          Объект недвижимости:{" "}
                        </Typography>
                        <Typography variant="body2" color={"#63707A"}>
                          {obj.object}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction={"row"} spacing={1}>
                        <Typography variant="body2">Дата выдачи:</Typography>
                        <Typography variant="body2" color={"#63707A"}>
                          {obj.issuing_time.replace("T", " в ").slice(0, -3)}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction={"row"} spacing={1}>
                        <Typography variant="body2">Статус записи:</Typography>
                        <Typography
                          variant="body2"
                          color={
                            obj.approved_by_client
                              ? "success.light"
                              : "error.light"
                          }
                        >
                          {obj.approved_by_client
                            ? "подтверждена"
                            : "не подтверждена"}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction={"row-reverse"} spacing={1}>
                        <Button
                          // sx={{ display: obj.read_only ? "none" : "auto" }}
                          size="small"
                          onClick={() => confirmIssue(obj)}
                        >
                          <Typography variant="caption" fontSize="12px">
                            Подтвердить запись
                          </Typography>
                        </Button>
                        <Button
                          size="small"
                          // sx={{
                          //   display:
                          //     obj.read_only || obj.confirmation_only
                          //       ? "none"
                          //       : "auto",
                          // }}
                        >
                          <Typography
                            variant="caption"
                            fontSize="12px"
                            onClick={() => handleChangeDateClick(obj)}
                          >
                            Изменить дату и время
                          </Typography>
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          </>
        )}
      </Grid>
    </Box>
  );
}

function Confirm({ setTab, object, ...rest }) {
  const { userObject } = useContext(UserContext);

  const handleClick = () => {
    window.open("/rec/users/", "_self");
  };

  return (
    <Box
      sx={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: {
          xs: "100%",
          sm: 550,
        },
        height: 290,
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body2" align="center" sx={{ mt: "72px" }}>
            Ваша запись на выдачу ключей в {object?.object}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="center" sx={{ mt: "8px" }}>
            <b>{object?.issuing_time.replace("T", " в ")}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" align="center" sx={{ mt: "8px" }}>
            <b>успешно подтверждена!</b>
          </Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <Button
            variant="contained"
            size="small"
            sx={{ width: "96px" }}
            color="success"
            onClick={() => handleClick()}
          >
            OK
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function ValidatePhone() {
  const { phone, setPhone } = useContext(UserContext);
  const [tempCode, setTempCode] = useState("Подождите...");
  const [client, setClient] = useState("");
  const [tab, setTab] = useState("input");
  const [isConfirmationOnly, setIsConfirmationOnly] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  //const [phone, setPhone] = useState("");
  const [object, setObject] = useState({
    client_id: "",
    client: "",
    object_id: "",
    object: "",
    contract_id: "",
    contract: "",
    transfer_act_id: "",
    transfer_act: "",
    approved_by_client: "",
    issuing_time: "",
  });

  const handleChangePhone = (newvalue) => {
    setPhone(newvalue);
  };

  switch (tab) {
    case "input":
      return (
        <InputPhone
          setTab={setTab}
          setPhoneNumber={setPhone}
          setClient={setClient}
          setTempCode={setTempCode}
          setIsConfirmationOnly={setIsConfirmationOnly}
          setIsReadOnly={setIsReadOnly}
        />
      );
    case "validate":
      return (
        <Validate
          setTab={setTab}
          phone={phone}
          client={client}
          tempCode={tempCode}
        />
      );
    case "error":
      return <Error setTab={setTab} />;
    case "info":
      return (
        <Info
          setTab={setTab}
          phone={phone}
          client={client}
          object={object}
          setObject={setObject}
          isConfirmationOnly={isConfirmationOnly}
          isReadOnly={isReadOnly}
        />
      );
    case "confirm":
      return <Confirm setTab={setTab} object={object} />;
    default:
      return <InputPhone setTab={setTab} setPhoneNumber={handleChangePhone} />;
  }
}
