export function separateBy(arr, elem) {
  let firstPart = arr.slice(0, arr.indexOf(elem));
  let secondPart = arr.slice(arr.indexOf(elem) + 1);
  return [firstPart, secondPart];
}

export function changeSymbols(str, firstSymbol, secondSymbol) {
  let newStr = "";
  for (let i = 0; i < str.length; i++) {
    if (str[i] === firstSymbol) {
      newStr += secondSymbol;
    } else {
      newStr += str[i];
    }
  }
  return newStr;
}

export function checkTime(startTime, endTime, timeToCheck) {
  const startTimeArr = startTime.split(":");
  const endTimeArr = endTime.split(":");
  const timeToCheckArr = timeToCheck.split(":");
  const startTimeInMinutes = startTimeArr[0] * 60 + +startTimeArr[1];
  const endTimeInMinutes = endTimeArr[0] * 60 + +endTimeArr[1];
  const timeToCheckInMinutes = timeToCheckArr[0] * 60 + +timeToCheckArr[1];
  return (
    timeToCheckInMinutes >= startTimeInMinutes &&
    timeToCheckInMinutes <= endTimeInMinutes
  );
}

export function timeArray(start, end, step) {
  let startTime = start.split(":");
  let endTime = end.split(":");
  let currentTime = startTime;
  let result = [];
  while (
    currentTime[0] * 60 + +currentTime[1] <
    endTime[0] * 60 + +endTime[1]
  ) {
    result.push(currentTime.join(":"));
    currentTime[1] = +currentTime[1] + step;
    if (currentTime[1] > 59) {
      currentTime[0] = +currentTime[0] + 1;
      currentTime[1] = 0;
      if (currentTime[0] < 10) {
        currentTime[0] = "0" + currentTime[0];
      }
    }
    if (currentTime[1] < 10) {
      currentTime[1] = "0" + currentTime[1];
    }
  }
  return result;
}
