import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#FFFFFF",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(0),
    width: "100%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
}));

const Divider = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <hr color="#D7DBDF" />
      <Box
        sx={{
          display: "flex",
          width: "70px",
          margin: "-18px auto",
          marginBottom: "0px",
          backgroundColor: "#D7DBDF",
          borderRadius: "20px",
        }}
      >
        <Typography variant="caption" sx={{ flexGrow: 1, textAlign: "center" }}>
          Сегодня
        </Typography>
      </Box>
    </Box>
  );
};

const Record = ({ time, type, label }) => {
  return (
    <Box
      sx={{
        borderLeft: "4px solid #1C61EA",
        width: "100%",
        paddingLeft: "12px",
      }}
    >
      <Stack spacing={2} direction="row">
        <Typography>{time}</Typography>
        {type === "comment" && <ChatBubbleOutlineIcon sx={{ color: "#1C93EA" }} />}
        {type === "meeting" && <PeopleOutlineIcon sx={{ color: "#FFC210" }} />}
        <Typography>{label}</Typography>
      </Stack>
    </Box>
  );
};

export default function Events() {
  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Stack spacing={2} direction="row">
          <Button variant="text" startIcon={<AccessTimeIcon />}>
            История
          </Button>
          <Button variant="text" startIcon={<LibraryBooksIcon />}>
            База знаний
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Поиск по истории событий"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
          <Button variant="contained">Найти</Button>
          <IconButton aria-label="settings">
            <TuneIcon />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Record
          time={"10:30"}
          label={"Арсений Новый этап: Договор из Бронирование"}
        />
      </Grid>
      <Grid item xs={12}>
        <Record
          time={"10:30"}
          type={"meeting"}
          label={
            "Встреча с Александром Прохоровым"
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Record
          time={"10:30"}
          type={"comment"}
          label={
            "Комментарий от: Арсений, кому: всем Подписание договора осложнено из-за проблем с оформлением ипотеки."
          }
        />
      </Grid>
    </Grid>
  );
}
