import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { DatabaseContext } from "../../..";
import { NotificationContext } from "./NotificationContext";

const UserContext = React.createContext();

const UserContextProvider = ({ children }) => {
  const { dbLink, dbGuid, apiPath } = useContext(DatabaseContext);
  const setNotification = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isShareholder, setIsShareholder] = useState(false);
  const [shareholder, setShareholder] = useState();
  const [userObject, setUserObject] = useState({
    approved_by_client: false,
    client: "",
    client_id: "",
    contract: "",
    contract_id: "",
    issuing_time: "",
    object: "",
    object_id: "",
    transfer_act: "",
    transfer_act_id: "",
  });

  const [userObjectsArr, setUserObjectArr] = useState([]);
  const [selectedScheduleId, setSelectedScheduleId] = useState([]);
  const [phone, setPhone] = useState("+7 (999) 999-99-99");

  useEffect(() => {
    //setIsLoading(!isLoading);
  }, [userObjectsArr]);

  const handleSetIsShareHolder = (newValue) => {
    setIsShareholder(newValue);
  };

  const handleSetShareholder = (newValue) => {
    setShareholder(newValue);
  };

  const handleSetObjectArr = (newValue) => {
    console.log("New User Object: ", newValue);
    setUserObjectArr(newValue);
  };

  const handleSetPhone = (newValue) => {
    console.log("New phone value: ", newValue);
    setPhone(newValue);
  };

  const loadUser = (uid, objId) => {
    console.log("Load User");
    setIsLoading(true);
    if (isShareholder) {
      // shareholder.objects - массив объектов клиента
      let d = new Date();
      let tempArr = [];
      /*
      shareholder?.objects?.forEach((obj) => {
        let url = `${dbLink}${apiPath}/keys_issue/queue?object_id=${
          obj.object_id
        }&timestamp=${d.toISOString()}`;
        axios
          .get(url)
          .then((res) => {
            console.log(res);
            // Заполняем информацию об объектах дольщика
            res.data.forEach((d) => {
              d.data.forEach((o) => {
                tempArr.push(o);
              });
            });
            //setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setNotification("error", "Ошибка при загрузке объектов дольщика");
            setIsLoading(false);
          })
      });
      */
      console.log("TEMP ARR: ", tempArr);
      handleSetObjectArr(shareholder.objects);
      setIsLoading(false);
    } else {
      let d = new Date();
      let url = `${dbLink}${apiPath}/keys_issue/objects_by_client?client_id=${uid}&timestamp=${d.toISOString()}`;
      axios
        .get(url)
        .then((res) => {
          console.log("NEW ARR: ", res.data.data);
          let newObj = res.data.data.find((el) => el.object_id === objId);
          handleSetObjectArr(res.data.data);
          console.log("new obj: ", newObj);
          //setSelectedScheduleId(newObj.schedule_id);
          setUserObject(newObj);
          //setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setNotification("error", "Ошибка загрузки данных");
        });
    }
  };

  console.log(userObjectsArr, "USERoBJECTS")

  return (
    <UserContext.Provider
      value={{
        userObjectsArr: userObjectsArr,
        setUserObjectArr: handleSetObjectArr,
        setUserObject: setUserObject,
        userObject: userObject,
        loadUser: loadUser,
        phone: phone,
        setPhone: handleSetPhone,
        setIsShareholder: handleSetIsShareHolder,
        isShareholder: isShareholder,
        shareholder: shareholder,
        setShareholder: handleSetShareholder,
        isContextLoading: isLoading,
        setIsContextLoading: setIsLoading,
        selectedScheduleId: selectedScheduleId,
        setSelectedScheduleId: setSelectedScheduleId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
