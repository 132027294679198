import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import "./index.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ru } from "date-fns/locale/";
import { parseISO, addMinutes, format } from "date-fns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import AddSection from "../Create/AddSection";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { DatabaseContext } from "../../../../index";
import { NotificationContext } from "../../context/NotificationContext";
import { DataContext } from "../../context/DataContext";
import { dinnerTime } from "../Create";
import { separateBy } from "../../utils/utils";

const dateFormat = (date, end = false) => {
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  let d = date.getDate();

  return (
    (y < 2 ? "000" + y : y) +
    "-" +
    (m < 10 ? "0" + m : m) +
    "-" +
    (d < 10 ? "0" + d : d) +
    (end ? "T23:59:59" : "T00:00:00")
  );
};

export default function EditSchedule({ onClose, ...rest }) {
  const {
    objsData,
    changeData,
    selectedSchedule,
    changeSchedule,
    scheduleDate,
    changeScheduleDate,
    selectedPeriod,
    setNewPeriod,
    showWeekends,
    changeShowWeekends,
    reloadIssues,
    reload,
  } = useContext(DataContext);
  const setNotification = useContext(NotificationContext);
  const { dbLink, dbGuid, apiPath } = useContext(DatabaseContext);

  const [sectionList, setSectionList] = useState([]);
  const [districtId, setDistrictId] = useState(null);
  const [object, setObject] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [issueOnSunday, setIssueOnSunday] = useState(false);
  const [issueOnSaturday, setIssueOnSaturday] = useState(false);
  const [issueOnHolidays, setIssueOnHolidays] = useState(false);
  const [issueStart, setIssueStart] = useState("08:00");
  const [issueEnd, setIssueEnd] = useState("18:00");
  const [startDateAlt, setStartDateAlt] = useState("");
  const [endDateAlt, setEndDateAlt] = useState("");
  const [specialistNumber, setSpecialistNumber] = useState(1);
  const [flatPerSpec, setFlatPerSpec] = useState(1);
  const [timePerFlat, setTimePerFlat] = useState(15);
  const [showAltDate, setShowAltDate] = useState(false);

  const [modalAddSection, setModalAddSection] = useState(false);

  const [availableTime, setAvailableTime] = useState([]);
  const [availableEndTime, setAvailableEndTime] = useState([]);

  const [withDiner, setWithDiner] = useState(false);
  const [dinnerStart, setDinnerStart] = useState("13:00");
  const [dinnerEnd, setDinnerEnd] = useState("14:00");

  useEffect(() => {
    //console.log("Schedule for edit: ", selectedSchedule);

    setDistrictId(selectedSchedule.district_id);
    setSectionList(selectedSchedule.sections);
    setStartDate(new Date(selectedSchedule.issue_start));
    setEndDate(new Date(selectedSchedule.issue_end));

    setStartDateAlt(new Date(selectedSchedule.alternative_issue_start));
    setEndDateAlt(new Date(selectedSchedule.alternative_issue_end));

    setIssueOnSaturday(selectedSchedule.issue_on_saturday === "true");
    setIssueOnSunday(selectedSchedule.issue_on_sunday === "true");
    setIssueOnHolidays(selectedSchedule.issue_on_holidays === "true");
    setIssueStart(
      selectedSchedule.issue_start_time.split("T")[1].split(":")[0] +
        ":" +
        selectedSchedule.issue_start_time.split("T")[1].split(":")[1]
    );
    setIssueEnd(
      selectedSchedule.issue_end_time.split("T")[1].split(":")[0] +
        ":" +
        selectedSchedule.issue_end_time.split("T")[1].split(":")[1]
    );

    setWithDiner(selectedSchedule.have_dinner === "true");
    setDinnerStart(
      selectedSchedule.dinner_start_time.split("T")[1].slice(0, -3)
    );
    let d = parseISO(selectedSchedule.dinner_end_time);
    let dinner_end = format(addMinutes(d, 1), "HH:mm");
    setDinnerEnd(dinner_end);

    setSpecialistNumber(Number(selectedSchedule.number_of_specialists));
    setFlatPerSpec(Number(selectedSchedule.flat_per_specialist));
    setTimePerFlat(Number(selectedSchedule.time_on_issue));
    setComment(selectedSchedule.comment);
  }, []);

  useEffect(() => {
    createTimeList("08:00", "20:00", timePerFlat);
  }, [timePerFlat]);

  const createTimeList = (startTime, endTime, step) => {
    // 07:00
    let endH = parseInt(endTime.split(":")[0], 10);
    let startH = parseInt(startTime.split(":")[0], 10);
    let stepParsed = parseInt(step, 10);

    let diffH = endH - startH;
    let totalMins = diffH * 60;
    let stepMins = totalMins / stepParsed;
    let arr = [startTime];
    let time = 0;
    for (let i = 0; i < Math.round(stepMins); i++) {
      time += stepParsed;
      let hh = startH + Math.floor(time / 60);
      let mm = time - Math.floor(time / 60) * 60;
      arr.push(`${hh < 10 ? "0" + hh : hh}:${mm < 10 ? "0" + mm : mm}`);
    }
    console.log(arr);
    setAvailableTime(arr);
    setAvailableEndTime(arr);
  };

  const [comment, setComment] = useState("");

  const handleDinnerStartChange = (event) => {
    //console.log(event.target.value);
    setDinnerStart(event.target.value);
  };

  const handleDinnerEndChange = (event) => {
    //console.log(event.target.value);
    setDinnerEnd(event.target.value);
  };

  const handleStartDateChangeAlt = (newValue) => {
    setStartDateAlt(newValue);
  };

  const handleEndDateChangeAlt = (newValue) => {
    setEndDateAlt(newValue);
  };

  const handleTimePerFlatChange = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    setTimePerFlat(onlyNums);
  };

  const handleSpecialistNumberChange = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    setSpecialistNumber(onlyNums);
  };

  const handleFlatPerSpecChange = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    setFlatPerSpec(onlyNums);
  };

  const handleIssueStartChange = (event) => {
    let index = availableTime.indexOf(event.target.value);
    let tempArr = availableTime.slice(index, availableTime.length);
    setIssueStart(event.target.value);
    setAvailableEndTime(tempArr);
  };

  const handleIssueEndChange = (event) => {
    setIssueEnd(event.target.value);
  };

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  const handleModalAddSection = () => {
    setModalAddSection(true);
  };

  const handleEditSchedule = () => {
    let tempObj;
    try {
      let d = parseISO("2022-12-12T" + dinnerEnd + ":00");
      let dinner_end = format(addMinutes(d, -1), "HHmmss");
      tempObj = {
        author_id: selectedSchedule.author_id,
        alternative_issue_start: dateFormat(startDateAlt),
        alternative_issue_end: dateFormat(endDateAlt, true),
        issue_start_time: issueStart.replace(":", "") + "00",
        issue_end_time: issueEnd.replace(":", "") + "00",
        time_on_issue: timePerFlat,
        issue_on_holidays: issueOnHolidays,
        issue_on_sunday: issueOnSunday,
        issue_on_saturday: issueOnSaturday,
        have_dinner: withDiner,
        dinner_start_time: dinnerStart.replace(":", "") + "00",
        dinner_end_time: dinner_end,
        flat_per_specialist: flatPerSpec,
        number_of_specialists: specialistNumber,
        comment: "",
        issue_start: dateFormat(startDate),
        issue_end: dateFormat(endDate, true),
        organisation_id: selectedSchedule.organisation_id,
        responsible_id: selectedSchedule.responsible_id,
        unit_id: selectedSchedule.unit_id,
        district_id: selectedSchedule.district_id,
        sections: selectedSchedule.sections,
      };
    } catch (err) {
      console.log(err);
      setNotification("error", "Ошибка при формировании объекта графика выдачи ключей");
    }

    //console.log("Edited schedule: ", tempObj)

    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .put(
        `${dbLink}${apiPath}/keys_issue/schedule?id=${selectedSchedule.schedule_id}`,
        tempObj,
        config
      )
      .then((response) => {
        console.log(response);
        setNotification("success", "График выдачи ключей успешно обновлен");
        reloadIssues();
        reload();
        onClose();
      })
      .catch((err) => {
        console.log(err);
        setNotification(
          "error",
          "Ошибка при редактировании графика выдачи ключей"
        );
      });
  };

  const handleAddSection = (section) => {
    console.log("Section to add: ", section);
    var tempArr = sectionList;
    const found = tempArr.some((el) => el.section_id === section.id);
    if (!found) tempArr.push({});
    setSectionList(tempArr);
  };

  const handleDeleteSection = (id) => {
    var tempArr = [...sectionList];
    console.log("OBJ LIST:", tempArr);
    var index = tempArr.findIndex(function (s) {
      return s.id === id;
    });
    if (index !== -1) tempArr.splice(index, 1);
    setSectionList(tempArr);
    console.log(id);
  };

  const modalWindowAddSection = (
    <Modal
      open={modalAddSection}
      aria-labelledby="modal-add-section-title"
      aria-describedby="modal-add-section-description"
    >
      <AddSection
        close={() => setModalAddSection(false)}
        add={handleAddSection}
        district={districtId}
      />
    </Modal>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IconButton
        sx={{ position: "absolute", right: 2, top: 2 }}
        aria-label="close"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
        <Grid item xs={11}>
          <Typography variant="h5">
            {selectedSchedule.schedule.split("от")[0]}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div>
            <Typography variant="subtitle2" align="right">
              Статус: {selectedSchedule.status}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <b>Дата создания:</b> {selectedSchedule.schedule.split("от")[1]}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <b>Район:</b> {selectedSchedule.district}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div>
            <Box>
              <Typography
                variant="body1"
                sx={{ paddingTop: "8px", float: "left" }}
              >
                <b>Список объектов и секций в порядке выдачи ключей:</b>
              </Typography>
              {/*
              <IconButton
                onClick={handleModalAddSection}
                aria-label="add"
                disabled={districtId ? false : true}
                sx={{ float: "right" }}
              >
                <AddIcon />
              </IconButton>
  */}
            </Box>
            {modalWindowAddSection}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid
            rowSpacing={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "200px",
              width: "100%",
              overflowY: "auto",
            }}
          >
            {sectionList.length === 0 ? (
              <Typography>Нет секции для отображения</Typography>
            ) : (
              sectionList.map((section, index) => (
                <Box
                  sx={{ width: "100%", display: "flex", flexDirection: "row" }}
                >
                  <Typography
                    variant="body1"
                    key={index}
                    sx={{ width: "94%", float: "left", marginTop: "8px" }}
                  >
                    <b>{section.section}</b> {section.adress}
                  </Typography>
                  {/*
                  <IconButton
                    value={section.id}
                    onClick={() => handleDeleteSection(section.id)}
                    aria-label="delete"
                    sx={{ width: "auto", float: "right" }}
                  >
                    <DeleteIcon />
                  </IconButton>
              */}
                </Box>
              ))
            )}
          </Grid>
        </Grid>

        <Grid mt={2} item xs={12}>
          <Typography variant="body1">
            <b>Основные даты выдачи:</b>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
            <DesktopDatePicker
              label="Начало выдачи"
              inputFormat="dd/MM/yyyy"
              disablePast
              value={startDate || null}
              onChange={handleStartDateChange}
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
            <DesktopDatePicker
              label="Окончание выдачи"
              inputFormat="dd/MM/yyyy"
              disablePast
              disabled={startDate ? false : true}
              minDate={startDate}
              value={endDate || null}
              onChange={handleEndDateChange}
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Typography variant="body1">Задействовать выходные дни:</Typography>
        </Grid>
        <Grid item xs={3}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={issueOnSaturday}
                  onChange={() => setIssueOnSaturday(!issueOnSaturday)}
                />
              }
              label="Суббота"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={9}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={issueOnSunday}
                  onChange={() => setIssueOnSunday(!issueOnSunday)}
                />
              }
              label="Воскресенье"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={issueOnHolidays}
                  onChange={() => setIssueOnHolidays(!issueOnHolidays)}
                />
              }
              label="Задействовать праздничные дни"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={5}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="body1" mt={1} mr={1}>
              Число специалистов:
            </Typography>
            <TextField
              id="standard-basic"
              label=""
              variant="standard"
              type="number"
              value={specialistNumber}
              onChange={handleSpecialistNumberChange}
              sx={{ width: "15%" }}
            />
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="body1" mt={1} mr={1}>
              Квартир на каждого:
            </Typography>
            <TextField
              id="standard-basic"
              label=""
              variant="standard"
              value={flatPerSpec}
              type="number"
              onChange={handleFlatPerSpecChange}
              sx={{ width: "15%" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="body1" mt={1} mr={1}>
              Время на каждого:
            </Typography>

            <FormControl variant="standard" sx={{ mt: "4px", width: "100px" }}>
              <Select
                labelId="select-object-label"
                id="select-object"
                label="Object"
                value={timePerFlat}
                onChange={handleTimePerFlatChange}
              >
                <MenuItem value={"5"}>5 минут</MenuItem>
                <MenuItem value={"10"}>10 минут</MenuItem>
                <MenuItem value={"15"}>15 минут</MenuItem>
                <MenuItem value={"20"}>20 минут</MenuItem>
                <MenuItem value={"25"}>25 минут</MenuItem>
                <MenuItem value={"30"}>30 минут</MenuItem>
                <MenuItem value={"35"}>35 минут</MenuItem>
                <MenuItem value={"40"}>40 минут</MenuItem>
                <MenuItem value={"45"}>45 минут</MenuItem>
                <MenuItem value={"50"}>50 минут</MenuItem>
                <MenuItem value={"55"}>55 минут</MenuItem>
                <MenuItem value={"60"}>60 минут</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="body1">Время выдачи: с</Typography>

            <FormControl
              variant="standard"
              sx={{ ml: 1, mt: -0.4, minWidth: "14%" }}
            >
              <Select
                labelId="select-object-label"
                id="select-object"
                label="Object"
                value={issueStart}
                onChange={handleIssueStartChange}
              >
                {availableTime.map((t, i) => {
                  return <MenuItem value={t}>{t}</MenuItem>;
                })}
              </Select>
            </FormControl>

            <Typography variant="body1" ml={1}>
              до
            </Typography>

            <FormControl
              variant="standard"
              sx={{ ml: 1, mt: -0.4, minWidth: "14%" }}
            >
              <Select
                labelId="select-object-label"
                id="select-object"
                label="Object"
                value={issueEnd}
                onChange={handleIssueEndChange}
              >
                {availableEndTime.map((t, i) => {
                  return <MenuItem value={t}>{t}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={withDiner}
                  onChange={() => setWithDiner(!withDiner)}
                />
              }
              label="Обед"
            />
          </FormGroup>
        </Grid>

        <Grid item mt={1} xs={10}>
          <Box sx={{ display: "flex", marginLeft: "26px" }}>
            <Typography variant="body1">c</Typography>

            <FormControl
              variant="standard"
              sx={{ ml: 1, mt: -0.4, minWidth: "14%", width: "80px" }}
            >
              <Select
                disabled={!withDiner}
                labelId="select-dinnerstart-label"
                id="select-dinnerstart"
                label="DinnerStart"
                value={dinnerStart}
                onChange={handleDinnerStartChange}
              >
                {dinnerTime.map((t, i) => {
                  return <MenuItem value={t}>{t}</MenuItem>;
                })}
              </Select>
            </FormControl>

            <Typography variant="body1" ml={1}>
              до
            </Typography>

            <FormControl
              variant="standard"
              sx={{ ml: 1, mt: -0.4, minWidth: "14%", width: "80px" }}
            >
              <Select
                disabled={!withDiner}
                labelId="select-dinnerend-label"
                id="select-dinnerend"
                label="DinnerEnd"
                value={dinnerEnd}
                onChange={handleDinnerEndChange}
              >
                {separateBy(dinnerTime, dinnerStart)[1].map((t, i) => {
                  return <MenuItem value={t}>{t}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showAltDate}
                  onChange={() => setShowAltDate(!showAltDate)}
                />
              }
              label="Альтернативные даты выдачи"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={4} sx={{ display: showAltDate ? "flex" : "none" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
            <DesktopDatePicker
              label="Начало выдачи"
              inputFormat="dd/MM/yyyy"
              disablePast
              value={startDateAlt}
              onChange={(newValue) => handleStartDateChangeAlt(newValue)}
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{ display: showAltDate ? "flex" : "none" }}
        ></Grid>
        <Grid item xs={4} sx={{ display: showAltDate ? "flex" : "none" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
            <DesktopDatePicker
              label="Окончание выдачи"
              inputFormat="dd/MM/yyyy"
              disablePast
              disabled={startDateAlt ? false : true}
              minDate={startDateAlt}
              value={endDateAlt}
              onChange={(newValue) => handleEndDateChangeAlt(newValue)}
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              maxWidth: "100%",
              mt: "12px",
              mb: "12px",
            }}
          >
            <TextField
              fullWidth
              label="Комментарий"
              multiline
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              rows={4}
              id="fullWidth"
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
            <Button variant="outlined" onClick={onClose}>
              Отмена
            </Button>
            <Button variant="contained" onClick={handleEditSchedule}>
              Сохранить
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
